<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Our-Objective</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class="active" aria-current="page"><a href="#">Our-Objective
                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
                <app-insta-post></app-insta-post>
                <!-- <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="mt-40">
                    <div class="con">
                        <h3>No Data Available</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Main content End -->
</div>