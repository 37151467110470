<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Leadership <span> </span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class="active" aria-current="page">Leadership</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                     <app-news-comp></app-news-comp>
                    <!-- <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically
                                            produced APM gas has been revised by Petroleum Pricing & Analysis
                                            Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021
                                        </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically
                                            produced APM gas has been revised by Petroleum Pricing & Analysis
                                            Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021
                                        </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically
                                            produced APM gas has been revised by Petroleum Pricing & Analysis
                                            Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021
                                        </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically
                                            produced APM gas has been revised by Petroleum Pricing & Analysis
                                            Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021
                                        </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2 d-block">View All <i
                                        class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content rs-single-shop">
                    <div class="tab-area">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link {{AllDataStatus ? 'active' : ''}}"
                                    (click)="this.NewDataStatus=false" (click)="this.AllDataStatus=true"
                                    id="directors-tab" data-bs-toggle="tab" data-bs-target="#directors" type="button"
                                    role="tab" aria-controls="directors" aria-selected="false">Board of
                                    Directors</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link {{NewDataStatus ? 'active' : ''}}"
                                    (click)="this.NewDataStatus=true" (click)="this.AllDataStatus=false"
                                    id="members-tab" data-bs-toggle="tab" data-bs-target="#members" type="button"
                                    role="tab" aria-controls="members" aria-selected="true">SMG Members &
                                    Co. Secretary</button>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <div class="tab-pane fade {{AllDataStatus ? 'active show' : ''}}" id="directors"
                                role="tabpanel" aria-labelledby="directors-tab">
                                <div class="chairman-section">
                                    <div class="chairman-photo"><img
                                            src="../../../assets/images/leadership/board-directors/sandeep-kumar-gupta.jpg">
                                    </div>
                                    <div class="chairman-details">
                                        <h4>Mr. Sandeep Kumar Gupta </h4>
                                        <p class="designation">-Chairman</p>
                                        <p>Mr. Sandeep Kumar Gupta has been appointed as Director on the Board and elected as
Chairman of Mahanagar Gas Limited effective from October 31, 2023.</p>

                                        <p>Mr. Sandeep Kumar Gupta is CMD of GAIL (India) Limited, India’s leading natural gas
company with diversified interests across the natural gas value chain of trading,
transmission, LPG production & transmission, LNG re-gasification, petrochemicals, city
gas, E&P, etc. Mr. Gupta is the Chairman of GAIL Gas Limited, Brahmaputra Cracker
and Polymer Limited and Director of Petronet LNG Limited. He is also Chairman of
Standing Conference of Public Enterprises (SCOPE), an apex professional organization
representing the Central Government Public Enterprises. Before joining GAIL in October
2022, Mr. Gupta held the position of Director (Finance) since August 2019 on the Board
of Indian Oil Corporation Limited. As Director (Finance), he was in charge of F&A,
Treasury, Pricing, International Trade, Optimization, Information Systems, Corporate
Affairs, Legal, Risk Management, etc.</p>

                                        <p>Mr. Gupta is a Fellow member of the Institute of Chartered Accountants of India and has
received prestigious individual recognition such as “CA CFO – Large Corporate –
Manufacturing and Infrastructure Category” in January 2021 by ICAI, adjudged among
Top 30 CFOs in India by StartupLanes.com in May 2022 and Best CEO – Oil & Gas
Sector 2022-23 by India Today. He has wide experience of over 35 years in the Oil &
Gas Industry. Presently, he holds directorship in GAIL (India) Limited, GAIL Gas
Limited, Brahmaputra Cracker and Polymer Limited and Petronet LNG Limited.</p>
                                    </div>
                                </div>

                                <div class="mt-40">
                                    <div class="row">
                                        <div *ngFor="let i of AllData" class="col-lg-3 col-md-3 col-sm-6 col-6 mb-30">
                                            <a>
                                                <div class="oth-directors">
                                                    <div (click)="openPopup(i)" class="oth-director-photo">
                                                        <img src="{{i.img}}">
                                                    </div>
                                                    <div class="oth-directors-des text-center">
                                                        <h4>{{i.name}}</h4>
                                                        <p>{{i.post}}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="tab-pane fade {{NewDataStatus ? 'active show' : ''}}" id="members"
                                role="tabpanel" aria-labelledby="members-tab">
                                <div class="row">
                                    <div *ngFor="let i of NewData" class="col-lg-3 col-md-3 col-sm-6 col-6 mb-30">
                                        <a>
                                            <div class="oth-directors">
                                                <div (click)="openPopup(i)" class="oth-director-photo">
                                                    <img src="{{i.img}}">
                                                </div>
                                                <div class="oth-directors-des text-center">
                                                    <h4>{{i.name}}</h4>
                                                    <p>{{i.post}}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div><!-- .tab-area -->
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Inner Page Content Section End -->

<!-- Main content End -->
<div class="modal fade directors-sd {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div  class="modal-dialog modal-xl">

        <div class="modal-content">
            <button (click)="this.closeModel()" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"><i class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="director-modal">
                    <div class="director-photo"><img src="{{selected.img}}"></div>
                    <div class="director-details">
                        <h4>{{selected.name}}</h4>
                        <p class="designation">-{{selected.post}}</p>
                        <p>{{selected.desc1}}</p>

                        <p>{{selected.desc2}}</p>

                        <p>{{selected.desc3}}</p>

                        <p>{{selected.desc4}}</p>

                        <p>{{selected.desc5}}</p>

                        <p>{{selected.desc6}}</p>

                        <p>{{selected.desc7}}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>