<div id="rs-services" class="rs-services style6 bn-service">
    <div class="marketstats">
      <img src="assets/images/market-stat-bg.png" class="mk-bg">
      <div class="marketstats-content">
         <ul>
          <li><!--<i class="demo-icon uil-calling"></i>-->
              <!--  <p>Emergency No/ Dial before Dig </p>
          <h6 > 1800 266 9944 / 6867 4500</h6> 
             <h6> 1800 266 9944 / 6875 9400</h6>-->
             <h6><span>Emergency no.</span>1800 266 9944 / 6875 9400</h6>
             <h6><span>Dial before Dig no.</span> 1800 2100 2100</h6>
          </li>
          <li><div class="mid-social">
          <a href=" https://www.facebook.com/mglltdofficial" target="_blank"><i class="fa fa-facebook"></i></a>
          <a href="https://twitter.com/mahanagargas" target="_blank"><i class="fa fa-twitter"></i></a>
          <a href="https://youtu.be/07Jxsttz6X8" target="_blank"><i class="fa fa-youtube-play"></i></a>
          <a href="https://instagram.com/mahanagargas?igshid=YmMyMTA2M2Y=" target="_blank"><i class="fa fa-instagram"></i></a>
          <a href="https://in.linkedin.com/company/mglltd" target="_blank"><i class="fa fa-linkedin"></i></a>
          </div></li> 
          <!--<li><p>MGL-NSE</p>
              <h6 ><i class="demo-icon uil-chart-line"></i> ₹{{stock.CurrentPrice}} <span [ngStyle]="{'color':NseColors}"  >({{stock.Changes}}%)</span></h6>
          </li>
          <li><p>MGL-BSE</p>
              <h6><i class="demo-icon uil-chart-line"></i> ₹{{stock.BseCurrentPrice}} <span [ngStyle]="{'color':BseColors}" >{{stock.BseChange}}</span> </h6>
          </li>
           <li><p>Call Us on</p>
              <h6><i class="demo-icon uil-phone-alt"></i> 68674500 / 61564500 </h6>
          </li> -->
         </ul>

      </div>
    </div>  
  <div class="looking-for cng-service-sect">
      <img src="assets/images/i-looking-for-bg.svg" class="looking-for-bgimg">
      <div class="looking-for-services">
          <h3>I am <span> looking for</span></h3>
          <ul>
            <li>
                <abbr>
                  <img src="assets/images/looking-for/cng/nearest-cng-station.png">
                  <a [routerLink]="['/cng/customer-zone/cng-locator']">
                    <h6>Nearest CNG <span>Station</span></h6>
                  </a>
                </abbr>
              </li>
              <li>
                <abbr>
                  <img src="assets/images/looking-for/cng/cng-saving-calculator.png">
                  <a [routerLink]="['/cng/customer-zone/saving-calculator']">
                    <h6>CNG Saving <span>Calculator</span></h6>
                  </a>
                  </abbr>
                  </li> 
                  <li>
                    <abbr>
                      <img src="assets/images/looking-for/cng/apply-for-cng-ro.png">
                      <a [routerLink]="['/cng/dealer-zone/apply-for-cng-oulet']">
                        <h6>Apply for<span>CNG RO</span></h6>
                      </a>
                      </abbr>
                      </li> 

                      <li>
                        <abbr>
                          <img src="assets/images/looking-for/cng/process-of-loi.png">
                          <a [routerLink]="['/cng/dealer-zone/process-of-loi-issuance']">
                            <h6>Process of<span>LOI Issuance </span></h6>
                          </a>
                        </abbr>
                        </li>
                         <li>
                        <abbr>
                          <img src="assets/images/looking-for/cng/retail-policy.png">
                          <a target="_blank" href="https://www.mahanagargas.com:3000/retail-policy.pdf">
                            <h6>Retail<span>Policy</span></h6>
                          </a>
                        </abbr>
                        </li>
                         <li>
                        <abbr>
                          <img src="assets/images/looking-for/cng/faq.png">
                          <a [routerLink]="['/cng/dealer-zone/faqs']">
                            <h6>FAQs <span></span></h6> 
                          </a>
                        </abbr>
                        </li>
           <!-- <li>
            <abbr>
              <img src="assets/images/lk-domestic-connection.png">
              <a [routerLink]="['/residential-png/customer-zone/domestic-connection']">
                <h6>Domestic <span>Connection</span></h6>
              </a>
            </abbr>
            </li>   

           <li>
            <abbr>
              <img src="assets/images/lk-rate-card.png">
              <a [routerLink]="['/residential-png/domestic-connection-request/png-rate-card']">
                <h6>Rate Card <span>& Plans</span></h6>
              </a>
            </abbr>
            </li> 

           <li>
           <abbr> 
            <img src="assets/images/lk-payment.png">
             <h6>
             <a [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">Payment </a>
             <span> <a [routerLink]="['/industrial-and-commercial/ic-bill']">I & C Bill</a></span>
            </h6>
           </abbr>
          </li>   
          
           <li>
            <abbr>
              <img src="assets/images/lk-generate-bill.png">
              <a [routerLink]="['/residential-png/customer-zone/current-bill']">
                <h6>Generate <span>Your Bill</span></h6>
              </a>
            </abbr>
          </li>   

           <li>
            <abbr>
              <img src="assets/images/lk-nach-upi.png">
              <a [routerLink]="['/residential-png/nach-online']">
                <h6>NACH/e-<span>NACH / UPI</span></h6>
              </a>
              </abbr>
           </li>   

          
         
          
          
          
           <li>
            <abbr>
              <img src="assets/images/lk-feedback-enquiry.png">
              <a [routerLink]="['/residential-png/customer-care/feedback-inquiry-form']">
                <h6>Feedback / <span>Enquiry</span></h6>
              </a>
            </abbr>
          </li> 

          

            <li>
              <abbr>
                <img src="assets/images/lk-qr-pay.png">
                <a [routerLink]="['/qr-pay']" >
                  <h6>QR <span>Pay</span></h6>
                </a>
              </abbr>
                </li>    -->
          </ul>
      </div>
  </div>
</div>