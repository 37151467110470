<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
     <div class="banner-heading">
        <h1>OEMs Level One Status</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav 2.0</a></li>
                <li class="active" aria-current="page"><a>OEMs Level One Status</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->

<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">

            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div id="ContentPlaceHolder1_div_Customer_Address" class="mb-20">
                                  <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Reason & Status
                                    </div>
                                    <div class="row">
                                        
                                       
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Status: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.status}}</span>
                                            </h6>
                                        </div>

                                        <div class="col-lg-8 mb-20 name-trasfer-address" *ngIf="previouspageData1.status=='Rejected'">
                                            <p>Reject Reason: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.reason}}</span>
                                            </h6>
                                        </div>
                                       
                                       
                                    </div>
                                    
                                    
                                    
                                </div>
                                 <div  class="form-group" *ngIf="previouspageData1.status=='Accepted'"><input type="submit" name="submit" value="Goto Level 2" class="greenbtn3 mt-20" (click)="gotoLevelSec()"></div>
                                  <div  class="form-group" *ngIf="previouspageData1.status=='Rejected'"><input type="submit" name="submit" value="Edit Application" class="greenbtn3 mt-20" (click)="gotoEdit()"></div>

                         <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Customer Information
                                    </div>
                                    <div class="row">
                                       
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Reference No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.refranceNumber}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Name: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Name}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Contact Number: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.ContactNumber}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Date of Birth: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.dob | date:'dd/MM/yyyy'}}</span>
                                            </h6>
                                        </div>
                                          <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Pan Number: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.panNo}}</span>
                                            </h6>
                                        </div>
                                          <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Full Address: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.address}}</span>
                                            </h6>
                                        </div>
                                          <!-- <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Pincode: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Pincode}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Email: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Email}}</span>
                                            </h6>
                                        </div> -->
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Vehicle Booking date: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.vehicleRegDate | date:'dd/MM/yyyy'}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Proposed RTO No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.rto_no}}</span>
                                            </h6>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>OEM: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.oem}}</span>
                                            </h6>
                                        </div>
                                          <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Vehicle Category : </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.vehicle_category}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Vehicle Model: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.vehicleModel}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Vehicle Chassis No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.vehicle_chassis_no}}</span>
                                            </h6>
                                        </div>

                                      
                                         
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Dealer Name: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.dealerName}}</span>
                                            </h6>
                                        </div>
                                      
                                       

                                    </div>
                                </div>

                                <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Documents upload
                                    </div>
                                    <div class="row">
                                       
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Booking receipt copy: </p>
                                            <a href="https://www.mahanagargas.com:3000/vehicle_promotion_2_0_oem/{{previouspageData1.booking_receipt}}" target="_blank" *ngIf="previouspageData1.booking_receipt!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>PAN card copy: </p>
                                            <a href="https://www.mahanagargas.com:3000/vehicle_promotion_2_0_oem/{{previouspageData1.panCardImg}}" target="_blank" *ngIf="previouspageData1.panCardImg!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Photograph of Fuel card: </p>
                                            <a href="https://www.mahanagargas.com:3000/vehicle_promotion_2_0_oem/{{previouspageData1.gift_card1}}" target="_blank" *ngIf="previouspageData1.gift_card1!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Fuel card no: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.gift_no1}}</span>
                                            </h6>
                                        </div>
                                    </div>
                                    
                                </div>

                             

                        </div>
                    </div>
                </div>
            </div>



        </div> <!-- row end -->
    </div>
</div>


