<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Apply for Name Transfer</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <!-- <li class=""><a >Customer Zone  </a></li> -->
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Name Transfer</a></li>
                <li class="active" aria-current="page"><a href="#">Apply for Name Transfer </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                
                <!-- <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Description"></a>
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div> -->
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section mb-lg-0 mb-30">
                    <p>In the interest of customer convenience, MGL offers the facility of name transfer to its domestic
                        customers. Customers can apply for name transfer under the following conditions; Sale of Flat,
                        Mortgaged Flat, Rental/ Pagdi/ Leased Flat, Demise of Registered Customer, Gift Transfer, name
                        transfer from Builder to Owner.</p>
                    <p><a [routerLink]="['/residential-png/customer-zone/name-transfer/apply-for-name']" class="greenbtn2">Apply for Name Transfer
                            Online <i class="demo-icon uil-angle-right"></i></a></p>
                    <p>Or</p>
                    <p>Duly filled name transfer form needs to be submitted along with requisite documents at below
                        mentioned address. (The Name Transfer Charges of Rs. 350 + 18% GST (9%CGST + 9%SGST) will be
                        collected in subsequent bill).</p>
                    <p><strong>Mahanagar Gas Limited</strong><br>
                        Pay &amp; Accounts Building,<br>
                        Ground Floor,<br>
                        Near Family Court,<br>
                        Bandra Kurla Complex,<br>
                        Bandra (E), Mumbai- 400 051</p>
                  <!--   <a _ngcontent-ued-c165="" href="assets/images/_7_Down_Down_Transfer_Eligibility_1d7cbf65c7.pdf"
                        target="_Blank" class="bluebtn2">VIEW ELIGIBILITY FOR TRANSFER
                        <i _ngcontent-ued-c165="" class="demo-icon uil-download-alt"></i></a> -->
                   <div class="border-single"></div>
                    <a _ngcontent-ued-c165="" href="assets/images/pdf/Transfer Form (6) (1).pdf"
                        target="_Blank" class="bluebtn2">DOWNLOAD NAME TRANSFER FORM & TERMS AND CONDITIONS
                        <i _ngcontent-ued-c165="" class="demo-icon uil-download-alt"></i></a>


                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>