<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Newsletters</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Media</a></li>
                <li class="active" aria-current="page"><a >Newsletters</a></li>
            </ul>
        </div>
    </div>
    

      
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content news-section-page rs-blog blog-main-home">
                    <div class="row">


<!-- <div class="tab">
  <button *ngFor="let i of allNews" class="tablinks" autofocus [ngStyle]="{'background-color': currentYear === i ? '#51ad32' : '#ddd'}" (click)="openCity($event, i)">{{i}}</button>
  
  
</div> -->



<!-- <div *ngFor="let k of filteredNews" class="col-lg-4 col-md-4 col-sm-12 mb-30">
    <div class="blog-item">
        <div class="image-wrap">
            <a *ngIf="k.Img"><img src="{{base}}{{k.Img}}" class="w-100"></a>
        </div>
        <div class="blog-content">
           
            
                 <p class="font-size11 mb-0">  {{k.PublishDate | date:'MM/dd/yyyy'}}</p>
                 <h3 class="blog-title"><a routerLink="/MGL-corporate/media/news/{{k._id}}">{{k.Title}}</a></h3> 
          
                    
            <div class="blog-btn">
                <a class="readon consultant blog" routerLink="/MGL-corporate/media/news/{{k._id}}">Read More</a>
            </div>
        </div>
    </div>
</div>
 -->

 <div *ngFor="let item of walkcenterArray" class="col-lg-4 col-md-4 col-sm-12 mb-30">
    <div class="blog-item">
        <div class="image-wrap">
            <a href="https://www.mahanagargas.com:3000/newsletters/{{item.image}}" target="_blank"><img src="https://www.mahanagargas.com:3000/newsletters/{{item.image}}" class="w-100"></a>
        </div>
        <div class="blog-content">
           
            
                 <p class="font-size11 mb-0">{{item.newsDate}}</p>
                 <h3 class="blog-title"><a routerLink="/MGL-corporate/media/newsletters-details/{{item._id}}">{{item.title}}</a></h3> 
          
                    
            <div class="blog-btn">
                <a class="readon consultant blog" href="https://www.mahanagargas.com:3000/newsletters/{{item.image}}" target="_blank">Read More</a>
                <!-- <a class="readon consultant blog" routerLink="/MGL-corporate/media/newsletters-details/{{item._id}}">Read More</a> -->
            </div>
        </div>
    </div>
</div>





                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</div>

