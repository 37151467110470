<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Newsletters</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Media</a></li>
                <li class="active" aria-current="page"><a >Newsletters</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
 <div class="rs-inner-blog innerpage-content-section">
 <div class="container custom">
   <div class="row ">
     <div class="col-lg-3 col-md-3 col-sm-12">
            <app-recent-post></app-recent-post>
             <app-tips></app-tips>
             <app-insta-post></app-insta-post>
               <!-- <div class="mt-30">
               	<a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by mahanagargas</a>
               </div> -->
            </div>
 <div class="col-lg-9 col-md-9 col-sm-12">
   <!-- <div class="row">
   	<div class="col-lg-12">
   		<div class="blog-details">
   			<div class="bs-img mb-35">
   				<a><img class="w-100" src="{{baseUrl}}{{Data[0].Img}}" alt=""></a>
   			</div>
   			<div class="blog-full">
   				<ul class="single-post-meta">
   					<li>
   						<span class="p-date">
   							<i class="fa fa-calendar-check-o"></i>
   						{{Data[0].PublishDate | date}}
   						</span>
   					</li>
   				</ul>
   				<h5> {{Data[0].Title}}</h5>
   				<p [innerHTML]="Data[0].Description"></p>
   			</div>
   		</div>
   	</div>
   </div> -->
   <div class="row">
   	<div class="col-lg-12">
   		<div class="blog-details">
   			<div class="bs-img mb-35">
   				<a href="https://www.mahanagargas.com:3000/newsletters/{{Data[0].image}}" target="_blank"><img class="w-100" src="https://www.mahanagargas.com:3000/newsletters/{{Data[0].image}}" alt=""></a>
   			</div>
   			<div class="blog-full">
   				<ul class="single-post-meta">
   					<li>
   						<span class="p-date">
   							<i class="fa fa-calendar-check-o"></i>
   						     {{Data[0].newsDate}}
   						</span>
   					</li>
   				</ul>
   				<h5> {{Data[0].title}}</h5>
                <p [innerHTML]="Data[0].Desc"></p>
   			</div>
   		</div>
   	</div>
   </div>

 </div>
 </div>
</div>
 <!-- Inner Page Content Section End -->





</div>
<!-- Main content End -->