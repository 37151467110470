<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/awards-banner.jpg">
  <div class="banner-heading">
    <h1>Commercial PNG Payment
    </h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a >Industrial & Commercial   </a></li>
        <li class=""><a  >Commercial PNG</a></li>
        <li class="active" aria-current="page"><a>Commercial PNG Payment
          </a></li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-commercial-service-section></app-commercial-service-section>
<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
        <app-recent-post></app-recent-post>
        <app-tips></app-tips>
        <app-insta-post></app-insta-post>
        <!-- <div class="mt-30">
          <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
            mahanagargas</a>
        </div> -->
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
        <div class="mt-40">
          <div class="con">
            <h6>You may also <span>Pay by RTGS/NEFT </span></h6>
            <div class="mt-20">
             <!--  <div class="area_table">
                <ul>
                  <li>
                    <span class="area2">Bank Name</span>
                    <big class="slab1">HSBC Bank Ltd</big>
                    <small class="slab2">State Bank of India</small>
                  </li>
                  
                  <li>
                    <span class="area2">Account No</span>
                    <big class="slab1">MAHANGASXXXXXXXXXXXX</big>
                    <small class="slab2"><span>MGL2XXXXXXXXXXXX</span></small>
                  </li>
                  
                  <li>
                    <span class="area2">IFSC Code</span>
                    <big class="slab1">HSBC0400002</big>
                    <small class="slab2"><span>SBIN0004266</span></small>
                  </li>
                  <li>
                    <span class="area2">MICR No.</span>
                    <big class="slab1">400039002</big>
                    <small class="slab2"><span>400002133</span></small>
                  </li>
                  <li>
                    <span class="area2">Type of Account</span>
                    <big class="slab1">Current Account</big>
                    <small class="slab2"><span>Current Account</span></small>
                  </li>
                  <li>
                    <span class="area2">Branch Address</span>
                    <big class="slab1">52/60, M.G.Road, Fort, Mumbai-400001</big>
                    <small class="slab2"><span>CAG1 Branch, Neville House,Ballard Estate,Fort,
                      Mumbai-400001</span></small>
                    </li>
                </ul>
              </div> -->

              <div class="light-blue-bg mt-30">
              <div class="white-box mb-30">
                <div class="pngpay-setion">
                  <div class="pngpay-head">
                    <div class="bankname">
                      <p>Bank Name</p>
                      <h6>HSBC BANK LTD </h6>  
                    </div>  
                  </div> 
                  <div class="pngpay-body">
                   <div class="category-sect">
                       <p>Account No  </p>
                       <h6>MAHANGASXXXXXXXXXXXX </h6>
                   </div> 
                   <div class="category-sect">
                       <p>IFSC Code </p>
                       <h6>HSBC0400002</h6>
                   </div>
                   <div class="category-sect">
                       <p>MICR No.  </p>
                       <h6>400039002</h6>
                   </div>
                   <div class="category-sect">
                       <p>Type of Account   </p>
                       <h6>Current Account  </h6>
                   </div> 
                   <div class="category-sect last-add">
                       <p>Branch Address  </p>
                       <h6>52/60, M.G.Road, Fort, Mumbai-400001   </h6>
                   </div>  
                  </div> 
                </div>              
            </div>

            <div class="white-box mt-30 mb-30">
                <div class="pngpay-setion">
                  <div class="pngpay-head">
                    <div class="bankname">
                      <p>Bank Name</p>
                      <h6>STATE BANK OF INDIA </h6>  
                    </div>  
                  </div> 
                  <div class="pngpay-body">
                   <div class="category-sect">
                       <p>Account No  </p>
                       <h6>MGL2XXXXXXXXXXXX </h6>
                   </div> 
                   <div class="category-sect">
                       <p>IFSC Code </p>
                       <h6>SBIN0004266</h6>
                   </div>
                   <div class="category-sect">
                       <p>MICR No.  </p>
                       <h6>400002133</h6>
                   </div>
                   <div class="category-sect">
                       <p>Type of Account   </p>
                       <h6>Current Account  </h6>
                   </div> 
                   <div class="category-sect last-add">
                       <p>Branch Address  </p>
                       <h6>CAG1 Branch, Neville House,Ballard Estate,Fort, Mumbai-400001 </h6>
                   </div>  
                  </div> 
                </div>              
            </div>
            <p class="mt-30"><b>Note :</b> XXXXXXXXXXXX - Please provide 12 digit Contract Account no.</p>

            </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>