<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Commercial PNG Applications</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a  >Industrial & Commercial   </a></li>
                <li class=""><a  >Commercial PNG</a></li>
                <li class=""><a  >Overview</a></li>
                <li class="active" aria-current="page"><a>Commercial PNG Applications</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-commercial-service-section></app-commercial-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

              <!--   <app-facebook-post></app-facebook-post> -->
                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <p>MGL connects customers
                        <span> of commercial PNG</span>
                    </p>
                    <p> across a wide network that includes hospitals, hotels, power, charitable trusts and industries.
                        </p>
                    <div class="light-blue-bg mt-20">
                        <h3>Applications</h3>
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="commercial-png-applications mb-30">
                                    <i><img src="assets/images/commercial-png-icon1.png"></i>
                                    <div class="comercial-heading">HOSPITALS</div>
                                    <ul>
                                        <li>Small Clinics </li>
                                        <li>Big Hospitals </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="commercial-png-applications mb-30">
                                    <i><img src="assets/images/commercial-png-icon-2.png"></i>
                                    <div class="comercial-heading">HOTELS</div>
                                    <ul>
                                        <li>Restaurants </li>
                                        <li>Big hotels </li>
                                        <li>Food Caterers </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="commercial-png-applications mb-30">
                                    <i><img src="assets/images/commercial-png-icon-3.png"></i>
                                    <div class="comercial-heading">POWER</div>
                                    <ul>
                                        <li>Micro Gas Turbines </li>
                                        <li>Gas Genset (CHP) </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="commercial-png-applications mb-30">
                                    <i><img src="assets/images/commercial-png-icon-4.png"></i>
                                    <div class="comercial-heading">CHARITABLE TRUSTS</div>
                                    <ul>
                                        <li>Temples </li>
                                        <li>Church</li>
                                        <li>Gurudwara</li>
                                        <li>Govt. Hospitals</li>
                                        <li>Crematoriums </li>
                                        <li>Orphanage</li>
                                        <li>Schools & Colleges</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="commercial-png-applications mb-30">
                                    <i><img src="assets/images/commercial-png-icon-5.png"></i>
                                    <div class="comercial-heading">INDUSTRIES</div>
                                    <ul>
                                        <li>Metal </li>
                                        <li>Pharmaceutical</li>
                                        <li>Printing and Dyeing </li>
                                        <li>Oil Mills</li>
                                        <li>Food and Beverages </li>
                                        <li>FMCG product</li>
                                        <li>Manufacturers</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->