<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
     <h1>Customer Support

    </h1>   
     <div class="breadcrumbs">
           <ul class="">
             <li class=""><a [routerLink]="['/']">Home</a></li>
             <li class=""><a >Residential PNG</a></li>
             <li class=""><a >Customer Care</a></li>
             <li class="active" aria-current="page"><a>Customer Support
            </a></li>
           </ul>
     </div>
    </div>
</div>
<!-- Services Section Start -->
     <app-service-section></app-service-section>
     <!-- Services Section End -->

 <!-- Inner Page Content Section Start -->
 <div class="innerpage-content-section">
 <div class="container custom">
       <div class="row">
 <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
 <app-recent-post></app-recent-post>

 <app-tips></app-tips>
 <app-insta-post></app-insta-post>

 <!-- <div class="mt-30">

<a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by mahanagargas</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>


 </div> -->
 </div>

 <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
     <!-- <div class="light-blue-bg mb-lg-0 mb-30">
        <div class="CustomerCare">
               
                  <h3><i class="fa fa-phone-square" style="color:#0069b3"></i> Customer Care: </h3>
              </div>
        <p class="comments">If you have comments on the website or experience any difficulty please <a [routerLink]="['/residential-png/customer-zone/customer-login']"><b>click here</b></a> to fill in the details</p> 
     </div> -->

     
     <div class="customer-care-overview-call mb-lg-0 mb-30">
            <div class="heading-sect">
                <i class="demo-icon uil-phone-volume"></i>
                            <h6>Customer Support</h6>
                        </div>
                        <div class="description-sect">
                            <span>68674500 / 61564500</span><br>
                            If you have comments on the website or experience any difficulty please <a [routerLink]="['/residential-png/customer-zone/customer-login']"><b>click here</b></a> to fill in the details
                        </div>
     </div>
    
 </div>
 </div>
</div>
 <!-- Inner Page Content Section End -->





</div>