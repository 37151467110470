<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/cng-rate-inner-banner.jpg">
    <div class="banner-heading">
        <h1>PNG Scheme/Offers</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                 <li class=""><a>PNG Offers/Schemes</a></li>
                <li class=""><a>PNG Scheme/Offers</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips> 
                <app-insta-post></app-insta-post>
              <!--   <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>

          
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                
                       
                <div class="mb-30">
                    <!-- <img src="assets/images/MGLHalf.jpeg" class="w-100"> -->
                    <img  src="assets/images/PNG Connection-01 (1).jpg" class="w-100">
                </div>
                <div class="text-center md-text-center">
                 <a class="bluebtn2" [routerLink]="['/MGL-corporate/awards']">National PNG Drive<i class="demo-icon uil-car"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>