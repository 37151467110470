<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Campaigns</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Safety, Health And Environment</a></li>
                <li class="active" aria-current="page"><a >Campaigns</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>


                </div> -->
            </div>
                  <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">

                        <div class="customer-login-page log-meter-reading">
                             <form [formGroup]="campaignsForm">
                                <div class="row">
                                    <div class="col-sm-6 form-group">
                                          <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="Name"
                                                [ngClass]="{ 'is-invalid': submitted && campaignsForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && campaignsForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="campaignsForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Name<span style="color: crimson;">*</span></label>
                                        </div>

                                    </div>
                                    <div class="col-sm-6 form-group">
                                          <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="number"
                                            class="form-control" formControlName="ContactNumber" placeholder="ContactNumber"
                                            [ngClass]="{ 'is-invalid': submitted && campaignsForm.controls['ContactNumber'].errors }"
                                            id="ContactNumber" />
                                        <div *ngIf="submitted && campaignsForm.controls['ContactNumber'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="campaignsForm.controls['ContactNumber'].errors.required">Required
                                                Field</div>
                                        </div>
                                        <label for="ContactNumber">Mobile No<span style="color: crimson;">*</span></label>
                                    </div>
                                        
                                    </div>
                                    <div class="col-sm-6 form-group">
                                             <div class="form-floating">
                                        <input type="email" class="form-control" formControlName="Email"
                                            placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && campaignsForm.controls['Email'].errors }"
                                            id="Email" />
                                        <div *ngIf="submitted && campaignsForm.controls['Email'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="campaignsForm.controls['Email'].errors.required">Required
                                                Field</div>
                                        
                                        </div>
                                        <label for="Email">Email<span style="color: crimson;">*</span></label>
                                    </div>
                                        
                                    </div>

                                      <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                                </div>
                            </form>
                        </div>
                    </div>
                      <div class="row" style="margin-bottom: 20px;">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="submitPop()" class="greenbtn2">Get Certificate<i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="text-right md-text-left">
                                            <button (click)="reset()" class="bluebtn2">Reset<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div>
                                    </div>

                                </div>
                    <img src="assets/images/Campaigns/Earth Day English.jpeg">
                </div>
            </div>
             <!-- <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                     <div class="print_box3">
                        <a  class="print"><button data-toggle="modal" (click)="openPopup()" style="background: transparent; border: transparent;">Download Now</button></a>
                     
                    </div>
                    <img src="assets/images/Campaigns/Earth Day English.jpeg">
                  
					
                </div>
            </div> -->
        </div>
    </div>
    <!-- Main content End -->
</div>


<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel-2">Enter Details</h5>
          <button type="button" class="close" (click)="closeModel()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 ">
                  <form [formGroup]="campaignsForm">
                     <div class="col-sm-12 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="Name"
                                                [ngClass]="{ 'is-invalid': submitted && campaignsForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && campaignsForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="campaignsForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-12 form-group">
                                         <div class="form-floating">
                                        <input onKeyPress="if(this.value.length==10) return false;" type="number"
                                            class="form-control" formControlName="ContactNumber" placeholder="ContactNumber"
                                            [ngClass]="{ 'is-invalid': submitted && campaignsForm.controls['ContactNumber'].errors }"
                                            id="ContactNumber" />
                                        <div *ngIf="submitted && campaignsForm.controls['ContactNumber'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="campaignsForm.controls['ContactNumber'].errors.required">Required
                                                Field</div>
                                        </div>
                                        <label for="ContactNumber">Mobile No<span style="color: crimson;">*</span></label>
                                    </div>
                                    </div>
                                     <div class="col-sm-12 form-group">
                                         <div class="form-floating">
                                        <input type="email" class="form-control" formControlName="Email"
                                            placeholder="Email"
                                            [ngClass]="{ 'is-invalid': submitted && campaignsForm.controls['Email'].errors }"
                                            id="Email" />
                                        <div *ngIf="submitted && campaignsForm.controls['Email'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="campaignsForm.controls['Email'].errors.required">Required
                                                Field</div>
                                        
                                        </div>
                                        <label for="Email">Email<span style="color: crimson;">*</span></label>
                                    </div>
                                    </div>

                                      <div *ngIf="alertType == 'secSubmit'"
                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                class="card card-inverse-info" id="context-menu-simple">
                                <div class="card-body">
                                    <p class="card-text">
                                        {{alertMsg}}
                                </div>
                            </div>
                  </form>
          
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" (click)="submitPop()">Submit</button>
          <button type="button" class="btn btn-light" (click)="closeModel()">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>

