<div class="innerpage-banner">
   <img src="assets/images/innerpage-banner/awards-banner.jpg">
   <div class="banner-heading">
    <h1>PNG Service Charges</h1>   
    <div class="breadcrumbs">
          <ul class="">
            <li class=""><a [routerLink]="['/']">Home</a></li>
            <li class=""><a >Residential PNG</a></li>
            <li class=""><a >Policies & Procedures</a></li>
            <li class=""><a >Aftersales Services</a></li>
            <li class="active" aria-current="page">PNG Service Charges</li>
          </ul>
      </div>
   </div>
</div>
<!-- Services Section Start -->

<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->  
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
<div class="container custom">
      <div class="row">
<div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
<app-recent-post></app-recent-post>

<app-tips></app-tips>

<app-insta-post></app-insta-post>

<!-- <div class="mt-30">

<a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by mahanagargas</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>


</div> -->
</div>
 <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
    <div class="innerpage-right-content awards-section"> 


           <div class="light-blue-bg mt-20">
                   <div class="rs-faq applications-natural-gas">
                       <div class="faq-content">
                            

                           <div id="accordion" class="accordion">



                            <div class="card">
                              <div class="card-header">
                                  <a class="card-link collapsed" href="#collapseone" data-toggle="collapse"
                                      data-bs-target="#collapseone" aria-expanded="false">After Sales charges (Labour charges) for job inside the customer's premises effective from 01st 
                                      September 2022 to 31st August 2025 (This service is available from 10 AM to 6 PM on all days except Sundays and Holidays)
                                 </a>
                              </div>
                              <div id="collapseone" class="collapse" data-bs-parent="#accordion" style="">
                                  <div class="card-body">
                                      <div class="blue-tick-list">
                                                           <div _ngcontent-udx-c139="" class="table-responsive">  
                                                                   <table _ngcontent-udx-c139="" class="table table-bordered">
                                                                  <thead _ngcontent-udx-c139="">
                                                                   <tr><th colspan="4">LABOUR CHARGES FOR AFTER SALES SERVICES </th></tr>
                                                                   </thead>
                                                                  <tbody _ngcontent-udx-c139="">
                                                                   <tr _ngcontent-udx-c139="">
                                                                   <th _ngcontent-udx-c139="">Sr no</th>
                                                                   <th _ngcontent-udx-c139="">SOR Description</th>
                                                                   <th _ngcontent-udx-c139="">UOM</th>
                                                                   <th _ngcontent-udx-c139="">Rate</th>
                                                                   </tr>
                           
                                                                 
                                                                 
                                                               <tr _ngcontent-udx-c139="">
                                                               <td _ngcontent-udx-c139="">1</td>
                                                               <td _ngcontent-udx-c139="">Removal of Meter without alteration 	</td>
                                                               <td _ngcontent-udx-c139="">Each</td>
                                                               <td _ngcontent-udx-c139="">320</td>
                                                              </tr>
                                                              <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">2</td>
                                                                  <td _ngcontent-udx-c139="">Installation of Meter without alteration/ Meter replacement work	</td>
                                                                  <td _ngcontent-udx-c139="">Each</td>
                                                                  <td _ngcontent-udx-c139="">460</td>
                                                                 
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">3</td>
                                                                  <td _ngcontent-udx-c139="">Addition / Alteration of Copper Tubing work (for Meter shifting)</td>
                                                                  <td _ngcontent-udx-c139="">Meter  </td>
                                                                  <td _ngcontent-udx-c139="">85</td>
                                               
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">4</td>
                                                                  <td _ngcontent-udx-c139="">Addition / Alteration of GI Pipe	</td>
                                                                  <td _ngcontent-udx-c139="">Meter </td>
                                                                  <td _ngcontent-udx-c139="">140</td>
                                                                  
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">5</td>
                                                                <!--   <td _ngcontent-udx-c139="">Copper tube work up to 5 meters length (for Gas meter shifting / Installation and). For more than 05 meters length, SOR 3 will also be applicable</td> -->
                                                                  <td _ngcontent-udx-c139="">Copper tube work upto 5 meters length (for shifting Gas meter). For more than 5 meters length, SOR 3 will also be applicable</td>
                                                                  <td _ngcontent-udx-c139="">Each</td>
                                                                  <td _ngcontent-udx-c139="">575</td>
                                                                 
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">6</td>
                                                                  <td _ngcontent-udx-c139="">Removal of Hole piece </td>
                                                                  <td _ngcontent-udx-c139="">Each</td>
                                                                  <td _ngcontent-udx-c139="">400</td>
                                                                  
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">7</td>
                                                                  <td _ngcontent-udx-c139="">Installation of Hole piece 	</td>
                                                                  <td _ngcontent-udx-c139="">Each</td>
                                                                  <td _ngcontent-udx-c139="">400</td>
                                                                  
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">8</td>
                                                                  <td _ngcontent-udx-c139="">Temporary Disconnection	</td>
                                                                  <td _ngcontent-udx-c139="">Each</td>
                                                                  <td _ngcontent-udx-c139="">230</td>
                                     
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">9</td>
                                                                  <td _ngcontent-udx-c139="">Reconnection of Temp. disconnected cases/ Reconnection of reticulated cases</td>
                                                                  <td _ngcontent-udx-c139="">Each</td>
                                                                  <td _ngcontent-udx-c139="">260</td>
                                                                 
                                                                 </tr>    
                                                                 <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">10</td>
                                                                   <td _ngcontent-udx-c139="">Fixing of PVC Clamps only	</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">200</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">11</td>
                                                                   <td _ngcontent-udx-c139="">Inspection of MGL assets against service request	</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">290</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">12</td>
                                                                   <td _ngcontent-udx-c139="">LIV Installation / Replacement work:	</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">200</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">13</td>
                                                                   <td _ngcontent-udx-c139="">Visit charge (applicable on third visit/ technical support request from customer)</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">175</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">14</td>
                                                                   <td _ngcontent-udx-c139="">Installation/replacement of appliance valve	</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">200</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">15</td>
                                                                   <td _ngcontent-udx-c139="">Installation/replacement of meter brackets</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">345</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">16</td>
                                                                   <td _ngcontent-udx-c139="">Painting of GI riser & laterals.</td>
                                                                   <td _ngcontent-udx-c139="">Meter </td>
                                                                   <td _ngcontent-udx-c139="">166</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">17.A</td>
                                                                   <td _ngcontent-udx-c139="">Riser testing, leakage rectification & clamping of riser.0 to 4th Floor</td>
                                                                   <td _ngcontent-udx-c139="">per meter</td>
                                                                   <td _ngcontent-udx-c139="">50</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">17.B</td>
                                                                   <td _ngcontent-udx-c139="">Riser testing, leakage rectification & clamping of riser 05th floor to 7th Floor</td>
                                                                   <td _ngcontent-udx-c139="">Per meter	</td>
                                                                   <td _ngcontent-udx-c139="">40</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">17.C</td>
                                                                   <td _ngcontent-udx-c139="">Riser testing, leakage rectification & clamping of riser 08th Floor to 15th Floor</td>
                                                                   <td _ngcontent-udx-c139="">per meter</td>
                                                                   <td _ngcontent-udx-c139="">30</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">17.D</td>
                                                                   <td _ngcontent-udx-c139="">Riser testing, leakage rectification & clamping of riser- 15th Floor & above</td>
                                                                   <td _ngcontent-udx-c139="">per meter	</td>
                                                                   <td _ngcontent-udx-c139="">30</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">18</td>
                                                                   <td _ngcontent-udx-c139="">Shifting of Service regulator up to 3 meter	</td>
                                                                   <td _ngcontent-udx-c139="">No</td>
                                                                   <td _ngcontent-udx-c139="">3450</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">19.a</td>
                                                                   <td _ngcontent-udx-c139="">Laying of 32/63 mm PE pipeline without reinstatement</td>
                                                                   <td _ngcontent-udx-c139="">Meter  </td>
                                                                   <td _ngcontent-udx-c139="">575</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">19.b</td>
                                                                   <td _ngcontent-udx-c139="">Laying of 32/63 mm PE pipeline with reinstatement</td>
                                                                   <td _ngcontent-udx-c139="">Meter</td>
                                                                   <td _ngcontent-udx-c139="">1035</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">20</td>
                                                                   <td _ngcontent-udx-c139="">Installation of Welded Riser</td>
                                                                   <td _ngcontent-udx-c139="">Meter </td>
                                                                   <td _ngcontent-udx-c139="">400</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">21</td>
                                                                   <td _ngcontent-udx-c139="">Installation of Half round concrete guard - labour charges	</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">175</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">22.a</td>
                                                                   <td _ngcontent-udx-c139="">GI Lateral work up to 2 meters for customer connectivity	</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">1200</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">22.b</td>
                                                                   <td _ngcontent-udx-c139="">GI Lateral work from 2 mtrs to 3 meters for customer connectivity</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">1300</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">22.c</td>
                                                                   <td _ngcontent-udx-c139="">GI Lateral work from 3 mtrs to 4 meters for customer connectivity	</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">1380</td>
                                                                  
                                                                  </tr>    
                                                                  <tr _ngcontent-udx-c139="">
                                                                   <td _ngcontent-udx-c139="">23</td>
                                                                   <td _ngcontent-udx-c139="">Replacement of Riser isolation valve	</td>
                                                                   <td _ngcontent-udx-c139="">Each</td>
                                                                   <td _ngcontent-udx-c139="">290</td>
                                                                  
                                                                  </tr>    
                                                              </tbody>
                                                              </table>
                                                              </div> 
                                                       
                                                        </div>
                                       

                                

                <p class="mt-30">1. CGST & SGST will be applicable extra.<br>
                  2.The cost of material is as per the MGL purchased price/rate of MGL approved vendors.<br>
                  3. 5% extra charges will be applicable on labour charges for Service Providers operating in South Zone i.e. from SION to CSMT & CHURCHGATE</p>
</div>
</div>
</div>

<!-- 
                                         <div class="card">
                                           <div class="card-header">
                                               <a class="card-link collapsed" href="#collapseTwo" data-toggle="collapse"
                                                   data-bs-target="#collapseTwo" aria-expanded="false">Charges to be taken by service providers for stove related services from PNG customers
                                              </a>
                                           </div>
                                           <div id="collapseTwo" class="collapse" data-bs-parent="#accordion" style="">
                                               <div class="card-body">
                                                   <div class="blue-tick-list">
                                                           <div _ngcontent-udx-c139="" class="table-responsive">  
                                                                   <table _ngcontent-udx-c139="" class="table table-bordered">
                                                                    <thead _ngcontent-udx-c139="">
                                                                      <tr><th colspan="7">In view of the changes in the service charges to be levied to the customers it has been reviewed and deliberated. Accordingly, effective 01st April 2021 it has been decided that service charges to be collected by the service providers from customers for services provided will be as under:</th></tr>
                                                                      </thead>
                                                                   <tbody _ngcontent-udx-c139="">
                                                                   <tr _ngcontent-udx-c139="">
                                                                   <th _ngcontent-udx-c139="">Sr no</th>
                                                                   <th _ngcontent-udx-c139="">Nature of services</th>
                                                                   <th _ngcontent-udx-c139="">Unit</th>
                                                                   <th _ngcontent-udx-c139="">Classification of goods/ services</th>
                                                                   <th _ngcontent-udx-c139="">Charges (Rs.) </th>
                                                                   <th _ngcontent-udx-c139="">GST(Rs.)</th>
                                                                   <th _ngcontent-udx-c139="">Total including GST(Rs)</th>
                                                                   </tr>

                                                                 <tbody _ngcontent-udx-c139="">
                                                               <tr _ngcontent-udx-c139="">
                                                               <td _ngcontent-udx-c139="">1</td>
                                                               <td _ngcontent-udx-c139="">Material charges for rubber hose replacement (1.5 meters</td>
                                                               <td _ngcontent-udx-c139="">Each</td>
                                                               <td _ngcontent-udx-c139="">Services 18% GST</td>
                                                               <td _ngcontent-udx-c139="">161</td>
                                                               <td _ngcontent-udx-c139="">29</td>
                                                               <td _ngcontent-udx-c139="">190</td>
                                                              </tr>
                                                              <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">2</td>
                                                                  <td _ngcontent-udx-c139="">Material charges for rubber hose replacement (1 meters)</td>
                                                                  <td _ngcontent-udx-c139="">Each</td>
                                                                  <td _ngcontent-udx-c139="">Services 18% GST</td>
                                                                  <td _ngcontent-udx-c139="">119</td>
                                                                  <td _ngcontent-udx-c139="">21</td>
                                                                  <td _ngcontent-udx-c139="">140</td>
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">3</td>
                                                                  <td _ngcontent-udx-c139="">Visit charges</td>
                                                                  <td _ngcontent-udx-c139="">Each </td>
                                                                  <td _ngcontent-udx-c139="">Services 18% GST</td>
                                                                  <td _ngcontent-udx-c139="">100</td>
                                                                  <td _ngcontent-udx-c139="">18</td>
                                                                  <td _ngcontent-udx-c139="">118</td>
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">4</td>
                                                                  <td _ngcontent-udx-c139="">Service charges for Hot plate for </td>
                                                                  <td _ngcontent-udx-c139="">Per hot plate</td>
                                                                  <td _ngcontent-udx-c139="">Services 18% GST</td>
                                                                  <td _ngcontent-udx-c139="">70</td>
                                                                  <td _ngcontent-udx-c139="">12.6</td>
                                                                  <td _ngcontent-udx-c139="">83</td>
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">5</td>
                                                                  <td _ngcontent-udx-c139="">Service charges for Cooking range, Hobs & auto ignition Hot plates</td>
                                                                  <td _ngcontent-udx-c139="">Per cooking range</td>
                                                                  <td _ngcontent-udx-c139="">Services 18% GST</td>
                                                                  <td _ngcontent-udx-c139="">300</td>
                                                                  <td _ngcontent-udx-c139="">54</td>
                                                                  <td _ngcontent-udx-c139="">354</td>
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">6</td>
                                                                  <td _ngcontent-udx-c139="">Stove conversion (LPG to PNG and PNG to LPG)</td>
                                                                  <td _ngcontent-udx-c139=""></td>
                                                                  <td _ngcontent-udx-c139=""></td>
                                                                  <td _ngcontent-udx-c139=""></td>
                                                                  <td _ngcontent-udx-c139=""></td>
                                                                  <td _ngcontent-udx-c139=""></td>
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">6.a</td>
                                                                  <td _ngcontent-udx-c139="">Stove with 2 burners</td>
                                                                  <td _ngcontent-udx-c139="">Per stove</td>
                                                                  <td _ngcontent-udx-c139="">Services 18% GST</td>
                                                                  <td _ngcontent-udx-c139="">150</td>
                                                                  <td _ngcontent-udx-c139="">27</td>
                                                                  <td _ngcontent-udx-c139="">177</td>
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">6.b</td>
                                                                  <td _ngcontent-udx-c139="">Stove with 3/4 burners</td>
                                                                  <td _ngcontent-udx-c139="">Per stove</td>
                                                                  <td _ngcontent-udx-c139="">Services 18% GST</td>
                                                                  <td _ngcontent-udx-c139="">250</td>
                                                                  <td _ngcontent-udx-c139="">45</td>
                                                                  <td _ngcontent-udx-c139="">295</td>
                                                                 </tr>
                                                                 <tr _ngcontent-udx-c139="">
                                                                  <td _ngcontent-udx-c139="">6.c</td>
                                                                  <td _ngcontent-udx-c139="">Automatic stove</td>
                                                                  <td _ngcontent-udx-c139="">Per burner</td>
                                                                  <td _ngcontent-udx-c139="">Services 18% GST</td>
                                                                  <td _ngcontent-udx-c139="">100</td>
                                                                  <td _ngcontent-udx-c139="">18</td>
                                                                  <td _ngcontent-udx-c139="">118</td>
                                                                 </tr>  
                                                                  <tr _ngcontent-udx-c139="">
                                                                    <td colspan="7">
                                                                  <p><b>The service providers shall provide the GST invoice for any service charges collected from the customers in line with the above</b></p>
                                                                  <p _ngcontent-xjp-c65=""><b>Note:</b> The cost of material/spare parts except Rubber hose changed by service provider shall be as per the model of the hot plate of the customer</p>
                                                              </td>
                                                                    </tr>
                                                              </tbody>
                                                              </table>
                                                              </div> 
                                                       
                                                        </div>
                                       

                                

</div>

</div>
</div> -->
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>


