<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Safety and Technical Competency (STC) Training</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Safety, Health And Environment</a></li>
                <li class="active" aria-current="page">Safety and Technical Competency (STC) Training</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="mt-40">
					<p>Safety and Technical Competency Training is imperative part of HSE management at MGL. It provides undeviating knowledge to work safely in all of MGL’s business operations.</p>
					<p>Every person working for MGL gets trained on safety precautions in his respective area. Practical exercise and Hazard Identification and Risk Assessment are the key components of the Safety and Technical Competency Training. Safety and Technical Competency Training is a step closer to ensure that every working site is incident and injury free site.
					</p>                 
                </div>
            </div>
        </div>
    </div>
    <!-- Main content End -->
</div>