import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { Retrofitters20Service } from 'src/app/Service/retrofitters20.service';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';

@Component({
  selector: 'app-retrofitters20levelone',
  templateUrl: './retrofitters20levelone.component.html',
  styleUrls: ['./retrofitters20levelone.component.css']
})
export class Retrofitters20leveloneComponent {
 promotionForm!: FormGroup
  submitted = false
  pancardimg: any;
  cngkitimg:any;
  bookingamountreceiptimg: any;
  existingrcbookimg: any;
  nocdocimg: any;
  captcha: any;
  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';
  retroffiterLoginRole:any;
   retrofittersEmail:any;

   VehicleModelArr:any

 constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: Retrofitters20Service,
    private UserService : UserRegistrationService,public router: Router) { }

  ngOnInit(): void {
    this.UserService.checkLogin1()

    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential1')))
    
     if (data) {
      let retroffiterLogindata = JSON.parse(data);

      let retroffiterData = retroffiterLogindata.details
     this.retroffiterLoginRole = retroffiterData.Role;
     this.retrofittersEmail = retroffiterData.Email;
     //console.log('details data',this.retroffiterLoginRole)
    }

    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);

   
    this.captchaGenerate()
    this.promotionForm = this.FormBuilder.group({
            Name: ['', [Validators.required]],
            ContactNumber: ['', Validators.compose([Validators.required,Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$")])],
            Email: ['', [Validators.required,Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            vehicleNo: ['', [Validators.required]],
            Pan:['',[Validators.required, Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")]],
            booking_receipt_date: ['', [Validators.required]],
            amount_of_scheme: ['', [Validators.required]],
            pan_card_img: ['', Validators.required],
            booking_amount_receipt: ['', Validators.required],
            existing_rc_book: ['', Validators.required],
            noc_doc:  [''],
            role: [''],
            vehicleTpye: ['', [Validators.required]],
            oem: ['', [Validators.required]],
            vehicleModel: ['', [Validators.required]],
            kit_Supplier: ['', [Validators.required]],
            Captcha: ['', [Validators.required]],
            cng_certificate: ['', Validators.required],
           
    });


     this.promotionForm.get('kit_Supplier')?.setValue(this.retroffiterLoginRole);

  }

  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  




  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result


  }

  vmodelData(){

     this.promotionForm.get('vehicleModel')?.setValue('');
     this.VehicleModelArr=[];
     this.promotionForm.get('vehicleTpye')?.setValue('');

    //   if(this.promotionForm.get('oem')?.value =='Ashok Leyland'){

    //   this.vehiclecategoryArr=[
    //  {id:'Bus',name:'Bus'},
    //  {id:'LCV (>3.5 T, <10 T)',name:'LCV (>3.5 T, <10 T)'},
    //  {id:'ICV ( ≥10T, <15T)',name:'ICV ( ≥10T, <15T)'},
    //  {id:'HCV (≥15 T)',name:'HCV (≥15 T)'},
    //  ]

    //   }else if(this.promotionForm.get('oem')?.value =='TATA Motors'){
    //      this.vehiclecategoryArr=[
    //  {id:'Bus',name:'Bus'},
    //  {id:'LCV (>3.5 T, <10 T)',name:'LCV (>3.5 T, <10 T)'},
    //  {id:'ICV ( ≥10T, <15T)',name:'ICV ( ≥10T, <15T)'},
    //  {id:'HCV (≥15 T)',name:'HCV (≥15 T)'},
    //  ]

    //   }else if(this.promotionForm.get('oem')?.value =='Eicher (VECV)'){

    //   }else{
    //    this.promotionForm.get('vehicleModel')?.setValue('');
    //  this.VehicleModelArr=[];
    //   this.promotionForm.get('vehicleTpye')?.setValue('');
     
    // }
     

  }


  selectCar(){
     this.promotionForm.get('vehicleModel')?.setValue('');
     this.VehicleModelArr=[];
     
     if(this.retroffiterLoginRole == "Shigan Telematics Private Limited"){

      if(this.promotionForm.get('oem')?.value =='Ashok Leyland'){

      if(this.promotionForm.get('vehicleTpye')?.value == "SCV (Upto 3.5 T)"){
        this.promotionForm.get('amount_of_scheme')?.setValue("0");
        this.VehicleModelArr=[];

    }else if(this.promotionForm.get('vehicleTpye')?.value == "LCV (>3.5T,<10T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("150000");
       this.VehicleModelArr=[];

    }else if(this.promotionForm.get('vehicleTpye')?.value == "Bus"){
      this.promotionForm.get('amount_of_scheme')?.setValue("200000");

         this.VehicleModelArr=[
     {id:'ALPSV 4/190',name:'ALPSV 4/190'},
     ]
      
    }else if(this.promotionForm.get('vehicleTpye')?.value == "ICV (>=10T,<15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("300000");
       this.VehicleModelArr=[];

    }else if(this.promotionForm.get('vehicleTpye')?.value == "HCV(>=15T)"){
       this.promotionForm.get('amount_of_scheme')?.setValue("450000");
       this.VehicleModelArr=[];
      
    }

     

      }else if(this.promotionForm.get('oem')?.value =='TATA Motors'){

          if(this.promotionForm.get('vehicleTpye')?.value == "SCV (Upto 3.5 T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("0");
        this.VehicleModelArr=[];

    }else if(this.promotionForm.get('vehicleTpye')?.value == "LCV (>3.5T,<10T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("150000");

         this.VehicleModelArr=[
     {id:'TATA 709',name:'TATA 709'},
     {id:'TATA 909',name:'TATA 909'},
     {id:'TATA 912',name:'TATA 912'},
     {id:'TATA 810',name:'TATA 810'},
     {id:'TATA 812',name:'TATA 812'},
     ]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "Bus"){
      this.promotionForm.get('amount_of_scheme')?.setValue("200000");

         this.VehicleModelArr=[
     {id:'TATA LP 912',name:'TATA LP 912'},
     {id:'TATA LP 410',name:'TATA LP 410'},
     {id:'TATA LP 712',name:'TATA LP 712'},
     {id:'TATA LP 1112',name:'TATA LP 1112'},
     {id:'TATA 712 EX/42',name:'TATA 712 EX/42'},
     {id:'Tata 1512 EX/42 Bus',name:'Tata 1512 EX/42 Bus'},
     {id:'TATA LP 1512 TC/59',name:'TATA LP 1512 TC/59'},
     {id:'TATA LP 1512 TC',name:'TATA LP 1512 TC'},
     {id:'TATA LP 1512',name:'TATA LP 1512'},
     {id:'TATA LP 1515',name:'TATA LP 1515'},
     {id:'TATA LP 1610',name:'TATA LP 1610'},
     {id:'TATA LPO 1512',name:'TATA LPO 1512'},
     {id:'TATA LPO 1515',name:'TATA LP 1112'},
     {id:'TATA LPO 1612',name:'TATA LPO 1612'},
     {id:'TATA LPO 1613',name:'TATA LPO 1613'},
     {id:'TATA LPO 1616',name:'TATA LPO 1616'},
     {id:'TATA LPO 1618',name:'TATA LPO 1618'},
     {id:'TATA LPO 1623',name:'TATA LPO 1623'},
     ]
      
    }else if(this.promotionForm.get('vehicleTpye')?.value == "ICV (>=10T,<15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("300000");
      
         this.VehicleModelArr=[
     {id:'TATA 1109',name:'TATA 1109'},
     {id:'TATA 1212',name:'TATA 1212'},
     {id:'TATA 1010',name:'TATA 1010'},
     {id:'TATA 1412',name:'TATA 1412'},
     ]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "HCV(>=15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("450000");

         this.VehicleModelArr=[
     {id:'TATA 1512',name:'TATA 1512'},
     ]
      
    }
         

      }else if(this.promotionForm.get('oem')?.value =='Eicher (VECV)'){

          if(this.promotionForm.get('vehicleTpye')?.value == "SCV (Upto 3.5 T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("0");

         this.VehicleModelArr=[]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "LCV (>3.5T,<10T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("150000");

       this.VehicleModelArr=[]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "Bus"){
      this.promotionForm.get('amount_of_scheme')?.setValue("200000");

         this.VehicleModelArr=[
     {id:'Eicher Pro 3010 H Bus',name:'Eicher Pro 3010 H Bus'},
     {id:'Eicher SKYILNE PRO 3008',name:'Eicher SKYILNE PRO 3008'},
     {id:'Eicher SKYILNE PRO 3008 H',name:'Eicher SKYILNE PRO 3008 H'},
     {id:'Eicher SKYILNE PRO 3009',name:'Eicher SKYILNE PRO 3009'},
     {id:'Eicher SKYILNE PRO 3009 L',name:'Eicher SKYILNE PRO 3009 L'},
     {id:'Eicher SKYILNE PRO 3009 H',name:'Eicher SKYILNE PRO 3009 H'},
     {id:'Eicher SKYILNE PRO 3010',name:'Eicher SKYILNE PRO 3010'},
     {id:'Eicher SKYILNE PRO 3010 L',name:'Eicher SKYILNE PRO 3010 L'},
     {id:'Eicher SKYILNE PRO 3011',name:'Eicher SKYILNE PRO 3011'},
     {id:'Eicher SKYILNE PRO 3011 L',name:'Eicher SKYILNE PRO 3011 L'},
     {id:'Eicher SKYILNE PRO 2121 M',name:'Eicher SKYILNE PRO 2121 M'},
     ]
      
    }else if(this.promotionForm.get('vehicleTpye')?.value == "ICV (>=10T,<15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("300000");
      
         this.VehicleModelArr=[
     {id:'EICHER PRO 3014',name:'EICHER PRO 3014'},
     {id:'Eicher 10.50',name:'Eicher 10.50'},
     {id:'Eicher 1059 XP',name:'Eicher 1059 XP'},
     {id:'Eicher 10.90 XP',name:'Eicher 10.90 XP'},
     {id:'Eicher 10.80',name:'Eicher 10.80'},
     {id:'Eicher 10.80 XP',name:'Eicher 10.80 XP'},
     {id:'Eicher 10.75',name:'Eicher 10.75'},
     {id:'Eicher 10.75 XP',name:'Eicher 10.75 XP'},
     {id:'Eicher 10.59',name:'Eicher 10.59'},
     {id:'Eicher 10.55',name:'Eicher 10.55'},
     {id:'Eicher 10.55 XP',name:'Eicher 10.55 XP'},
     {id:'Eicher 10.50 XP',name:'Eicher 10.50 XP'},
     {id:'Eicher 10.49',name:'Eicher 10.49'},
     {id:'Eicher 10.49 XP',name:'Eicher 10.49 XP'},
     {id:'Eicher 20.49',name:'Eicher 20.49'},
     {id:'Eicher 10.75',name:'Eicher 10.75'},
     {id:'Eicher Pro 3008',name:'Eicher Pro 3008'},
     {id:'Eicher Pro 3009'},
     ]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "HCV(>=15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("450000");

         this.VehicleModelArr=[
     {id:'EICHER PRO 3015',name:'EICHER PRO 3015'},
     {id:'EICHER PRO 3016',name:'EICHER PRO 3016'},
     {id:'Eicher 10.95 XP',name:'Eicher 10.95 XP'},
     {id:'Eicher 20.95',name:'Eicher 20.95'},
     {id:'Eicher 20.95 XP',name:'Eicher 20.95 XP'},
     {id:'Eicher 3013',name:'Eicher 3013'},
     {id:'Eicher Pro 1114',name:'Eicher Pro 1114'},
     {id:'Eicher Pro 1114 XP',name:'Eicher Pro 1114 XP'},
     {id:'Eicher Pro 1110 XP',name:'Eicher Pro 1110 XP'},
     {id:'Eicher Pro 1110 XPT',name:'Eicher Pro 1110 XPT'},
     {id:'Eicher Pro 1112 XP',name:'Eicher Pro 1112 XP'},
     {id:'Eicher Pro 3012',name:'Eicher Pro 3012'},
     {id:'Eicher Pro 1110',name:'Eicher Pro 1110'},
         
     ]
      
    }

      
    }


      

     }else if (this.retroffiterLoginRole == "Amol Prala Clean Energy Pvt. Ltd"){

      if(this.promotionForm.get('oem')?.value =='Ashok Leyland'){

           if(this.promotionForm.get('vehicleTpye')?.value == "SCV (Upto 3.5 T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("0");

         this.VehicleModelArr=[]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "LCV (>3.5T,<10T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("150000");

       this.VehicleModelArr=[]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "Bus"){
      this.promotionForm.get('amount_of_scheme')?.setValue("200000");

         this.VehicleModelArr=[
     {id:'ALPSV 4/186',name:'ALPSV 4/186'},
     {id:'ALPSV 4/189',name:'ALPSV 4/189'},
     {id:'ALPSV 4/54',name:'ALPSV 4/54'},
     {id:'ALPSV 4/82',name:'ALPSV 4/82'},
     {id:'ALPSV 4/83',name:'ALPSV 4/83'},
     {id:'ALPSV 4/103',name:'ALPSV 4/103'},
     {id:'ALPSV 4/151',name:'ALPSV 4/151'},
     {id:'ALPSV 4/161',name:'ALPSV 4/161'},
     {id:'ALPSV 4/169',name:'ALPSV 4/169'},
     {id:'ALPSV 4/170',name:'ALPSV 4/170'},
     {id:'ALPSV 4/183',name:'ALPSV 4/183'},
     {id:'ALPSV 4/184',name:'ALPSV 4/184'},
     {id:'ALPSV 4/186',name:'ALPSV 4/186'},
     {id:'ALPSV 4/187',name:'ALPSV 4/187'},
     {id:'ALPSV 4/188',name:'ALPSV 4/188'},
     {id:'ALPSV 4/190',name:'ALPSV 4/190'},
     ]
      
    }else if(this.promotionForm.get('vehicleTpye')?.value == "ICV (>=10T,<15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("300000");
      
        this.VehicleModelArr=[]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "HCV(>=15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("450000");

         this.VehicleModelArr=[
     {id:'Ashok Leyland 2516 H',name:'Ashok Leyland 2516 H'},
     {id:'Ashok Leyland 1616',name:'Ashok Leyland 1616'},
     {id:'Ashok Leyland 1618',name:'Ashok Leyland 1618'},
     {id:'Ashok Leyland 2518',name:'Ashok Leyland 2518'},
     {id:'Ashok Leyland 3116',name:'Ashok Leyland 3116'},
     {id:'Ashok Leyland 3118',name:'Ashok Leyland 3118'},
     {id:'Ashok Leyland 3518',name:'Ashok Leyland 3518'},
     {id:'Ashok Leyland 3520',name:'Ashok Leyland 3520'},
     {id:'Ashok Leyland 3718',name:'Ashok Leyland 3718'},
     {id:'Ashok Leyland 4018',name:'Ashok Leyland 4018'},
     {id:'Ashok Leyland 4019',name:'Ashok Leyland 4019'},
     {id:'Ashok Leyland 4020',name:'Ashok Leyland 4020'},
     {id:'Ashok Leyland 4023',name:'Ashok Leyland 4023'},
     {id:'Ashok Leyland 4620',name:'Ashok Leyland 4620'},
     {id:'Ashok Leyland 4923',name:'Ashok Leyland 4923'},
     ]
      
    }

     

      }else if(this.promotionForm.get('oem')?.value =='TATA Motors'){

          if(this.promotionForm.get('vehicleTpye')?.value == "SCV (Upto 3.5 T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("0");

         this.VehicleModelArr=[]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "LCV (>3.5T,<10T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("150000");

         this.VehicleModelArr=[
     {id:'TATA 410',name:'TATA 410'},
     {id:'TATA 709',name:'TATA 709'},
     {id:'TATA 909',name:'TATA 909'},
     {id:'TATA 912',name:'TATA 912'},
     {id:'TATA 712',name:'TATA 712'},
     {id:'TATA LPT 407',name:'TATA LPT 407'},
     {id:'TATA 810',name:'TATA 810'},
     {id:'TATA 812 ULTRA',name:'TATA 812 ULTRA'},
     ]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "Bus"){
      this.promotionForm.get('amount_of_scheme')?.setValue("200000");

         this.VehicleModelArr=[
     {id:'TATA LPO 1624 MARCOPOLO',name:'TATA LPO 1624 MARCOPOLO'},
     {id:'TATA LP0 1616 MARCOPOLO',name:'TATA LP0 1616 MARCOPOLO'},
     {id:'TATA LP 912',name:'TATA LP 912'},
     {id:'TATA LP 410',name:'TATA LP 410'},
     {id:'TATA LP 712',name:'TATA LP 712'},
     {id:'TATA LP 909',name:'TATA LP 909'},
     {id:'TATA LP 407',name:'TATA LP 407'},
     {id:'TATA LP 1112',name:'TATA LP 1112'},
     {id:'TATA LP 912 STAR',name:'TATA LP 912 STAR'},
     {id:'TATA 709 STAR',name:'TATA 709 STAR'},
     {id:'TATA 810 STAR',name:'TATA 810 STAR'},
     {id:'TATA 710 STAR',name:'TATA 710 STAR'},
     {id:'TATA ULTRA URBAN 6/9',name:'TATA ULTRA URBAN 6/9'},
     ]
      
    }else if(this.promotionForm.get('vehicleTpye')?.value == "ICV (>=10T,<15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("300000");
      
         this.VehicleModelArr=[
     {id:'TATA 1109',name:'TATA 1109'},
     {id:'TATA 1110',name:'TATA 1110'},
     {id:'TATA 1112',name:'TATA 1112'},
     {id:'TATA 1114',name:'TATA 1114'},
     {id:'TATA 1212',name:'TATA 1212'},
     {id:'TATA 1010',name:'TATA 1010'},
     {id:'TATA 1412',name:'TATA 1412'},
     {id:'TATA 1012 ULTRA',name:'TATA 1012 ULTRA'},
     {id:'TATA 1412 ULTRA',name:'TATA 1412 ULTRA'},
     ]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "HCV(>=15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("450000");

         this.VehicleModelArr=[
     {id:'TATA LPT 1613',name:'TATA LPT 1613'},
     {id:'TATA 4023 Prima',name:'TATA 4023 Prima'},
     {id:'TATA LPT 2515 TC',name:'TATA LPT 2515 TC'},
     {id:'TATA 1512',name:'TATA 1512'},
     {id:'TATA LPK 1613',name:'TATA LPK 1613'},
     {id:'TATA LPT 1615',name:'TATA LPT 1615'},
     {id:'TATA LPT 1616',name:'TATA LPT 1616'},
     {id:'TATA LPT 1618',name:'TATA LPT 1618'},
     {id:'TATA LPK 2515 TC',name:'TATA LPK 2515 TC'},
     {id:'TATA LPK 2518',name:'TATA LPK 2518'},
     {id:'TATA LPT 2518 TC',name:'TATA LPT 2518 TC'},
     {id:'TATA LPT 2518TM',name:'TATA LPT 2518TM'},
     {id:'TATA LPT 2513',name:'TATA LPT 2513'},
     {id:'TATA LPT 2516',name:'TATA LPT 2516'},
     {id:'TATA LPT 2518',name:'TATA LPT 2518'},
     {id:'TATA LPT 2523',name:'TATA LPT 2523'},
     {id:'TATA SIGNA 2518 K',name:'TATA SIGNA 2518 K'},
     {id:'TATA LPT 3118',name:'TATA LPT 3118'},
     {id:'TATA LPT 3518',name:'TATA LPT 3518'},
     {id:'TATA LPT 3718',name:'TATA LPT 3718'},
     {id:'TATA SIGNA 3118',name:'TATA SIGNA 3118'},
     {id:'TATA LPK 3118',name:'TATA LPK 3118'},
     {id:'TATA PRIMA LX 2523',name:'TATA PRIMA LX 2523'},
     {id:'TATA PRIMA LX 3123',name:'TATA PRIMA LX 3123'},
     {id:'TATA SIGNA 3518 S',name:'TATA SIGNA 3518 S'},
     {id:'TATA SIGNA 3718',name:'TATA SIGNA 3718'},
     {id:'TATA SIGNA 4823 T',name:'TATA SIGNA 4823 T'},
     {id:'TATA SIGNA 4923',name:'TATA SIGNA 4923'},
     {id:'TATA LPT 3723',name:'TATA LPT 3723'},
     {id:'TATA LPT 4018',name:'TATA LPT 4018'},
     {id:'TATA LPT 3516',name:'TATA LPT 3516'},
     {id:'TATA PRIMA LX 3718 T',name:'TATA PRIMA LX 3718 T'},
     {id:'TATA LPS 4018',name:'TATA LPS 4018'},
     {id:'TATA SIGNA 4018 S',name:'TATA SIGNA 4018 S'},
     {id:'TATA LPS 4923',name:'TATA LPS 4923'},
     {id:'TATA SIGNA 5523 S',name:'TATA SIGNA 5523 S'},
     ]
      
    }
         

  }else if(this.promotionForm.get('oem')?.value =='Eicher (VECV)'){

          if(this.promotionForm.get('vehicleTpye')?.value == "SCV (Upto 3.5 T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("0");

         this.VehicleModelArr=[]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "LCV (>3.5T,<10T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("150000");

         this.VehicleModelArr=[]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "Bus"){
      this.promotionForm.get('amount_of_scheme')?.setValue("200000");

         this.VehicleModelArr=[
     {id:'Eicher 10.50 C STARLINE',name:'Eicher 10.50 C STARLINE'},
     {id:'Eicher 10.50 D STARLINE',name:'Eicher 10.50 D STARLINE'},
     {id:'Eicher 10.75 H SKYILNE',name:'Eicher 10.75 H SKYILNE'},
     {id:'Eicher 11.12 K STARLINE',name:'Eicher 11.12 K STARLINE'},
     {id:'Eicher 10.90 L SKYILNE',name:'Eicher 10.90 L SKYILNE'},
     {id:'Eicher 10.90 L STARLINE',name:'Eicher 10.90 L STARLINE'},
     ]
      
    }else if(this.promotionForm.get('vehicleTpye')?.value == "ICV (>=10T,<15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("300000");
      
         this.VehicleModelArr=[
     {id:'Eicher 11.10',name:'Eicher 11.10'},
     {id:'EICHER 11.10 PRO',name:'EICHER 11.10 PRO'},
     {id:'EICHER 11.12 PRO',name:'EICHER 11.12 PRO'},
     {id:'EICHER 11.14 PRO',name:'EICHER 11.14 PRO'},
     {id:'EICHER 11.55 PRO',name:'EICHER 11.55 PRO'},
     {id:'EICHER 11.12',name:'EICHER 11.12'},
     {id:'Eicher 10.95',name:'Eicher 10.95'},
     {id:'Eicher 10.90',name:'Eicher 10.90'},

     ]

    }else if(this.promotionForm.get('vehicleTpye')?.value == "HCV(>=15T)"){
      this.promotionForm.get('amount_of_scheme')?.setValue("450000");

      this.VehicleModelArr=[]
      
    }

      
    }

  }

  
    

  }

 
 
 
  filechange(element: any, check: any) {
   // console.log(check);
    if (check == 'bookingamountreceipt') {
      if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('booking_amount_receipt')?.setValue('');
        return alert('Please select PDF less than 2 MB ')
      }else{
        this.bookingamountreceiptimg = element.target.files
      }
      
     

    } else if (check == 'existingrcbook') {
        if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('existing_rc_book')?.setValue('');
        return alert('Please select PDF less than 2 MB ')
      }else{
        this.existingrcbookimg = element.target.files
      }
      
     
    } else if (check == 'pancard'){
       if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('pan_card_img')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
       this.pancardimg = element.target.files
      }
       
      
    } else if (check == 'noccard'){
       if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('noc_doc')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
       this.nocdocimg = element.target.files
      }
       
      
    }else if (check == 'Kitimg'){
       if(element.target.files[0].size/1024/1024>2){
          this.promotionForm.get('cng_certificate')?.setValue('');
        return alert('Please select image less than 2 MB ')
      }else{
       this.cngkitimg = element.target.files
      }
       
      
    }




  }



  

  

 

  clearAlert() {
    this.alertType = ''
  }

  
  submitForm() {
   // console.log(this.nocdocimg)
        this.submitted = true
    if (this.promotionForm.get('Captcha')?.value != this.captcha) {
      this.promotionForm.get('Captcha')?.setValue('')
    }
    if (this.promotionForm.invalid) {
      return;
    }

   

    const form = new FormData()
    form.append("Name", this.promotionForm.get('Name')?.value)
    form.append("ContactNumber", this.promotionForm.get('ContactNumber')?.value)
    form.append("Email", this.promotionForm.get('Email')?.value)
    form.append("vehicleNo", this.promotionForm.get('vehicleNo')?.value.replace(/\s/g, ""))
    form.append("Pan", this.promotionForm.get('Pan')?.value)
    form.append("booking_receipt_date", this.promotionForm.get('booking_receipt_date')?.value)
    form.append("amount_of_scheme", this.promotionForm.get('amount_of_scheme')?.value)
    form.append("vehicleTpye", this.promotionForm.get('vehicleTpye')?.value)
    form.append("kit_Supplier", this.promotionForm.get('kit_Supplier')?.value)
    form.append("oem", this.promotionForm.get('oem')?.value)
    form.append("vehicleModel", this.promotionForm.get('vehicleModel')?.value)
    form.append("role", this.retroffiterLoginRole )
     form.append("retrofittersEmail", this.retrofittersEmail )
       
    let bookingamountreceiptimgImg = this.bookingamountreceiptimg[0].name.split('.').pop()
    let bookingamountreceiptimgImg1  =this.bookingamountreceiptimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ bookingamountreceiptimgImg;
    form.append("booking_amount_receipt", this.bookingamountreceiptimg[0], bookingamountreceiptimgImg1)

    let existingrcbookimgImg = this.existingrcbookimg[0].name.split('.').pop()
    let existingrcbookimgImg1  =this.existingrcbookimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ existingrcbookimgImg;
    form.append("existing_rc_book", this.existingrcbookimg[0], existingrcbookimgImg1)

    let pancardimgImg = this.pancardimg[0].name.split('.').pop()
    let pancardimgImg1  =this.pancardimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ pancardimgImg;
    form.append("pan_card_img", this.pancardimg[0], pancardimgImg1)

     if(this.nocdocimg != undefined){
       let nocdocimgImg = this.nocdocimg[0].name.split('.').pop()
       let nocdocimgImg1  =this.nocdocimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ nocdocimgImg;
      form.append("noc_doc", this.nocdocimg[0], nocdocimgImg1)
     }

     let cngkitimgImg = this.cngkitimg[0].name.split('.').pop()
    let cngkitimgImg1  =this.cngkitimg[0].name.split('.').slice(0, -1).join('.')+this.promotionForm.get('Pan')?.value+ new Date() +'.'+ cngkitimgImg;
    form.append("cng_certificate", this.cngkitimg[0], cngkitimgImg1)
    

   
      

     this.service.insert_Retrofitters_Level_one(form).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.submitted = false;
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        this.promotionForm.reset();
     
        setTimeout(() => {
           this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-retrofitters-dashboard']);
          this.clearAlert()

        }, 5000);

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'vehiclepromotion'
        this.alertMsg = res.message;
        //  this.promotionForm.reset();
        setTimeout(() => {
          this.clearAlert()

        }, 5000);
      }



    }, err => {
     // console.log(err)
    })

  }



  reset() {
    this.promotionForm.reset()
    this.submitted = false;
   

  }

   validDate(){
    
    console.log(this.promotionForm.get('booking_receipt_date')?.value)
    var selectDate = new Date(this.promotionForm.get('booking_receipt_date')?.value);
    var todayDate = new Date('2024-09-01');

    console.log(selectDate)
     console.log(todayDate)

    if(todayDate > selectDate){
         this.promotionForm.get('booking_receipt_date')?.setValue('')
       alert("we have not accept booking receipt date before 1 st Sep 2024")
    }else{
        console.log("okay")
    }
  }



  
}



