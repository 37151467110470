<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Apply for Name Transfer
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Name Transfer</a></li>
                <li class="active" aria-current="page">Apply for Name Transfer
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Description"></a>
                                  
                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i class="demo-icon uil-arrow-right"></i></a> 
                        </div>
                    </div>
                </div>

                <app-tips></app-tips>
                <app-insta-post></app-insta-post>

               <!--  <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>




            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
              <div  class="customer-login-page log-meter-reading">

                    <form [formGroup]="CustomerInfoForm">
                        <div class="light-blue-bg mt-10">
                            <div class="row">
                               <!--  <p><b><i>Details of Meter installed at premise</i></b></p> -->
                                <div class="row">
                                <div class="col-md-12">
                                    <p class="mb-2"><b>No Objection certificate (NOC) from the previous Owner</b></p>
                                     <div class="form-check form-check-inline">
                                         <input formControlName="Noc" (change)="getGoreen($event)" class="form-check-input" type="radio" name="color" value="Yes" id="iyes" [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Noc'].errors }"><label class="form-check-label" for="iyes"> Yes</label>
                                         </div>

                                          <div class="form-check form-check-inline">
                                          <input formControlName="Noc" (change)="getGoreen($event)" class="form-check-input" type="radio" name="color" value="No" id="ino" [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Noc'].errors }"> <label class="form-check-label" for="ino">No :   I , the applicant named above do hereby declare that I shall defend, indemnify and hold harmless MGL from all claims and losses that MGL may sustain or incur as a result, whether directly or indirectly in not providing NOC from the previous owner.</label>
                                        </div>
                                            <div *ngIf="submitted && CustomerInfoForm.controls['Noc'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="CustomerInfoForm.controls['Noc'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                </div>
                             
                           </div>
                         
                           <div *ngIf="nocDoc" class="col-lg-6 col-md-6 col-sm-12 mb-3" style="margin-top: 20px;">
                                      <label>Upload NOC</label>
                                      <div class="upload-input">
                                                <input accept=".pdf" (change)="fileCheckForSlip($event,'noc')"
                                                    formControlName="NocNameFile"
                                                    
                                                    [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['NocNameFile'].errors}"
                                                    class="from-control" type="file" id="name" name="name"
                                                    placeholder="Enter Lpg Connection Surrender Slip">
                                                <div *ngIf="submitted && CustomerInfoForm.controls['NocNameFile'].errors"
                                                    class="invalid-feedback">
                                                    <div style="white-space: nowrap;"
                                                        *ngIf="CustomerInfoForm.controls['NocNameFile'].errors">
                                                        Required Field
                                                    </div>
                                                </div>
                                                 <div  *ngIf="previouspageData.NocNameFile!=''">
                                                    <a href="https://mahanagargas.com:3000/{{previouspageData.NocNameFile}}"
                                                        target="_blank" *ngIf="previouspageData.NocNameFile!=''">
                                                        <h6> <span class="badge bg-secondary">View Old DOC</span></h6>
                                                    </a>
                                                </div>
                                            </div>
                                            <p class="font-size11">Only .pdf file is allowed and size within 2 MB</p>


                                        </div>

                                         <div class="box100per" style="margin-top:10px">
                                         	 <div class="d-inline"><strong class="d-inline"
                                            style="color:#000;">Declaration</strong><strong>*</strong>:
                                        <div class="form-check d-inline-flex">
                                            <input
                                                [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['Declaration'].errors}"
                                                formControlName="Declaration" id="ContentPlaceHolder1_chkDeclaration"
                                                type="checkbox" name="ctl00$ContentPlaceHolder1$chkDeclaration"
                                                class="form-check-input">

                                            <label class="form-check-label" style="margin-top:2px"> <a
                                                    href="../../../assets/images/pdf/_Name_transfer_&_registration_form_T&C_6de4837bfa.pdf?rand=1781308971"
                                                    target="_blank">I accept</a></label>
                                        </div>
                                    </div>
                                     <div *ngIf="submitted && CustomerInfoForm.controls['Declaration'].errors"
                                        class="invalid-feedback">
                                        <div *ngIf="CustomerInfoForm.controls['Declaration'].errors">
                                            Required Field
                                        </div>
                                    </div>
                                      <b id="spDeclaration"></b>

                                    <div></div>

                                    <ul class="apply-list-sect">
                                        <li>1. I/We declare the above details are true & correct and authorize MGL to verify the same.</li>
                                        <li> 2. I/ We acknowledge that we will visit www.mahanagargas.com for latest terms and conditions which shall be binding on me/us.</li>
                                        <li> 3. I/We agree that this application can be accepted or rejected by Mahanagar Gas Ltd., without assigning any reason thereof.</li>
                                        <li> 4. I/We agree to settle the unpaid connection related charges gas consumption bills of the previous gas consumer.</li>
                                      
                                    </ul>
                                     <div class="mb-20 mt-20 ">
                                        <div class="d-inline"><strong>Terms &amp; Conditions</strong><strong>*</strong>
                                            : </div>
                                        <div class="d-inline-flex">
                                            <div class="form-check">
                                                <input
                                                    [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['TNC'].errors}"
                                                    formControlName="TNC" id="ContentPlaceHolder1_chkTerm"
                                                    type="checkbox" name="ctl00$ContentPlaceHolder1$chkTerm"
                                                    class="form-check-input">


                                                <label class="form-check-label"> <a
                                                        href="../../../assets/images/pdf/_Name_transfer_&_registration_form_T&C_6de4837bfa.pdf?rand=1781308971"
                                                        target="_blank">I accept all Terms &amp; Conditions</a></label>
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && CustomerInfoForm.controls['TNC'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['TNC'].errors">
                                                Required Field
                                            </div>
                                        </div>
                                    </div>


                                         </div>

                                         <div id="ContentPlaceHolder1_div_Customer_Address" class="mb-20">
                                    <div class="name-transfer-box">
                                        <div class="user-add-heading">
                                            Name Changes is being processed for following address
                                        </div>
                                        <div class="row">
                                             <div class="col-lg-12 mb-20">
                                                <span>Name: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{previouspageData.Fname}} {{previouspageData.Mname}} {{previouspageData.lname}}</span></b>

                                            </div>
                                            <div class="col-lg-6 mb-20">
                                                <span>BP No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{previouspageData.BPno}}</span></b>

                                            </div>

                                            <div class="col-lg-6 mb-20">
                                                <span>CA No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingCANo">{{previouspageData.CAno}}</span></b>

                                            </div>
                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Flat/Room No: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtFlat">{{previouspageData.FlatNo}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Floor: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtFloor">{{previouspageData.Floor}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Wing: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtWing">{{previouspageData.Wing}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Plot No: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtPlot">{{previouspageData.PlotNo}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Building Name: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtBuildingName">{{previouspageData.BuildingName}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Colony Name: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtColony">{{previouspageData.Colony}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Road Name: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtRoadName">{{previouspageData.RoadName}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Location: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtLocation">{{previouspageData.Location}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Land Mark: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtLandMark">{{previouspageData.LandMark}}</span>
                                                </h6>

                                            </div>
 
                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>City: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtCity">{{previouspageData.City}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Pincode: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtPin">{{previouspageData.Pin}}</span>
                                                </h6>

                                            </div>

                                        </div>
                                    </div>
                                   

                                </div>

                                   
                            </div>
                            
                              <!-- <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-12">
                                              <div class="mt-20">
                                                <div (copy)="false" class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-7 col-sm-12">
                                               <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Captcha'].errors }"
                                                        id="Captcha" />
                                                    <div *ngIf="submitted && CustomerInfoForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="CustomerInfoForm.controls['Captcha'].errors.required">
                                                            Required Field</div>
                                                     
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> -->
                                 <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="submitCustomerForm()"
                                            class="greenbtn2">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Submit<i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>

                                  <!--   <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                        <button (click)="goBack()" class="greybtn">Back</button>
                                    </div> -->
                                </div>


                                <div *ngIf="alertType == '3rdSubmit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                        </div>
                      
                      
                    </form>
                </div>






            </div>
        </div>
    </div>
</div>


<div class="modal fade directors-sd {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                    class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <!--  <div class="row"> -->
                         <div class="mb-20" style="text-align: center; font-size: 20px;border-bottom: 1px solid #dee2e6;padding: 20px;">
                             <!--  <div class="user-add-heading"> -->
                                <b>Please confirm submited details</b>
                                     <!--    </div> -->
                                    </div>
                                    <div class="name-transfer-box1">
                                      
                                        <div class="row" *ngIf="popuppData != undefined">
                                             <div class="col-lg-6 mb-20">
                                                <span>Initial: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.NameInitial}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>Name: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.Fname}} {{popuppData.Mname}} {{popuppData.lname}}</span></b>

                                            </div>
                                            <div class="col-lg-6 mb-20">
                                                <span>Father/Husband's Name: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.FHusbandName}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>Pan No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.Pan}}</span></b>

                                            </div>
                                              <div class="col-lg-6 mb-20">
                                                <span>Mobile No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.MobileNo}}</span></b>

                                            </div>
                                              <div class="col-lg-6 mb-20">
                                                <span>Email: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.Email}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>Alternate Mobile No:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.AltMobileNo}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>Meter No:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.MeterNo}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>Meter Reading:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.MeterReading}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>LPG Connection:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.LpgGasConn}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>Gas Supplier:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.GasSuppli}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>Gas Distributor:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.GasDistri}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>LPG Connection Number:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.LpgConnNo}}</span></b>

                                            </div>
                                           <!--  <div class="col-lg-6 mb-20">
                                                <span>LPG Connection Surrender Slip:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.AltMobileNo}}</span></b>
                                                        Upload NOC

                                            </div> -->
                                             <div class="col-lg-6 mb-20">
                                                <span>Reason for transfer of PNG Connection:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.ResForTrans}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                <span>NOC:</span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.Noc}}</span></b>

                                            </div>
                                             <div class="col-lg-6 mb-20">
                                                

                                            </div>
                                            <div class="col-lg-3 mb-20 pdf-download">
                                            <a href="https://www.mahanagargas.com:3000/{{popuppData.Doc1File}}" target="_blank">
                                            <div class="img-div-class ">
                                            <img src="../../assets/images/pdf.png" class="img-class">
                                            <div class="lg-sub-html-add">
                                            <div>{{popuppData.Doc1Type}}</div>

                                            </div>
                                            </div>
                                            </a>
                                            </div>
                                             <div class="col-lg-3 mb-20 pdf-download" *ngIf="popuppData.Noc=='Yes'">
                                            <a href="https://www.mahanagargas.com:3000/{{popuppData.NocNameFile}}" target="_blank">
                                            <div class="img-div-class ">
                                            <img src="../../assets/images/pdf.png" class="img-class">
                                            <div class="lg-sub-html-add">
                                            <div>NOC</div>

                                            </div>
                                            </div>
                                            </a>
                                            </div>
                                              <div class="col-lg-3 mb-20 pdf-download" *ngIf="popuppData.LpgGasConn=='Yes'">
                                            <a href="https://www.mahanagargas.com:3000/{{popuppData.LpgSurrSlip}}" target="_blank">
                                            <div class="img-div-class ">
                                            <img src="../../assets/images/pdf.png" class="img-class">
                                            <div class="lg-sub-html-add">
                                            <div>LPG Slip</div>

                                            </div>
                                            </div>
                                            </a>
                                            </div>
                                          <!--   <div class="col-lg-6 mb-20">
                                                <span>BP No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">{{popuppData.BPno}}</span></b>

                                            </div>

                                            <div class="col-lg-6 mb-20">
                                                <span>CA No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingCANo">{{popuppData.CAno}}</span></b>

                                            </div>
                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Flat/Room No: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtFlat">{{popuppData.FlatNo}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Floor: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtFloor">{{popuppData.Floor}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Wing: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtWing">{{popuppData.Wing}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Plot No: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtPlot">{{popuppData.PlotNo}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Building Name: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtBuildingName">{{popuppData.BuildingName}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Colony Name: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtColony">{{popuppData.Colony}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Road Name: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtRoadName">{{popuppData.RoadName}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Location: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtLocation">{{popuppData.Location}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-6 mb-20 name-trasfer-address">
                                                <p>Land Mark: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtLandMark">{{popuppData.LandMark}}</span>
                                                </h6>

                                            </div>
 
                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>City: </p>
                                                <h6> <span
                                                        id="ContentPlaceHolder1_txtCity">{{popuppData.City}}</span>
                                                </h6>

                                            </div>

                                            <div class="col-lg-3 mb-20 name-trasfer-address">
                                                <p>Pincode: </p>
                                                <h6><span
                                                        id="ContentPlaceHolder1_txtPin">{{popuppData.Pin}}</span>
                                                </h6>

                                            </div> -->

                                        </div>
                                    </div>
                                   

                               <!--  </div> -->
                 </div>
            <div class="modal-footer">
                <button  class="greenbtn2" (click)="submitPop()">Confirm</button>
                <button class="bluebtn2" (click)="gotoEdit()">Edit</button>
            </div>
        </div>
    </div>
</div>
<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>


