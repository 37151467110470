<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Retrofitter Level One
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>CNG</a></li>
                <li class=""><a>MGL CNG Mahostav 2.0</a></li>
                <li class="active" aria-current="page"><a>Retrofitter Level One</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>
                <app-insta-post></app-insta-post>

               <!--  <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">

                        <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="promotionForm">
                                <div class="row">
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="Customer Name"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['Name'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Customer Name<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="ContactNumber"
                                                placeholder="Customer Contact Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['ContactNumber'].errors }"
                                                id="ContactNumber" />
                                            <div *ngIf="submitted && promotionForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['ContactNumber'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['ContactNumber'].errors.pattern">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.ContactNumber.hasError('maxlength') && promotionForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                                <div
                                                    *ngIf="promotionForm.controls.ContactNumber.hasError('minlength') && promotionForm.controls.ContactNumber.touched">
                                                    Invalid Contact Number </div>
                                            </div>
                                            <label>Customer Contact Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Email"
                                                placeholder="Customer Email"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submitted && promotionForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                                 <div *ngIf="promotionForm.controls['Email'].errors.required">
                                                    Required Field
                                                </div>

                                                <div *ngIf="promotionForm.controls['Email'].errors.pattern">
                                                    Invalid Email ID </div>
                                            </div>
                                            <label>Customer Email ID<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                      <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control panupper" formControlName="Pan"
                                                placeholder="Customer PAN Card Number"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['Pan'].errors}" />

                                            <div *ngIf="submitted && promotionForm.controls['Pan'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['Pan'].errors.required">
                                                    PAN Card Number is Required
                                                </div>
                                                <div *ngIf="promotionForm.controls['Pan'].errors.pattern">
                                                    Invalid PAN Card Number </div>

                                            </div>

                                            <label>Customer PAN Card Number<span
                                                    style="color: crimson;">*</span></label>

                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="oem"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['oem'].errors}" (change)="vmodelData()">
                                                <option value="" selected>Select OEM</option>
                                                 <option value="TATA Motors">TATA Motors</option>
                                                  <option value="Eicher (VECV)">Eicher (VECV)</option>
                                                <option value="Ashok Leyland">Ashok Leyland</option>
                                             </select>
                                            <div *ngIf="submitted && promotionForm.controls['oem'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['oem'].errors">OEM
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>OEM<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select (change)="selectCar()" class="form-select" formControlName="vehicleTpye"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['vehicleTpye'].errors}">
                                                <option value="" selected>Select Type of vehicle </option>
                                                <option value="LCV (>3.5T,<10T)">LCV (>3.5T,<10T)</option>
                                                <option value="Bus">Bus</option>
                                                 <option value="ICV (>=10T,<15T)">ICV (>=10T,<15T)</option>
                                                  <option value="HCV(>=15T)">HCV(>=15T)</option>
                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['vehicleTpye'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleTpye'].errors">Type of
                                                    vehicle
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Type of vehicle<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="vehicleModel"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['vehicleModel'].errors}">
                                                <option value="" selected>Select Vehicle Model</option>
                                                <option *ngFor="let item of VehicleModelArr" value="{{item.id}}">{{item.name}}</option>
                                               
                                                

                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['vehicleModel'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleModel'].errors">Vehicle Model
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Vehicle Model<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="vehicleNo"
                                                placeholder="Vehicle Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['vehicleNo'].errors }"
                                                id="vehicleNo" />
                                            <div *ngIf="submitted && promotionForm.controls['vehicleNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['vehicleNo'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="promotionForm.controls['vehicleNo'].errors.pattern">
                                                    Invalid Vehicle Number </div>
                                            </div>
                                            <label>Vehicle Number<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="kit_Supplier"
                                                placeholder="Vehicle Number"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['kit_Supplier'].errors }"
                                                id="kit_Supplier" />
                                            <div *ngIf="submitted && promotionForm.controls['kit_Supplier'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['kit_Supplier'].errors.required">
                                                    Required Field
                                                </div>
                                               
                                            </div>
                                            <label>Name of Kit Supplier<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="kit_Supplier"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['kit_Supplier'].errors}">
                                                <option value="" selected>Select Name of Kit Supplier </option>
                                                <option value="Green Globe Fuel Solutions Pvt Ltd">Green Globe Fuel
                                                    Solutions Pvt Ltd</option>
                                                <option value="Eco Fuel Systems (I) Pvt Ltd">Eco Fuel Systems (I) Pvt
                                                    Ltd</option>
                                                <option value="Amol Prala Clean Energy Pvt. Ltd">Amol Prala Clean Energy
                                                    Pvt. Ltd</option>
                                                <option value="Shigan Quantum Technologies Limited">Shigan Telematics
                                                    Private Limited</option>
                                                 <option value="Shigan Quantum Technologies Limited">Shigan Quantum Technologies Limited</option>
                                                <option value="Motozen Fuel Systems Pvt. Ltd.">Motozen Fuel Systems Pvt.
                                                    Ltd.</option>

                                            </select>
                                            <div *ngIf="submitted && promotionForm.controls['kit_Supplier'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['kit_Supplier'].errors">Name of Kit
                                                    Supplier
                                                    is
                                                    Required</div>
                                            </div>
                                            <label>Name of Kit Supplier<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                         <input type="date" (change)="validDate()" class="form-control" formControlName="booking_receipt_date"
                                                placeholder="Booking Receipt Date"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['booking_receipt_date'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['booking_receipt_date'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['booking_receipt_date'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Booking Receipt Date<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" readonly class="form-control" formControlName="amount_of_scheme"
                                                placeholder="Amount of Scheme"
                                                [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['amount_of_scheme'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && promotionForm.controls['amount_of_scheme'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['amount_of_scheme'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label>Amount of Scheme<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                  
                                  
                                  
                                  
                                   

                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">Upload Customer PAN Card<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'pancard')"
                                                class="from-control" type="file" formControlName="pan_card_img"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['pan_card_img'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['pan_card_img'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['pan_card_img'].errors">PAN Card
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">Booking Amount Receipt<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'bookingamountreceipt')" class="from-control"
                                                type="file" formControlName="booking_amount_receipt"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['booking_amount_receipt'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['booking_amount_receipt'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['booking_amount_receipt'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size11">Existing RC Book<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'existingrcbook')" class="from-control"
                                                type="file" formControlName="existing_rc_book"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['existing_rc_book'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['existing_rc_book'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['existing_rc_book'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>

                                    </div>
                                      <div class="col-sm-6 form-group">
                                        <label class="font-size11">Upload NOC (If RTO changed from Transporter)</label>
                                        <div class="form-floating">
                                            <input accept=".jpg, .jpeg, .png" (change)="filechange($event,'noccard')"
                                                class="from-control" type="file" formControlName="noc_doc">
                                            <!-- <div *ngIf="submitted && promotionForm.controls['pan_card_img'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['pan_card_img'].errors">PAN Card
                                                    is
                                                    Required</div>
                                            </div> -->
                                        </div>

                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <label class="font-size11">CNG kit retrofitment certificate<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating">
                                            <input (change)="filechange($event,'Kitimg')" class="from-control"
                                                type="file" formControlName="cng_certificate"
                                                [ngClass]="{'is-invalid':submitted && promotionForm.controls['cng_certificate'].errors}">
                                            <div *ngIf="submitted && promotionForm.controls['cng_certificate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="promotionForm.controls['cng_certificate'].errors">Document
                                                    is
                                                    Required</div>
                                            </div>
                                        </div>

                                    </div>
                                   <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && promotionForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && promotionForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="promotionForm.controls['Captcha'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="submitForm()" class="greenbtn2">Submit <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="text-right md-text-left">
                                            <button (click)="reset()" class="bluebtn2">Reset<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-right mt-30">
                                    <p class="mb-0"><b><i><span class="text-danger">*</span>MGL reserves the right to
                                                ask for any other document if required </i></b></p>
                                    <p class="mb-0"><b><i>Please note all fields marked <span
                                                    class="text-danger">*</span> are compulsory.</i></b></p>
                                </div>
                                <div *ngIf="alertType == 'vehiclepromotion'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





