<!-- <div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Track Name Transfer Request</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Name Transfer</a></li>
                <li class="active" aria-current="page">Track Name Transfer Request</li>
            </ul>
        </div>
    </div>
</div>

<app-service-section></app-service-section>

<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Description"></a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> {{i.PublishDate | date:'MMM dd, yyyy'}}</span>
                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i class="demo-icon uil-arrow-right"></i></a> 
                        </div>
                    </div>
                </div>

                <app-tips></app-tips>

                <div class="mt-30">
                    <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2 d-block">View All <i
                                        class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>




            </div>

          

          
        </div>
        
    </div>
</div> -->


<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Track Name Transfer Request</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Name Transfer</a></li>
                <li class="active" aria-current="page">Track Name Transfer Request</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Description"></a>
                                    <!-- <span class="date-post"> <i class="fa fa-calendar"></i> {{i.PublishDate | date:'MMM dd, yyyy'}}</span> -->
                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i
                                    class="demo-icon uil-arrow-right"></i></a>
                        </div>
                    </div>
                </div>

                <app-tips></app-tips>
                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="customer-login-page log-meter-reading">
                    <div class="light-blue-bg mt-10">
                        <div class="row">
                            <div id="ContentPlaceHolder1_div_Customer_Address" class="mb-20">

                                <!-- <div class="name-transfer-box">
                                        <div class="user-add-heading">
                                            Name Changes is being processed for following address
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span>BP No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingBPNo">skdf</span></b>

                                            </div>

                                            <div class="col-lg-6">
                                                <span>CA No: </span>
                                                <b><span
                                                        id="ContentPlaceHolder1_lblExistingCANo">sddfdf</span></b>

                                            </div>
                                        </div>
                                    </div> -->


                                <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Customer Information
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>BP No: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFlat">{{previouspageData1.BPno}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>CA No: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFloor">{{previouspageData1.CAno}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Application No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.ApplicationNo}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Name: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.NameInitial}} {{previouspageData1.Fname}} {{previouspageData1.Mname}} {{previouspageData1.lname}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Father/Husband's Name: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.FHusbandName}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Date of Birth: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.DOB | date:'dd/MM/yyyy'}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Gender: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Occupation}}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Contact Information
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Flat/Room No: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFlat">{{previouspageData1.FlatNo}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Floor: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFloor">{{previouspageData1.Floor}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Wing: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Wing}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Plot No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.PlotNo}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Building Name: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.BuildingName}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Colony: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Colony}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Road Name: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.RoadName}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Location: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Location}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Land Mark: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.LandMark}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>City: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.City}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Pin: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Pin}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>PAN No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Pan}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Mobile No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.MobileNo}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Telephone No: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.TelePhone_Off}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Email ID: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Email}}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Details of Meter installed at premise
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Meter No: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFlat">{{previouspageData1.MeterNo}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Meter Reading: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFloor">{{previouspageData1.MeterReading}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Meter Photo File: </p>
                                            <!-- <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.MeterPhoto}}</span>
                                            </h6> -->
                                            <a href="https://www.mahanagargas.com:3000/{{previouspageData1.MeterPhoto}}" target="_blank" *ngIf="previouspageData1.MeterPhoto!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                      
                                    </div>
                                    
                                </div>

                                <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Documents upload
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Gas Supplier: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFlat">{{previouspageData1.GasSuppli}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Gas Distributor: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFloor">{{previouspageData1.GasDistri}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>LPG Connection Number: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.LpgConnNo}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>LPG Connection surrender Slip: </p>
                                            <a href="https://www.mahanagargas.com:3000/{{previouspageData1.LpgSurrSlip}}" target="_blank" *ngIf="previouspageData1.LpgSurrSlip!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>{{previouspageData1.Doc1Type}}: </p>
                                            <a href="https://www.mahanagargas.com:3000/{{previouspageData1.Doc1File}}" target="_blank" *ngIf="previouspageData1.Doc1File!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                         <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>{{previouspageData1.Doc2Type}}: </p>
                                            <a href="https://www.mahanagargas.com:3000/{{previouspageData1.Doc2File}}" target="_blank" *ngIf="previouspageData1.Doc2File!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>{{previouspageData1.Doc3Type}}: </p>
                                            <a href="https://www.mahanagargas.com:3000/{{previouspageData1.Doc3File}}" target="_blank" *ngIf="previouspageData1.Doc3File!=''">
                                                <h6> <span class="badge bg-secondary">View</span></h6>
                                            </a>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div class="name-transfer-box mt-30">
                                    <div class="user-add-heading">
                                        Name Transfer Reason & Status
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Reason for Transfer of PNG Connection: </p>
                                            <h6><span id="ContentPlaceHolder1_txtFlat">{{previouspageData1.ResForTrans}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Name Transfer Charges (Including GST): </p>
                                            <h6><span id="ContentPlaceHolder1_txtFloor">0</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Payment Status: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.PaymentStatus}}</span>
                                            </h6>
                                        </div>
                                        <div class="col-lg-4 mb-20 name-trasfer-address">
                                            <p>Name Tranfer Status: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Status}}</span>
                                            </h6>
                                        </div>

                                        <div class="col-lg-8 mb-20 name-trasfer-address" *ngIf="previouspageData1.Status=='Reject'">
                                            <p>Reject Reason: </p>
                                            <h6> <span id="ContentPlaceHolder1_txtWing">{{previouspageData1.Reason}}</span>
                                            </h6>
                                        </div>
                                       
                                       
                                    </div>
                                    
                                    
                                    
                                </div>
                                <!-- *ngIf="previouspageData1.Status=='Reject'" -->
                                <div  *ngIf="previouspageData1.Status=='Reject'" class="form-group"><input type="submit" name="submit" value="Update Name Transfer Request" class="greenbtn3 mt-20" (click)="gotoEdit()"></div>

                              <!--   <div class="text-right mt-30">
                                    <p class="mb-0"><b><i><span class="text-danger">*</span>Please note that a Convenience Fee of 0.90% + GST will be levied for Visa / MasterCard / RuPay Cards & 1.10% + GST for Amex Card transactions </i></b></p>
                                 
                                </div> -->

                            </div>












                        </div>
                    </div>

                </div>






            </div>
        </div>
    </div>
</div>