<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>View Authorized Person
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class="active" aria-current="page"><a href="#">View Authorized Person</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg mb-lg-0 mb-30">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
                            <div class="customer-login-page log-meter-reading">
                                <form [formGroup]="SearchForm">
                                    <div class="row">
                                        <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                                        <label>Enter Authorized Person Code <span class="text-danger">*</span></label>
                                        <div class="mb-30 cu-login-input mt-10">
                                            <i class="demo-icon uil-user"></i>
                                            <input formControlName="AuthPersonCode" class="from-control" type="text"
                                                id="Enter Authorized Person Code" name="Name"
                                                placeholder="Enter Number of the Authorized Person"
                                                [ngClass]="{'is-invalid':submitted && SearchForm.controls['AuthPersonCode'].errors}">
                                            <div *ngIf="submitted && SearchForm.controls['AuthPersonCode'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="SearchForm.controls['AuthPersonCode'].errors">Enter
                                                    Authorized Person Code</div>
                                            </div>
                                        </div>
                                    </div> -->

                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="AuthPersonCode"
                                                    placeholder="AuthPersonCode"
                                                    [ngClass]="{ 'is-invalid': submitted && SearchForm.controls['AuthPersonCode'].errors }"
                                                    id="AuthPersonCode" />
                                                <div *ngIf="submitted && SearchForm.controls['AuthPersonCode'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="SearchForm.controls['AuthPersonCode'].errors.required">
                                                        Required Field
                                                    </div>
                                                </div>
                                                <label for="AuthPersonCode">Enter Authorized Person Code<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 form-group">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" formControlName="Captcha"
                                                    placeholder="Captcha" 
                                                    [ngClass]="{ 'is-invalid': submitted && SearchForm.controls['Captcha'].errors }"
                                                    id="Captcha" />
                                                <div *ngIf="submitted && SearchForm.controls['Captcha'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="SearchForm.controls['Captcha'].errors.required">Required
                                                        Field
                                                    </div>
                                                </div>
                                                <label for="Captcha">Captcha<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 mt-20 form-group">
                                            <!-- <label>Captcha<span class="text-danger">*</span></label> -->
                                            <div class="pt-20">
                                                <!-- <i class="demo-icon uil-phone-volume"></i> -->
                                                <div class="captcha-text">{{this.captcha}} <i
                                                        (click)="captchaGenerate()" class="fa fa-refresh"
                                                        aria-hidden="true" (copy)=false></i> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <button (click)="submitSearch()" class="greenbtn2">Submit <i
                                                    class="demo-icon uil-arrow-right"></i></button>
                                        </div>
                                        <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div class="text-right md-text-left">
                                                <button (click)="reset()" class="bluebtn2">Reset<i
                                                        class="demo-icon uil-redo"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div *ngIf="alertType == 'submit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="allAuth.length > 0">
                        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto">
                            <div class="authorized-personnel mt-30">
                                <ul>
                                    <li>
                                        <!-- <div class="authorized-personnel-userImg"><img
                                                src="https://mahanagargas.com:3000/{{allAuth[0].Photo}}">
                                        </div> -->
                                        <div class="authorized-personnel-userImg"><img
                                                src="{{allAuth[0].SupervisorPhoto}}">
                                        </div>
                                    </li>
                                     <!--<li><span>Name</span> {{allAuth[0].FirstName}} {{allAuth[0].LastName}}</li> -->
                                        <li><span>Name</span> {{allAuth[0].Name}}</li>
                                    <li><span>Authorized Person Code</span> {{allAuth[0].SupervisorCode}}</li>
                                    <li><span>Agency Name</span> {{allAuth[0].Agency}}</li>
                                      <!-- <li><span>Sp Type</span> {{allAuth[0].SpType}}</li>
                                        <li><span>Sp Title</span> {{allAuth[0].SpTitle}}</li> -->
                                    <!-- <li><span>CreatedOn:</span> {{allAuth[0].CreatedOn| date:'dd/MM/yyyy'}}</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>