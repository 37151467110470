<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
     <h1>Apply For job Application </h1>   
     <div class="breadcrumbs">
           <ul class="">
             <li class=""><a href="#">Home</a></li>
             <li class="active" aria-current="page"><a href="#">Apply For job Application </a></li>
           </ul>
     </div>
    </div>
</div>
<!-- Services Section Start -->
     <app-service-section></app-service-section>
     <!-- Services Section End -->

 <!-- Inner Page Content Section Start -->
 <div class="innerpage-content-section">
 <div class="container custom">
       <div class="row">
 <div class="col-lg-3 col-md-3 col-sm-12">
 <app-recent-post></app-recent-post>

 <app-tips></app-tips>
 <app-insta-post></app-insta-post>

 <<!-- div class="mt-30">

<a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by mahanagargas</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
 </div> -->
 </div>
 <div class="col-lg-9 col-md-9 col-sm-12" >
  <div class="light-blue-bg">
    <h3>Personal Details:</h3>
 <div class="customer-login-page log-meter-reading">
  <div class="log-meter-text">
  <form [formGroup]="carrierapplyform">
    <div class="row">
        <div class="col-sm-6 form-group">
          <div class="form-floating">
          <input type="text" class="form-control" formControlName="FirstName"
              placeholder="FirstName"
              [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['FirstName'].errors }" id="FirstName" />
          <div *ngIf="submitted && carrierapplyform.controls['FirstName'].errors"
              class="invalid-feedback">
              <div *ngIf="carrierapplyform.controls['FirstName'].errors.required">Required Field
              </div>
            </div>
          <label for="FirstName">FirstName<span style="color: crimson;">*</span></label>
          </div>
        </div>


      <div class="col-sm-6 form-group">
        <div class="form-floating">
        <input type="text" class="form-control" formControlName="LastName"
            placeholder="LastName"
            [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['LastName'].errors }" id="LastName" />
        <div *ngIf="submitted && carrierapplyform.controls['LastName'].errors"
            class="invalid-feedback">
            <div *ngIf="carrierapplyform.controls['LastName'].errors.required">Required Field
            </div>
          </div>
        <label for="LastName">LastName<span style="color: crimson;">*</span></label>
        </div>
      </div>


    <div class="col-sm-6 form-group">
      <div class="form-floating">
      <input type="text" class="form-control" formControlName="Qualification"
          placeholder="Qualification"
          [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['Qualification'].errors }" id="Qualification" />
      <div *ngIf="submitted && carrierapplyform.controls['Qualification'].errors"
          class="invalid-feedback">
          <div *ngIf="carrierapplyform.controls['Qualification'].errors.required">Required Field
          </div>
        </div>
      <label for="Qualification">Qualification<span style="color: crimson;">*</span></label>
      </div>
    </div>
    




   

  <div class="col-sm-6 form-group">
    <div class="form-floating">
    <input type="text" class="form-control" formControlName="ContactNumber"
        placeholder="ContactNumber"
        [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['ContactNumber'].errors }" id="ContactNumber" />
    <div *ngIf="submitted && carrierapplyform.controls['ContactNumber'].errors"
        class="invalid-feedback">
        <div *ngIf="carrierapplyform.controls['ContactNumber'].errors.required">Required Field
        </div>
        <div *ngIf="carrierapplyform.controls['ContactNumber'].errors?.pattern">Invalid Contact Number
        </div>
      </div>
    <label for="ContactNumber">Contact Number<span style="color: crimson;">*</span></label>
    </div>
  </div>
  


<div class="col-sm-6 form-group">
  <div class="form-floating">
  <input type="date" class="form-control" formControlName="DOB"
      placeholder="DOB"
      [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['DOB'].errors }" id="DOB" />
  <div *ngIf="submitted && carrierapplyform.controls['DOB'].errors"
      class="invalid-feedback">
      <div *ngIf="carrierapplyform.controls['DOB'].errors.required">Required Field/Age Should be above 18
      </div>
    </div>
  <label for="DOB">DOB<span style="color: crimson;">*</span></label>
  </div>
</div>





<div class="col-sm-6 form-group">
  <div class="form-floating">
      <!-- <i class="demo-icon uil-envelope-alt"></i> -->
      <select #opt (change)="this.carrierapplyform.get('Gender')?.setValue(opt.value)"  required class="form-select" id="floatingSelect" class="form-control"  [ngClass]="{'is-invalid':submitted && carrierapplyform.controls['Gender'].errors}">
          <option>Select the Gender </option>
          <option *ngFor="let GenderItem of gender " value={{GenderItem.Gender}}>{{GenderItem.Gender}}</option>
        </select>
          <div *ngIf="submitted && carrierapplyform.controls['Gender'].errors" class="invalid-feedback">
            <div *ngIf="carrierapplyform.controls['Gender'].errors">Gender is required</div>
        </div>
        <label for="Gender">Gender<span style="color: crimson;">*</span></label>  
  </div>
  
</div>


                        <div class="col-sm-6 form-group">
                          <div class="form-floating">
                          <input type="email" class="form-control" formControlName="Email"
                              placeholder="Email"
                              [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['Email'].errors }" id="Email" />
                          <div *ngIf="submitted && carrierapplyform.controls['Email'].errors"
                              class="invalid-feedback">
                              <div *ngIf="carrierapplyform.controls['Email'].errors.required">Required Field
                              </div>
                              <div *ngIf="carrierapplyform.controls['Email'].errors.pattern">Invalid Email
                              </div>
                            </div>
                          <label for="Email">Email<span style="color: crimson;">*</span></label>
                          </div>
                        </div>
                        


                       

                      <div class="col-sm-6 form-group">
                        <div class="form-floating">
                        <input type="number" class="form-control" formControlName="Pincode"
                            placeholder="Pincode"
                            [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['Pincode'].errors }" id="Pincode" />
                        <div *ngIf="submitted && carrierapplyform.controls['Pincode'].errors"
                            class="invalid-feedback">
                            <div *ngIf="carrierapplyform.controls['Pincode'].errors.required">Required Field
                            </div>
                            <div *ngIf="carrierapplyform.controls['Pincode'].errors?.pattern">Invalid Pincode
                            </div>
                          </div>
                        <label for="Pincode">Pincode<span style="color: crimson;">*</span></label>
                        </div>
                      </div>
                      



                      <div class="col-sm-6 form-group">
                        <div class="form-floating">
                        <input type="text" class="form-control" formControlName="Address"
                            placeholder="Address"
                            [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['Address'].errors }" id="Address" />
                        <div *ngIf="submitted && carrierapplyform.controls['Address'].errors"
                            class="invalid-feedback">
                            <div *ngIf="carrierapplyform.controls['Address'].errors.required">Required Field
                            </div>
                          </div>
                        <label for="Address">Address<span style="color: crimson;">*</span></label>
                        </div>
                      </div>



                      <div class="col-sm-6 form-group">
                        <div class="form-floating">
                        <input type="number" class="form-control" formControlName="ExpYear"
                            placeholder="ExpYear"
                            [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['ExpYear'].errors }" id="ExpYear" />
                        <div *ngIf="submitted && carrierapplyform.controls['ExpYear'].errors"
                            class="invalid-feedback">
                            <div *ngIf="carrierapplyform.controls['ExpYear'].errors.required">Required Field
                            </div>
                            <div *ngIf="carrierapplyform.controls['ExpYear'].errors.pattern">Enter valid year

                            </div>
                          </div>
                        <label for="ExpYear">Exp Year<span style="color: crimson;">*</span></label>
                        </div>
                      </div>



            


                      <div class="col-sm-6 form-group">
                        <div class="form-floating">
                        <input type="number" class="form-control" formControlName="ExpMonth"
                            placeholder="ExpMonth"
                            [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['ExpMonth'].errors }" id="ExpMonth" />
                        <div *ngIf="submitted && carrierapplyform.controls['ExpMonth'].errors"
                            class="invalid-feedback">
                            <div *ngIf="carrierapplyform.controls['ExpMonth'].errors.required">Required Field
                            </div>
                            <div *ngIf="carrierapplyform.controls['ExpMonth'].errors.pattern">Enter valid Month

                            </div>

                          </div>
                        <label for="ExpMonth">Exp Month<span style="color: crimson;">*</span></label>
                        </div>
                      </div>



                      <div class="col-sm-6 form-group">
                        <div class="form-floating">
                        <input type="text" class="form-control" formControlName="CompanyName"
                            placeholder="CompanyName"
                            [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['CompanyName'].errors }" id="CompanyName" />
                        <div *ngIf="submitted && carrierapplyform.controls['CompanyName'].errors"
                            class="invalid-feedback">
                            <div *ngIf="carrierapplyform.controls['CompanyName'].errors.required">Required Field
                            </div>
                          </div>
                        <label for="CompanyName">Company Name<span style="color: crimson;">*</span></label>
                        </div>
                      </div>

                  

                      <div class="col-sm-6 form-group">
                        <div class="form-floating">
                        <input type="text" class="form-control" formControlName="CurrentSalary"
                            placeholder="CurrentSalary"
                            [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['CurrentSalary'].errors }" id="CurrentSalary" />
                        <div *ngIf="submitted && carrierapplyform.controls['CurrentSalary'].errors"
                            class="invalid-feedback">
                            <div *ngIf="carrierapplyform.controls['CurrentSalary'].errors.required">Required Field
                            </div>
                          </div>
                        <label for="CurrentSalary">Current Salary<span style="color: crimson;">*</span></label>
                        </div>
                      </div>






                      <div class="col-sm-6 form-group">
                        <div class="form-floating">
                        <input type="text" class="form-control" formControlName="ExpectedSalary"
                            placeholder="ExpectedSalary"
                            [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['ExpectedSalary'].errors }" id="ExpectedSalary" />
                        <div *ngIf="submitted && carrierapplyform.controls['ExpectedSalary'].errors"
                            class="invalid-feedback">
                            <div *ngIf="carrierapplyform.controls['ExpectedSalary'].errors.required">Required Field
                            </div>
                          </div>
                        <label for="ExpectedSalary">Expected Salary<span style="color: crimson;">*</span></label>
                        </div>
                      </div>
                      <div class="col-sm-6 form-group">
                        <div class="form-floating">
                            <!-- <i class="demo-icon uil-envelope-alt"></i> -->
                            <input  accept="pdf,msword,document"
                            (change)="fileCheckForSlip($event.target,'CVFileName')"
                            class="from-control" type="file"  formControlName="CVFileName"
                                 [ngClass]="{'is-invalid':submitted && carrierapplyform.controls['CVFileName'].errors}">
                                <div *ngIf="submitted && carrierapplyform.controls['CVFileName'].errors" class="invalid-feedback">
                                  <div *ngIf="carrierapplyform.controls['CVFileName'].errors">Required Field</div>
                              </div>
                        </div>
                        <label for="CVFileName">CV/Resume<span style="color: crimson;">*</span></label>
                    </div>

                  
                    <div class="col-sm-6 form-group">
                      <div class="row">
                      <div class="col-lg-5 col-md-5 col-sm-5">
                        <div class="mt-10">
                         <div class="captcha-text">{{this.captcha}}  <i (click)="captchaGenerate()" class="fa fa-refresh" aria-hidden="true"></i> </div>
                      </div>  
                       </div>
                      <div class="col-lg-7 col-md-7 col-sm-7">
                      <div class="form-floating">
                      <input type="text" class="form-control" formControlName="Captcha"
                          placeholder="Captcha"
                          [ngClass]="{ 'is-invalid': submitted && carrierapplyform.controls['Captcha'].errors }" id="Captcha" />
                      <div *ngIf="submitted && carrierapplyform.controls['Captcha'].errors"
                          class="invalid-feedback">
                          <div *ngIf="carrierapplyform.controls['Captcha'].errors.required">Required Field
                          </div>
                        </div>
                      <label for="Captcha">Captcha<span style="color: crimson;">*</span></label>
                      </div>
                      </div>
                         
                      </div>
                    </div>

         <div class="row">
            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                <button (click)="onSubmit()"class="greenbtn2">Submit <i
                        class="demo-icon uil-arrow-right"></i></button>
            </div>
            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                <div class="text-right md-text-left">
                    <button (click)="reset()" 
                        class="bluebtn2">Reset<i class="demo-icon uil-redo"></i></button>
                </div>
            </div>
         </div>

         <div *ngIf="alertType == 'submit'"
         [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
         class="card card-inverse-info" id="context-menu-simple">
         <div class="card-body">
             <p class="card-text">
                 {{alertMsg}}
         </div>
     </div>
        </div>
     </form>
     <div class="text-right mt-20">
      <p class="mb-0"><b><i>Please note all fields marked <span class="text-danger">*</span> are
                  compulsory.</i></b></p>
     </div>
     </div>
     </div>
     </div>
     </div>
    



    
                  






