import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FooterComponent } from './footer/footer.component';
import { RightMenuComponent } from './right-menu/right-menu.component';
import { HomePageContentComponent } from './home-page-content/home-page-content.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxCaptchaModule } from 'ngx-captcha';
import { GoogleMapsModule } from '@angular/google-maps';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { MenuTabComponent } from './Component/menu-tab/menu-tab.component';

import { CustomerLoginComponent } from './Component/customer-login/customer-login.component';
import { CustomerUserRegistrationComponent } from './Component/customer-user-registration/customer-user-registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerDashboardComponent } from './Component/customer-dashboard/customer-dashboard.component';
import { AwardsComponent } from './Component/awards/awards.component';
import { AboutMglComponent } from './Component/about-mgl/about-mgl.component';
import { GalleryComponent } from './Component/gallery/gallery.component';
import { BillingCycleComponent } from './Component/billing-cycle/billing-cycle.component';
import { ComPngApplicationComponent } from './Component/com-png-application/com-png-application.component';
import { LogMeterReadingComponent } from './Component/log-meter-reading/log-meter-reading.component';
import { NachOnlineComponent } from './Component/nach-online/nach-online.component';
import { NewsListComponent } from './Component/news-list/news-list.component';
import { TipsComponent } from './Component/tips/tips.component';
import { RecentPostComponent } from './Component/recent-post/recent-post.component';
import { TypesOfIndustriesComponent } from './Component/types-of-industries/types-of-industries.component';
import { FaqListComponent } from './Component/faq-list/faq-list.component';
import { BlogListComponent } from './Component/blog-list/blog-list.component';
import { BlogDetailsComponent } from './Component/blog-details/blog-details.component';
import * as $ from 'jquery';
import { DashboardInfoCardComponent } from './Component/dashboard-info-card/dashboard-info-card.component';
import { ServiceSectionComponent } from './Component/service-section/service-section.component'
import { PrivacypolicyComponent } from './Component/privacypolicy/privacypolicy.component';
import { DisclamerComponent } from './Component/disclamer/disclamer.component';
import { LifemglComponent } from './Component/lifemgl/lifemgl.component';
import { ActiveTenderComponent } from './Component/active-tender/active-tender.component';
import { BillingInfoComponent } from './Component/billing-info/billing-info.component';
import { CngFillingstationComponent } from './Component/cng-fillingstation/cng-fillingstation.component';
import { CurrentOppComponent } from './Component/current-opp/current-opp.component';
import { CustomerCareOverviewComponent } from './Component/customer-care-overview/customer-care-overview.component';
import { EmdFormatComponent } from './Component/emd-format/emd-format.component';
import { InstructionToBiddersComponent } from './Component/instruction-to-bidders/instruction-to-bidders.component';
import { MglJourneyComponent } from './Component/mgl-journey/mgl-journey.component';
import { OnlinePaymentComponent } from './Component/online-payment/online-payment.component';
import { OurObjectiveComponent } from './Component/our-objective/our-objective.component';
import { OverviewComponent } from './Component/overview/overview.component';
import { SecurityDepositComponent } from './Component/security-deposit/security-deposit.component';
import { CsrPolicyComponent } from './Component/CSR/csr-policy/csr-policy.component';
import { CsrInitiativeComponent } from './Component/CSR/csr-initiative/csr-initiative.component';
import { CsrInitiativeEnvComponent } from './Component/CSR/csr-initiative-env/csr-initiative-env.component';
import { CsrInitiativeEducComponent } from './Component/CSR/csr-initiative-educ/csr-initiative-educ.component';
import { CsrInitiativeEmpComponent } from './Component/CSR/csr-initiative-emp/csr-initiative-emp.component';
import { CsrOtherInitiativeComponent } from './Component/CSR/csr-other-initiative/csr-other-initiative.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomerSaftyCheckListComponent } from './Component/customer-safty-check-list/customer-safty-check-list.component';
import { TechGuideForPngInstallComponent } from './Component/tech-guide-for-png-install/tech-guide-for-png-install.component';
import { AfterSaleServiceChargeComponent } from './Component/after-sale-service-charge/after-sale-service-charge.component';
import { ImportantPolicyComponent } from './Component/important-policy/important-policy.component';
import { CustomerPaymentHistoryComponent } from './Component/customer-payment-history/customer-payment-history.component';
import { CustomerBillingHistoryComponent } from './Component/customer-billing-history/customer-billing-history.component';
import { IndapplyComponent } from './Component/indapply/indapply.component';
import { InddocumentComponent } from './Component/inddocument/inddocument.component';
import { HowtoapplyComponent } from './Component/howtoapply/howtoapply.component';
import { DocumentrequireComponent } from './Component/documentrequire/documentrequire.component';
import { PngpaymentComponent } from './Component/pngpayment/pngpayment.component';
import { ChangePasswordComponent } from './Component/change-password/change-password.component';
import { LogcomplaintComponent } from './Component/logcomplaint/logcomplaint.component';
import { ViewcomplaintComponent } from './Component/viewcomplaint/viewcomplaint.component';
import { EditProfileComponent } from './Component/edit-profile/edit-profile.component';
import { BenefitsComponent } from './Component/benefits/benefits.component';
import { ClienteleComponent } from './Component/clientele/clientele.component';
import { CautionNoticeComponent } from './Component/caution-notice/caution-notice.component';
import { OverviewEnviroBenifitsComponent } from './Component/overview-enviro-benifits/overview-enviro-benifits.component';
import { WalkinCenterComponent } from './Component/walkin-center/walkin-center.component';
import { PaymentLinkComponent } from './Component/payment-link/payment-link.component';
import { HowbilliscalculatedComponent } from './Component/howbilliscalculated/howbilliscalculated.component';
import { GasgyserrateComponent } from './Component/gasgyserrate/gasgyserrate.component';
import { CngratecardComponent } from './Component/cngratecard/cngratecard.component';

import { ReqmtForOpenPlotComponent } from './Component/reqmt-for-open-plot/reqmt-for-open-plot.component';
import { DomesticConnectionComponent } from './Component/domestic-connection/domestic-connection.component';
import { CngtetingstationComponent } from './Component/cngtetingstation/cngtetingstation.component';
import { NewsDetailComponent } from './Component/news-detail/news-detail.component';
import { CngkitsupplierComponent } from './Component/cngkitsupplier/cngkitsupplier.component';
import { ConversionEnquiryFormComponent } from './Component/conversion-enquiry-form/conversion-enquiry-form.component';
import { LeadershipComponent } from './Component/leadership/leadership.component';
import { AnnualReportComponent } from './Component/annual-report/annual-report.component';
import { AuditedResultsComponent } from './Component/audited-results/audited-results.component';
import { ApplyForNewConnectionComponent } from './Component/apply-for-new-connection/apply-for-new-connection.component';
import { HowToApplyOnlineComponent } from './Component/how-to-apply-online/how-to-apply-online.component';
import { DocumentsRequiredComponent } from './Component/documents-required/documents-required.component';
import { CngMobileRefuelingUnitComponent } from './Component/cng-mobile-refueling-unit/cng-mobile-refueling-unit.component';
import { EmpanelMentNoticeComponent } from './Component/empanel-ment-notice/empanel-ment-notice.component';
import { CngupcomingreportsComponent } from './Component/cngupcomingreports/cngupcomingreports.component';
import { CarcareComponent } from './Component/carcare/carcare.component';
import { PngRateCardComponent } from './Component/png-rate-card/png-rate-card.component';
import { HesPolicyComponent } from './Component/hes-policy/hes-policy.component';
import { EmpanelmentComponent } from './Component/empanelment/empanelment.component';
import { BiddersOfHolidayComponent } from './Component/bidders-of-holiday/bidders-of-holiday.component';
import { QuarterlyResultComponent } from './Component/quarterly-result/quarterly-result.component';
import { InvestorGuideComponent } from './Component/investor-guide/investor-guide.component';
import { PoliciesComponent } from './Component/policies/policies.component';
import { CorporateGovernanceComponent } from './Component/corporate-governance/corporate-governance.component';
import { AnnualGeneralMeetingComponent } from './Component/annual-general-meeting/annual-general-meeting.component';
import { DividendPolicyComponent } from './Component/dividend-policy/dividend-policy.component';
import { UnclaimedDividendComponent } from './Component/unclaimed-dividend/unclaimed-dividend.component';
import { CustomerAwarenessComponent } from './Component/customer-awareness/customer-awareness.component'
import { FeedbackInquiryComponent } from './Component/feedback-inquiry/feedback-inquiry.component';
import { IfYouSmellGasComponent } from './Component/if-you-smell-gas/if-you-smell-gas.component';
import { MglLifeSavingRulesComponent } from './Component/mgl-life-saving-rules/mgl-life-saving-rules.component';
import { EnvironmentalComplianceComponent } from './Component/environmental-compliance/environmental-compliance.component';
import { DropboxComponent } from './Component/dropbox/dropbox.component';
import { NaturalGasDataSheetComponent } from './Component/natural-gas-data-sheet/natural-gas-data-sheet.component';
import { RegisteredandcorporateofficeaddressComponent } from './Component/registeredandcorporateofficeaddress/registeredandcorporateofficeaddress.component';
import { MGLQualityPolicyComponent } from './Component/mglquality-policy/mglquality-policy.component';
import { SafetyAsCoreValueComponent } from './Component/safety-as-core-value/safety-as-core-value.component';
import { AssetIntegrityComponent } from './Component/asset-integrity/asset-integrity.component';
import { STCTrainingComponent } from './Component/stc-training/stc-training.component';
import { SafetyFeaturesComponent } from './Component/safety-features/safety-features.component';
import { VideogalleryComponent } from './Component/videogallery/videogallery.component';
import { MglSpiritStmtComponent } from './Component/mgl-spirit-stmt/mgl-spirit-stmt.component';
import { OurApproachtoSustainabilityComponent } from './Component/our-approachto-sustainability/our-approachto-sustainability.component';
import { ServiceproviderComponent } from './Component/serviceprovider/serviceprovider.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { ChargestobetakenComponent } from './Component/PngServicecharges/chargestobetaken.component';
import { NametranferComponent } from './Component/nametranfer/nametranfer.component';
import { ApplyfornameComponent } from './Component/applyforname/applyforname.component';
import { InvestorUpdateAndPresentationComponent } from './Component/investor-update-and-presentation/investor-update-and-presentation.component';
import { ShareHoldingPatternComponent } from './Component/share-holding-pattern/share-holding-pattern.component';
import { SitemapComponent } from './Component/sitemap/sitemap.component';
import { CNGsavingcalculatorComponent } from './Component/cngsavingcalculator/cngsavingcalculator.component';
import { CurrentBillComponent } from './Component/current-bill/current-bill.component';
import { ApplyforcngouletComponent } from './Component/applyforcngoulet/applyforcngoulet.component';
import { CustomersupportComponent } from './Component/customersupport/customersupport.component';
import { CngFeedbackFormComponent } from './Component/cng-feedback-form/cng-feedback-form.component';
import { CngComplaintStatusComponent } from './Component/cng-complaint-status/cng-complaint-status.component';
import { ForgotPasswordComponent } from './Component/forgot-password/forgot-password.component';
import { CnglocatorsComponent } from './Component/cnglocators/cnglocators.component';
import { QrPayComponent } from './Component/qr-pay/qr-pay.component';
import { AuthorizedpersonComponent } from './Component/authorizedperson/authorizedperson.component';
import { DomesticConReqFormComponent } from './Component/domestic-con-req-form/domestic-con-req-form.component';
import { DomesticConfromNonGasifiedNotAvailComponent } from './Component/domestic-confrom-non-gasified-not-avail/domestic-confrom-non-gasified-not-avail.component';
import { DomesticConfromNonGasifiedNotAvailIsBeenLaidComponent } from './Component/domestic-confrom-non-gasified-not-avail-is-been-laid/domestic-confrom-non-gasified-not-avail-is-been-laid.component';
import { ConnectionApplicationStatusComponent } from './Component/connection-application-status/connection-application-status.component';
import { TrackNameTransferReqComponent } from './Component/track-name-transfer-req/track-name-transfer-req.component';
import { DomesticPngConnGasifiedBulidComponent } from './Component/domestic-png-conn-gasified-bulid/domestic-png-conn-gasified-bulid.component';
import { ContactUsComponent } from './Component/contact-us/contact-us.component';
import { CarrierjobapplyComponent } from './Component/carrierjobapply/carrierjobapply.component';
import { BlogarticleComponent } from './Component/blogarticle/blogarticle.component';
import { NgxPrintModule } from 'ngx-print';
import { MGLSahayogiDialbeforeDigComponent } from './Component/mglsahayogi-dialbefore-dig/mglsahayogi-dialbefore-dig.component';
import { IcBillComponent } from './Component/ic-bill/ic-bill.component'
import { NachCancellationComponent } from './Component/nach-cancellation/nach-cancellation.component';
import { PngNetworkComponent } from './Component/png-network/png-network.component';
import { RefundPolicyComponent } from './Component/refund-policy/refund-policy.component';
import { StockExchangeComponent } from './Component/stock-exchange/stock-exchange.component';
import { IandCNachCancellationComponent } from './Component/iand-cnach-cancellation/iand-cnach-cancellation.component';
import { BloglistsComponent } from './Component/bloglists/bloglists.component';
import { TenderComponent } from './Component/tender/tender.component';
import { VehiclepromotionComponent } from './Component/vehiclepromotion/vehiclepromotion.component';
import { QuickPayComponent } from './Component/quick-pay/quick-pay.component';
import { ThankYouComponent } from './Component/thank-you/thank-you.component';
import { EditVehiclepromotionComponent } from './Component/edit-vehiclepromotion/edit-vehiclepromotion.component';
import { EditVehiclepromotionforeditComponent } from './Component/edit-vehiclepromotionforedit/edit-vehiclepromotionforedit.component';
import { ViewYourBillComponent } from './Component/view-your-bill/view-your-bill.component';
import { PaymentErrorComponent } from './Component/payment-error/payment-error.component';
import { PaymentSuccessComponent } from './Component/payment-success/payment-success.component';
import { NewsCompComponent } from './Component/news-comp/news-comp.component';
import { DisableRightClickService } from './DisableRightClickService';
import { ThankYouOutletComponent } from './Component/thank-you-outlet/thank-you-outlet.component';
import { ResidentialPngServiceSectionComponent } from './Component/residential-png-service-section/residential-png-service-section.component';
import { CngServiceSectionComponent } from './Component/cng-service-section/cng-service-section.component';
import { CommercialServiceSectionComponent } from './Component/commercial-service-section/commercial-service-section.component';
import { IndustrialServiceSectionComponent } from './Component/industrial-service-section/industrial-service-section.component';
import { MglCorporateServiceSectionComponent } from './Component/mgl-corporate-service-section/mgl-corporate-service-section.component';
import { IcQrPayComponent } from './Component/ic-qr-pay/ic-qr-pay.component';
import { AwardDetailsComponent } from './Component/award-details/award-details.component';
import { AwardsListComponent } from './Component/awards-list/awards-list.component';
import { SavingsKiShubhShuruaatBrochureComponent } from './Component/savings-ki-shubh-shuruaat-brochure/savings-ki-shubh-shuruaat-brochure.component';
import { MgltezComponent } from './Component/mgltez/mgltez.component';
import { ExpressionOfInterestComponent } from './Component/expression-of-interest/expression-of-interest.component';
import { NtdcComponent } from './other/ntdc/ntdc.component';
import { NtlcComponent } from './other/ntlc/ntlc.component';
import { NtzcComponent } from './other/ntzc/ntzc.component';
import { NtzdComponent } from './other/ntzd/ntzd.component';
import { StatusofopenrequestComponent } from './Component/statusofopenrequest/statusofopenrequest.component';
import { SteelpipelinenetworklayoutComponent } from './Component/steelpipelinenetworklayout/steelpipelinenetworklayout.component';
import { VisionComponent } from './Component/vision/vision.component';
import { ResetPasswordComponent } from './Component/reset-password/reset-password.component';
import { NametransferdetailsComponent } from './Component/nametransferdetails/nametransferdetails.component';
import { ApplyfornameEditComponent } from './Component/applyforname-edit/applyforname-edit.component';
import { EtendersapsrmComponent } from './Component/etendersapsrm/etendersapsrm.component'
import { CommercialtariffcardComponent } from './Component/commercialtariffcard/commercialtariffcard.component';
import { IndustrialtariffcardComponent } from './Component/industrialtariffcard/industrialtariffcard.component'
import { OpenplotnearusarComponent } from './Component/openplotnearusar/openplotnearusar.component'
import { WithoutloginlogmeterreadingComponent } from './Component/withoutloginlogmeterreading/withoutloginlogmeterreading.component';
import { CsrprojectComponent } from './Component/csrproject/csrproject.component';
import { NewslettersComponent } from './Component/newsletters/newsletters.component';
import { NewslettersdetailsComponent } from './Component/newslettersdetails/newslettersdetails.component';
import { NachpaymentsuccesssComponent } from './Component/nachpaymentsuccesss/nachpaymentsuccesss.component';
import { NachpaymenterrorComponent } from './Component/nachpaymenterror/nachpaymenterror.component';
import { UpiautopayComponent } from './Component/upiautopay/upiautopay.component';
import { RetrofittersdetailsComponent } from './Component/retrofittersdetails/retrofittersdetails.component';
import { NewCNGVehicleComponent } from './Component/new-cngvehicle/new-cngvehicle.component';
import { RetrofitmentComponent } from './Component/retrofitment/retrofitment.component';
import { CngmahotsavmarutisuzukiComponent } from './Component/cngmahotsavmarutisuzuki/cngmahotsavmarutisuzuki.component';
import { RetrofitmentFAQsComponent } from './Component/retrofitment-faqs/retrofitment-faqs.component';
import { NewvehicleFAQComponent } from './Component/newvehicle-faq/newvehicle-faq.component';
import { CngmahotsavmarutisuzukiappstatusComponent } from './Component/cngmahotsavmarutisuzukiappstatus/cngmahotsavmarutisuzukiappstatus.component';
import { CngmahotsavmarutisuzukiappdetailsComponent } from './Component/cngmahotsavmarutisuzukiappdetails/cngmahotsavmarutisuzukiappdetails.component';
import { MglcngmahotsavComponent } from './Component/mglcngmahotsav/mglcngmahotsav.component';
import { CngmahotsavmarutisuzukiapprovalComponent } from './Component/cngmahotsavmarutisuzukiapproval/cngmahotsavmarutisuzukiapproval.component';
import { QuarterlycorporategovernanceComponent } from './Component/quarterlycorporategovernance/quarterlycorporategovernance.component';
import { MarutisuzukithankyouComponent } from './Component/marutisuzukithankyou/marutisuzukithankyou.component';
import { DownloadapplinkComponent } from './Component/downloadapplink/downloadapplink.component';
import { OemsnewvehiclesComponent } from './Component/oemsnewvehicles/oemsnewvehicles.component';
import { OemsnewvehiclesdetailsComponent } from './Component/oemsnewvehiclesdetails/oemsnewvehiclesdetails.component';
import { OemsnewvehiclesapprovalComponent } from './Component/oemsnewvehiclesapproval/oemsnewvehiclesapproval.component';
import { OemsnewvehiclesstatusComponent } from './Component/oemsnewvehiclesstatus/oemsnewvehiclesstatus.component';
import { CngmahotsavmarutisuzukiapplicationeditComponent } from './Component/cngmahotsavmarutisuzukiapplicationedit/cngmahotsavmarutisuzukiapplicationedit.component';
import { OemsnewvehicleseditComponent } from './Component/oemsnewvehiclesedit/oemsnewvehiclesedit.component';
import { RetrofittersloginComponent } from './Component/retrofitterslogin/retrofitterslogin.component';
import { RetrofittersappstatusComponent } from './Component/retrofittersappstatus/retrofittersappstatus.component';
import { RetrofittersappdetailsComponent } from './Component/retrofittersappdetails/retrofittersappdetails.component';
import { RetrofittersappeditComponent } from './Component/retrofittersappedit/retrofittersappedit.component';
import { DomesticpngconngasifiedbulidnewComponent } from './Component/domesticpngconngasifiedbulidnew/domesticpngconngasifiedbulidnew.component';
import { ConnectionApplicationStatusnewComponent } from './Component/connection-application-statusnew/connection-application-statusnew.component';
import { PaymentSuccessTestComponent } from './Component/payment-success-test/payment-success-test.component';
import { PaymentErrorTestComponent } from './Component/payment-error-test/payment-error-test.component';
import { BillingtestComponent } from './Component/billingtest/billingtest.component';
import { GenerateyourbillComponent } from './Component/generateyourbill/generateyourbill.component';
import { NationalpngdriveComponent } from './Component/nationalpngdrive/nationalpngdrive.component';
import { MglcorporatebroucherComponent } from './Component/mglcorporatebroucher/mglcorporatebroucher.component';
import { LoginwithotpComponent } from './Component/loginwithotp/loginwithotp.component';
import { Mr1Component } from './other/mr1/mr1.component';
import { EbillzprojectComponent } from './other/ebillzproject/ebillzproject.component';
import { MeterreadingprojectComponent } from './other/meterreadingproject/meterreadingproject.component';
import { NexgenprojectComponent } from './other/nexgenproject/nexgenproject.component';

import { ApppayupaymentsuccessComponent } from './Component/apppayupaymentsuccess/apppayupaymentsuccess.component';
import { ApppayupaymenterrorComponent } from './Component/apppayupaymenterror/apppayupaymenterror.component';

import { PayuwalletComponent } from './Component/payuwallet/payuwallet.component';
import { PayuwalletbalanceComponent } from './Component/payuwalletbalance/payuwalletbalance.component';
import { PayuwalletthankyouComponent } from './Component/payuwalletthankyou/payuwalletthankyou.component';
import { PayupaymentsuccessComponent } from './Component/payupaymentsuccess/payupaymentsuccess.component';
import { PayupaymenterrorComponent } from './Component/payupaymenterror/payupaymenterror.component';
import { PayUpaymenterrorComponent } from './Component/pay-upaymenterror/pay-upaymenterror.component';
import { PayUpaymentsuccessComponent } from './Component/pay-upaymentsuccess/pay-upaymentsuccess.component';
import { PayUunloadpaymenterrorComponent } from './Component/pay-uunloadpaymenterror/pay-uunloadpaymenterror.component';
import { PayUunloadpaymentsuccessComponent } from './Component/pay-uunloadpaymentsuccess/pay-uunloadpaymentsuccess.component';
import { ViewpayutransactionComponent } from './Component/viewpayutransaction/viewpayutransaction.component';
import { ApplyfornametestComponent } from './Component/applyfornametest/applyfornametest.component';
import { ApplyfornametsteponeComponent } from './Component/applyfornametstepone/applyfornametstepone.component';
import { ApplyfornametsteptwoComponent } from './Component/applyfornametsteptwo/applyfornametsteptwo.component';
import { ApplyfornametstepthreeComponent } from './Component/applyfornametstepthree/applyfornametstepthree.component';
import { ApplyfornametstepfourComponent } from './Component/applyfornametstepfour/applyfornametstepfour.component';
import { NametransferthankyouComponent } from './Component/nametransferthankyou/nametransferthankyou.component';
import { PledgeComponent } from './Component/pledge/pledge.component';
import { PledgethankyouComponent } from './Component/pledgethankyou/pledgethankyou.component';
import { ApplyfornametsteponeeditComponent } from './Component/applyfornametsteponeedit/applyfornametsteponeedit.component';
import { ApplyfornametsteptwoeditComponent } from './Component/applyfornametsteptwoedit/applyfornametsteptwoedit.component';
import { ApplyfornametstepthreeeditComponent } from './Component/applyfornametstepthreeedit/applyfornametstepthreeedit.component';
import { RetrofittersleveloneComponent } from './Component/retrofitterslevelone/retrofitterslevelone.component';
import { RetrofittersleveltwoComponent } from './Component/retrofittersleveltwo/retrofittersleveltwo.component';
import { RetrofitterslevelonestatusComponent } from './Component/retrofitterslevelonestatus/retrofitterslevelonestatus.component';
import { RetrofitterslevelonedetailsComponent } from './Component/retrofitterslevelonedetails/retrofitterslevelonedetails.component';
import { RetrofittersleveltwostatusComponent } from './Component/retrofittersleveltwostatus/retrofittersleveltwostatus.component';
import { RetrofittersleveltwodetailsComponent } from './Component/retrofittersleveltwodetails/retrofittersleveltwodetails.component';
import { RetrofittersleveltwoeditComponent } from './Component/retrofittersleveltwoedit/retrofittersleveltwoedit.component';
import { RetrofittersleveloneeditComponent } from './Component/retrofittersleveloneedit/retrofittersleveloneedit.component';
import { DealerzonefaqsComponent } from './Component/dealerzonefaqs/dealerzonefaqs.component';
import { OpenplotnearjitegaonComponent } from './Component/openplotnearjitegaon/openplotnearjitegaon.component';
import { ListofdocumentrequiredComponent } from './Component/listofdocumentrequired/listofdocumentrequired.component';
import { ProcessofloiissuanceComponent } from './Component/processofloiissuance/processofloiissuance.component';
import { Oemsnewvehicles20Component } from './Component/oemsnewvehicles20/oemsnewvehicles20.component';
import { Oemsnewvehicles20detailsComponent } from './Component/oemsnewvehicles20details/oemsnewvehicles20details.component';
import { Oemsnewvehicles20editComponent } from './Component/oemsnewvehicles20edit/oemsnewvehicles20edit.component';
import { Oemsnewvehicles20statusComponent } from './Component/oemsnewvehicles20status/oemsnewvehicles20status.component';
import { Oemsnewvehicles20approveComponent } from './Component/oemsnewvehicles20approve/oemsnewvehicles20approve.component';
import { DomesticPngConnGasifiedBulidInstitutionComponent } from './Component/domestic-png-conn-gasified-bulid-institution/domestic-png-conn-gasified-bulid-institution.component';
import { PaymentInstitutionSuccessComponent } from './Component/payment-institution-success/payment-institution-success.component';
import { PaymentInstitutionErrorComponent } from './Component/payment-institution-error/payment-institution-error.component';
import { ConnectionApplicationInstitutionStatusComponent } from './Component/connection-application-institution-status/connection-application-institution-status.component';
import { Mglcngmaostav20dashboardComponent } from './Component/mglcngmaostav20dashboard/mglcngmaostav20dashboard.component';
import { Mglcngmaostav20oemdashboardComponent } from './Component/mglcngmaostav20oemdashboard/mglcngmaostav20oemdashboard.component';
import { Oemsnewvehicles20leveltwostatusComponent } from './Component/oemsnewvehicles20leveltwostatus/oemsnewvehicles20leveltwostatus.component';
import { Oemsnewvehicles20leveltwodetailsComponent } from './Component/oemsnewvehicles20leveltwodetails/oemsnewvehicles20leveltwodetails.component';
import { Oemsnewvehicles20leveltwoeditComponent } from './Component/oemsnewvehicles20leveltwoedit/oemsnewvehicles20leveltwoedit.component';
import { Retrofitters20leveloneComponent } from './Component/retrofitters20levelone/retrofitters20levelone.component';
import { Retrofitters20leveltwoComponent } from './Component/retrofitters20leveltwo/retrofitters20leveltwo.component';
import { Retrofitters20levelonestatusComponent } from './Component/retrofitters20levelonestatus/retrofitters20levelonestatus.component';
import { Retrofitters20levelonedetailsComponent } from './Component/retrofitters20levelonedetails/retrofitters20levelonedetails.component';
import { Retrofitters20leveltwostatusComponent } from './Component/retrofitters20leveltwostatus/retrofitters20leveltwostatus.component';
import { Retrofitters20leveltwodetailsComponent } from './Component/retrofitters20leveltwodetails/retrofitters20leveltwodetails.component';
import { Retrofitters20leveltwoeditComponent } from './Component/retrofitters20leveltwoedit/retrofitters20leveltwoedit.component';
import { Retrofitters20leveloneditComponent } from './Component/retrofitters20levelonedit/retrofitters20levelonedit.component';
import { Marutisuzuki20thankyouComponent } from './Component/marutisuzuki20thankyou/marutisuzuki20thankyou.component';
import { ApplyforcngouletnewComponent } from './Component/applyforcngouletnew/applyforcngouletnew.component';
import { ApplyoutletstatusComponent } from './Component/applyoutletstatus/applyoutletstatus.component';
import { SubsidiaryFinancialsComponent } from './Component/subsidiary-financials/subsidiary-financials.component';
import { UnisonEnviroPrivateLimitedComponent } from './Component/unison-enviro-private-limited/unison-enviro-private-limited.component';
import { MahanagarLNGPrivateLimitedComponent } from './Component/mahanagar-lngprivate-limited/mahanagar-lngprivate-limited.component';
import { ApplyoutletthankyouComponent } from './Component/applyoutletthankyou/applyoutletthankyou.component';

import { ApppaymentSuccessComponent } from './Component/apppayment-success/apppayment-success.component';
import { ApppaymentErrorComponent } from './Component/apppayment-error/apppayment-error.component';
import { FacebookPostComponent } from './Component/facebook-post/facebook-post.component';
import { InstaPostComponent } from './Component/insta-post/insta-post.component';
import { CommercialfaqsComponent } from './Component/commercialfaqs/commercialfaqs.component';
import { IndustrialfaqsComponent } from './Component/industrialfaqs/industrialfaqs.component';
import { OemloginComponent } from './Component/oemlogin/oemlogin.component';
import { ApplyforcngouletmComponent } from './Component/applyforcngouletm/applyforcngouletm.component';
import { ApplyforcngouletnewmComponent } from './Component/applyforcngouletnewm/applyforcngouletnewm.component';
import { ApplyoutletthankyoumComponent } from './Component/applyoutletthankyoum/applyoutletthankyoum.component';
import { ThankYouOutletmComponent } from './Component/thank-you-outletm/thank-you-outletm.component';
import { ApplyoutletstatusmComponent } from './Component/applyoutletstatusm/applyoutletstatusm.component';
import { Oem20faqsComponent } from './Component/oem20faqs/oem20faqs.component';
import { ListofmglcngstationsComponent } from './Component/listofmglcngstations/listofmglcngstations.component';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    NavBarComponent,
    FooterComponent,
    RightMenuComponent,
    HomePageContentComponent,
    MenuTabComponent,
    CustomerLoginComponent,
    CustomerUserRegistrationComponent,
    CustomerDashboardComponent,
    AwardsComponent,
    AboutMglComponent,
    GalleryComponent,
    BillingCycleComponent,
    ComPngApplicationComponent,
    LogMeterReadingComponent,
    NachOnlineComponent,
    NewsListComponent,
    TipsComponent,
    RecentPostComponent,
    TypesOfIndustriesComponent,
    FaqListComponent,
    BlogListComponent,
    BlogDetailsComponent,
    DashboardInfoCardComponent,
    ServiceSectionComponent,
    PrivacypolicyComponent,
    DisclamerComponent,
    LifemglComponent,
    ActiveTenderComponent,
    BillingInfoComponent,
    CngFillingstationComponent,
    CurrentOppComponent,
    CustomerCareOverviewComponent,
    EmdFormatComponent,
    InstructionToBiddersComponent,
    MglJourneyComponent,
    OnlinePaymentComponent,
    OurObjectiveComponent,
    OverviewComponent,
    SecurityDepositComponent,
    CsrPolicyComponent,
    CsrInitiativeComponent,
    CsrInitiativeEnvComponent,
    CsrInitiativeEducComponent,
    CsrInitiativeEmpComponent,
    CsrOtherInitiativeComponent,
    CustomerSaftyCheckListComponent,
    TechGuideForPngInstallComponent,
    AfterSaleServiceChargeComponent,
    ImportantPolicyComponent,
    CustomerPaymentHistoryComponent,
    CustomerBillingHistoryComponent,
    InddocumentComponent,
    HowtoapplyComponent,
    DocumentrequireComponent,
    PngpaymentComponent,
    ChangePasswordComponent,
    IndapplyComponent,
    LogcomplaintComponent,
    ViewcomplaintComponent,
    EditProfileComponent,
    BenefitsComponent,
    ClienteleComponent,
    CautionNoticeComponent,
    OverviewEnviroBenifitsComponent,
    WalkinCenterComponent,
    PaymentLinkComponent,
    HowbilliscalculatedComponent,
    GasgyserrateComponent,
    CngratecardComponent,
    ReqmtForOpenPlotComponent,
    DomesticConnectionComponent,
    CngtetingstationComponent,
    NewsDetailComponent,
    CngkitsupplierComponent,
    ConversionEnquiryFormComponent,
    LeadershipComponent,
    AnnualReportComponent,
    AuditedResultsComponent,
    ApplyForNewConnectionComponent,
    HowToApplyOnlineComponent,
    DocumentsRequiredComponent,
    CngMobileRefuelingUnitComponent,
    EmpanelMentNoticeComponent,
    CngupcomingreportsComponent,
    CarcareComponent,
    PngRateCardComponent,
    HesPolicyComponent,
    EmpanelmentComponent,
    BiddersOfHolidayComponent,
    QuarterlyResultComponent,
    InvestorGuideComponent,
    PoliciesComponent,
    CorporateGovernanceComponent,
    AnnualGeneralMeetingComponent,
    DividendPolicyComponent,
    UnclaimedDividendComponent,
    CustomerAwarenessComponent,
    FeedbackInquiryComponent,
    IfYouSmellGasComponent,
    MglLifeSavingRulesComponent,
    EnvironmentalComplianceComponent,
    DropboxComponent,
    NaturalGasDataSheetComponent,
    MGLQualityPolicyComponent,
    RegisteredandcorporateofficeaddressComponent,
    SafetyAsCoreValueComponent,
    AssetIntegrityComponent,
    STCTrainingComponent,
    SafetyFeaturesComponent,
    VideogalleryComponent,
    MglSpiritStmtComponent,
    OurApproachtoSustainabilityComponent,
    ServiceproviderComponent,
    PageLoaderComponent,
    ChargestobetakenComponent,
    NametranferComponent,
    ApplyfornameComponent,
    InvestorUpdateAndPresentationComponent,
    ShareHoldingPatternComponent,
    SitemapComponent,
    CNGsavingcalculatorComponent,
    CurrentBillComponent,
	ApplyforcngouletComponent,
	CustomersupportComponent,
    CngFeedbackFormComponent,
    CngComplaintStatusComponent,
    ForgotPasswordComponent,
	CnglocatorsComponent,
	QrPayComponent,
    AuthorizedpersonComponent,
    DomesticConReqFormComponent,
    DomesticConfromNonGasifiedNotAvailComponent,
    DomesticConfromNonGasifiedNotAvailIsBeenLaidComponent,
    ConnectionApplicationStatusComponent,
    TrackNameTransferReqComponent,
    DomesticPngConnGasifiedBulidComponent,
	ContactUsComponent,
    CarrierjobapplyComponent,
	BlogarticleComponent,
	MGLSahayogiDialbeforeDigComponent,
	IcBillComponent,
    NachCancellationComponent,
    PngNetworkComponent,
    RefundPolicyComponent,
	StockExchangeComponent,
    IandCNachCancellationComponent,
    BloglistsComponent,
	TenderComponent,
	VehiclepromotionComponent,
	QuickPayComponent,
	ThankYouComponent,
	EditVehiclepromotionComponent,
	EditVehiclepromotionforeditComponent,
	ViewYourBillComponent,
	PaymentErrorComponent,
	PaymentSuccessComponent,
    NewsCompComponent,
    ThankYouOutletComponent,
    ResidentialPngServiceSectionComponent,
    CngServiceSectionComponent,
    CommercialServiceSectionComponent,
    IndustrialServiceSectionComponent,
    MglCorporateServiceSectionComponent,
	IcQrPayComponent,
	AwardDetailsComponent,
	AwardsListComponent,
	SavingsKiShubhShuruaatBrochureComponent,
	MgltezComponent,
	ExpressionOfInterestComponent,
	NtdcComponent,
	NtlcComponent,
	NtzcComponent,
	NtzdComponent,
	StatusofopenrequestComponent,
	SteelpipelinenetworklayoutComponent,
	VisionComponent,
	ResetPasswordComponent,
	NametransferdetailsComponent,
	ApplyfornameEditComponent,
	EtendersapsrmComponent,
	CommercialtariffcardComponent,
    IndustrialtariffcardComponent,
    OpenplotnearusarComponent,
    WithoutloginlogmeterreadingComponent,
    CsrprojectComponent,
    NewslettersComponent,
    NewslettersdetailsComponent,
    NachpaymentsuccesssComponent,
    NachpaymenterrorComponent,
    UpiautopayComponent,
    RetrofittersdetailsComponent,
    RetrofitmentComponent,
    NewCNGVehicleComponent,
    CngmahotsavmarutisuzukiComponent,
    RetrofitmentFAQsComponent,
    NewvehicleFAQComponent,
    CngmahotsavmarutisuzukiappstatusComponent,
    CngmahotsavmarutisuzukiappdetailsComponent,
    MglcngmahotsavComponent,
    CngmahotsavmarutisuzukiapprovalComponent,
    QuarterlycorporategovernanceComponent,
    MarutisuzukithankyouComponent,
    DownloadapplinkComponent,
    OemsnewvehiclesComponent,
    OemsnewvehiclesdetailsComponent,
    OemsnewvehiclesapprovalComponent,
    OemsnewvehiclesstatusComponent,
    CngmahotsavmarutisuzukiapplicationeditComponent,
    OemsnewvehicleseditComponent,
    RetrofittersloginComponent,
    RetrofittersappstatusComponent,
    RetrofittersappdetailsComponent,
    RetrofittersappeditComponent,
    DomesticpngconngasifiedbulidnewComponent,
    ConnectionApplicationStatusnewComponent,
    PaymentSuccessTestComponent,
    PaymentErrorTestComponent,
    BillingtestComponent,
    GenerateyourbillComponent,
    NationalpngdriveComponent,
    MglcorporatebroucherComponent,
    LoginwithotpComponent,
    Mr1Component,
    EbillzprojectComponent,
    MeterreadingprojectComponent,
    NexgenprojectComponent,
    ApppayupaymentsuccessComponent,
    ApppayupaymenterrorComponent,
    PayuwalletComponent,
    PayuwalletbalanceComponent,
    PayuwalletthankyouComponent,
    PayupaymentsuccessComponent,
    PayupaymenterrorComponent,
    PayUpaymenterrorComponent,
    PayUpaymentsuccessComponent,
    PayUunloadpaymenterrorComponent,
    PayUunloadpaymentsuccessComponent,
    ViewpayutransactionComponent,
    ApplyfornametestComponent,
    ApplyfornametsteponeComponent,
    ApplyfornametsteptwoComponent,
    ApplyfornametstepthreeComponent,
    ApplyfornametstepfourComponent,
    NametransferthankyouComponent,
    PledgeComponent,
    PledgethankyouComponent,
    ApplyfornametsteponeeditComponent,
    ApplyfornametsteptwoeditComponent,
    ApplyfornametstepthreeeditComponent,
    RetrofittersleveloneComponent,
    RetrofittersleveltwoComponent,
    RetrofitterslevelonestatusComponent,
    RetrofitterslevelonedetailsComponent,
    RetrofittersleveltwostatusComponent,
    RetrofittersleveltwodetailsComponent,
    RetrofittersleveltwoeditComponent,
    RetrofittersleveloneeditComponent,
    ApppaymentSuccessComponent,
    ApppaymentErrorComponent,
    DealerzonefaqsComponent,
    OpenplotnearjitegaonComponent,
    ListofdocumentrequiredComponent,
    ProcessofloiissuanceComponent,
    Oemsnewvehicles20Component,
    Oemsnewvehicles20detailsComponent,
    Oemsnewvehicles20editComponent,
    Oemsnewvehicles20statusComponent,
    Oemsnewvehicles20approveComponent,
    DomesticPngConnGasifiedBulidInstitutionComponent,
    PaymentInstitutionSuccessComponent,
    PaymentInstitutionErrorComponent,
    ConnectionApplicationInstitutionStatusComponent,
    Mglcngmaostav20dashboardComponent,
    Mglcngmaostav20oemdashboardComponent,
    Oemsnewvehicles20leveltwostatusComponent,
    Oemsnewvehicles20leveltwodetailsComponent,
    Oemsnewvehicles20leveltwoeditComponent,
    Retrofitters20leveloneComponent,
    Retrofitters20leveltwoComponent,
    Retrofitters20levelonestatusComponent,
    Retrofitters20levelonedetailsComponent,
    Retrofitters20leveltwostatusComponent,
    Retrofitters20leveltwodetailsComponent,
    Retrofitters20leveltwoeditComponent,
    Retrofitters20leveloneditComponent,
    Marutisuzuki20thankyouComponent,
    ApplyforcngouletnewComponent,
    ApplyoutletstatusComponent,
    SubsidiaryFinancialsComponent,
    UnisonEnviroPrivateLimitedComponent,
    MahanagarLNGPrivateLimitedComponent,
    ApplyoutletthankyouComponent,
    FacebookPostComponent,
    InstaPostComponent,
    CommercialfaqsComponent,
    IndustrialfaqsComponent,
    OemloginComponent,
    ApplyforcngouletmComponent,
    ApplyforcngouletnewmComponent,
    ApplyoutletthankyoumComponent,
    ThankYouOutletmComponent,
    ApplyoutletstatusmComponent,
    Oem20faqsComponent,
    ListofmglcngstationsComponent
  ],
  imports: [
    BrowserModule,
    CarouselModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
	GoogleMapsModule,
    NgxPrintModule,
    AutocompleteLibModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })

  ],
  providers: [NgbDropdown, DatePipe, DisableRightClickService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}