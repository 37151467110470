<div id="rs-services" class="rs-services style6 bn-service">
    <div class="marketstats">
      <img src="assets/images/market-stat-bg.png" class="mk-bg">
      <div class="marketstats-content">
         <ul>
          <li><!--<i class="demo-icon uil-calling"></i>-->
            <!-- <p>Emergency No/ Dial before Dig </p>
             <h6 > 1800 266 9944 / 6867 4500</h6> 
             <h6> 1800 266 9944 / 6875 9400</h6>-->
             <h6><span>Emergency no.</span>1800 266 9944 / 6875 9400</h6>
             <h6><span>Dial before Dig no.</span> 1800 2100 2100</h6>
          </li>
          <li><div class="mid-social">
          <a href=" https://www.facebook.com/mglltdofficial" target="_blank"><i class="fa fa-facebook"></i></a>
          <a href="https://twitter.com/mahanagargas" target="_blank"><i class="fa fa-twitter"></i></a>
          <a href="https://youtu.be/07Jxsttz6X8" target="_blank"><i class="fa fa-youtube-play"></i></a>
          <a href="https://instagram.com/mahanagargas?igshid=YmMyMTA2M2Y=" target="_blank"><i class="fa fa-instagram"></i></a>
          <a href="https://in.linkedin.com/company/mglltd" target="_blank"><i class="fa fa-linkedin"></i></a>
          </div></li> 
          <!--<li><p>MGL-NSE</p>
              <h6 ><i class="demo-icon uil-chart-line"></i> ₹{{stock.CurrentPrice}} <span [ngStyle]="{'color':NseColors}"  >({{stock.Changes}}%)</span></h6>
          </li>
          <li><p>MGL-BSE</p>
              <h6><i class="demo-icon uil-chart-line"></i> ₹{{stock.BseCurrentPrice}} <span [ngStyle]="{'color':BseColors}" >{{stock.BseChange}}</span> </h6>
          </li>
           <li><p>Call Us on</p>
              <h6><i class="demo-icon uil-phone-alt"></i> 68674500 / 61564500 </h6>
          </li> -->
         </ul>

      </div>
    </div>  
  <div class="looking-for">
      <img src="assets/images/i-looking-for-bg.svg" class="looking-for-bgimg">
      <div class="looking-for-services">
          <h3>I am <span> looking for</span></h3>
          <ul>
           <li>
            <abbr>
              <img src="assets/images/looking-for/commercial/procedure-for-new-commercial-connection.png">
              <a [routerLink]="['/industrial-and-commercial/Commercial-png/apply-for-commercial-connection/how-to-apply']">
                <h6>Procedure for New<span>Connection</span></h6>
              </a>
            </abbr>
            </li>   

           <li>
            <abbr>
              <img src="assets/images/looking-for/commercial/documents-required.png">
              <a [routerLink]="['/industrial-and-commercial/commercial-png/apply-for-commercial-connection/document']">
                <h6>Documents<span>Required</span></h6>
              </a>
            </abbr>
            </li> 
            <li>
                <abbr>
                  <img src="assets/images/looking-for/commercial/tariff-card.png">
                  <a [routerLink]="['/industrial-and-commercial/commercial-png/commercial-tariff-card']"> 
                    <h6>Tariff<span>Card</span></h6>
                  </a>
                </abbr>
                </li> 

                <li>
                    <abbr>
                      <img src="assets/images/looking-for/commercial/view-your-bill.png">
                      <a [routerLink]="['/industrial-and-commercial/ic-bill']">
                        <h6>View<span>your Bill</span></h6>
                      </a>
                    </abbr>
                    </li> 
                    <li>
                        <abbr>
                          <img src="assets/images/looking-for/commercial/payment-of-commercial-bill.png">
                          <a [routerLink]="['/industrial-and-commercial/commercial-png/png-payment']">
                            <h6>Payment of <span>Commercial Bill</span></h6>
                          </a>
                        </abbr>
                        </li> 
                        <li>
                            <abbr>
                              <img src="assets/images/looking-for/commercial/contact-us.png">
                              <a [routerLink]="['/industrial-and-commercial/contact-us']">
                                <h6>Contact<span>US</span></h6>
                              </a>
                            </abbr>
                            </li> 
                            <li>
                <abbr>
                  <img src="assets/images/looking-for/residential-png/QR-pay.png">
                  <a [routerLink]="['/industrial-and-commercial/qr-pay']" >
                    <h6>QR <span>Pay</span></h6>
                  </a>
                </abbr>
                  </li> 

        
          </ul>
      </div>
  </div>
</div>