<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <!-- <h1>Application for CNG Retail Outlets
        </h1> -->
        <h1>इरादा पत्र जारी करण्याची प्रक्रिया (LOI)
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">मुखप्रुष्ट</a></li>
                <li class=""><a >सीएनजी</a></li>
                <li class=""><a >डीलर झोन</a></li>
                <li class="active" aria-current="page"><a href="#">इरादा पत्र जारी करण्याची प्रक्रिया (LOI)</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <!-- <app-recent-post></app-recent-post>

                <app-tips></app-tips> -->

               <!--  <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
               <!--  <div class="light-blue-bg">
                    <div class="row"> -->
                        
                        <div class="customer-login-page log-meter-reading">

                            <form [formGroup]="applyoutletForm">
                                 <div class="light-blue-bg mt-10">
                                            <p><b>अर्जदाराचे तपशील :</b></p>
                            	
                                <div class="row">
                                	 <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="typeofapplicant"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['typeofapplicant'].errors}" (change)="getApplicanttype()">
                                                <option value="" selected>अर्जदाराचा प्रकार निवडा </option>
                                                <option value="वैयक्तिक">वैयक्तिक</option>
                                                <option value="नोंदणीकृत संस्था">नोंदणीकृत संस्था</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['typeofapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['typeofapplicant'].errors">आवश्यक फील्ड</div>
                                            </div>
                                            <label for="typeofapplicant">अर्जदाराचा प्रकार<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group" *ngIf="applicantIndividualFlag==true">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="numberofapplicant"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['numberofapplicant'].errors}" (change)="getApplicantNumber()">
                                                <option value="" selected>अर्जदारांची संख्या निवडा</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['numberofapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['numberofapplicant'].errors">आवश्यक फील्ड</div>
                                            </div>
                                            <label for="numberofapplicant">अर्जदारांची संख्या निवडा<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    
                                       <div class="col-sm-6 form-group" *ngIf="firstFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="firstapplicant"
                                                placeholder="अर्जदाराचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['firstapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['firstapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['firstapplicant'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">अर्जदाराचे नाव<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="secondFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="secondapplicant"
                                                placeholder="अर्जदाराचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['secondapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['secondapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['secondapplicant'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">अर्जदाराचे नाव<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="thirdFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="thirdapplicant"
                                                placeholder="अर्जदाराचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['thirdapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['thirdapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['thirdapplicant'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">अर्जदाराचे नाव<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="fourthFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="fourthapplicant"
                                                placeholder="अर्जदाराचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['fourthapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['fourthapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['fourthapplicant'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">अर्जदाराचे नाव<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="fiveFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="fifthapplicant"
                                                placeholder="अर्जदाराचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['fifthapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['fifthapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['fifthapplicant'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">अर्जदाराचे नाव<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="sixFalg==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="sixapplicant"
                                                placeholder="अर्जदाराचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['sixapplicant'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['sixapplicant'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['sixapplicant'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">अर्जदाराचे नाव<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                     <div class="col-sm-6 form-group" *ngIf="applicantFirmFlag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="nameoffirm"
                                                placeholder="संस्थेचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['nameoffirm'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['nameoffirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['nameoffirm'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">संस्थेचे नाव<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group" *ngIf="applicantFirmFlag==true">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="typeoffirm"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['typeoffirm'].errors}" (change)="getfirmtype()">
                                                <option value="" selected>संस्थेचा प्रकार निवडा </option>
                                                <option value="व्यक्तिगत मालकी">व्यक्तिगत मालकी</option>
                                                <option value="भागीदारी">भागीदारी</option>
                                                <option value="प्रायव्हेट लिमिटेड">प्रायव्हेट लिमिटेड</option>
                                                <option value="इतर">इतर</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['typeoffirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['typeoffirm'].errors">आवश्यक फील्ड</div>
                                            </div>
                                            <label for="typeoffirm">संस्थेचा प्रकार निवडा<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                     <div class="col-sm-6 form-group" *ngIf="othertypeFlag==true">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="otherfirm"
                                                placeholder="संस्थेचा इतर प्रकार"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['otherfirm'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['otherfirm'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['otherfirm'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">संस्थेचा इतर प्रकार<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="light-blue-bg mt-10">
                                            <p><b>संभाषणासाठीचे तपशील :</b></p>

                                    <div class="row">

                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Name"
                                                placeholder="संपर्क व्यक्तीचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Name'].errors }"
                                                id="Name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Name'].errors.required">आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="Name">संपर्क व्यक्तीचे नाव<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input readonly type="text" class="form-control" formControlName="ContactNumber"
                                                placeholder="मोबाईल क्रमांक"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['ContactNumber'].errors }"
                                                id="ContactNumber" />
                                            <div *ngIf="submitted && applyoutletForm.controls['ContactNumber'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['ContactNumber'].errors.required">
                                                    आवश्यक फील्ड
                                                </div>
                                                <div *ngIf="applyoutletForm.controls['ContactNumber'].errors?.pattern">
                                                    अवैध मोबाईल क्रमांक </div>
                                            </div>
                                            <label for="ContactNumber">मोबाईल क्रमांक<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Email"
                                                placeholder="ईमेल आयडी"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Email'].errors }"
                                                id="Email" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Email'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Email'].errors.required">आवश्यक फील्ड
                                                </div>
                                                 <div *ngIf="applyoutletForm.controls['Email'].errors.pattern">
                                                    अवैध ईमेल आयडी </div>
                                            </div>
                                            <label for="Email">ईमेल आयडी</label>
                                            <!-- <label for="Email">Email<span style="color: crimson;">*</span></label> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Correspondence_Address"
                                                placeholder="पत्ता"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Correspondence_Address'].errors }"
                                                id="Correspondence_Address" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Correspondence_Address'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Correspondence_Address'].errors.required">आवश्यक फील्ड
                                                </div>
                                                 
                                            </div>
                                            <label for="Correspondence_Address">पत्ता<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     </div>
                                      </div>

                                       <div class="light-blue-bg mt-10">
                                            <p><b>देऊ केलेल्या जमिनीचे तपशील :</b></p>

                                      <div class="row">


                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofPlotOwner"
                                                placeholder="प्लॉट मालका(कां)चे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['NameofPlotOwner'].errors }"
                                                id="NameofPlotOwner" />
                                            <div *ngIf="submitted && applyoutletForm.controls['NameofPlotOwner'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['NameofPlotOwner'].errors.required">
                                                    आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="NameofPlotOwner">प्लॉट मालका(कां)चे नाव<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="lease_tenure_years"
                                                placeholder="भाडेपट्टा मुदत"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['lease_tenure_years'].errors }"
                                                id="lease_tenure_years" />
                                            <div *ngIf="submitted && applyoutletForm.controls['lease_tenure_years'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['lease_tenure_years'].errors.required">
                                                    आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="lease_tenure_years">भाडेपट्टा मुदत<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                  <!--   <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control"
                                                formControlName="RelationshipofAppOwner"
                                                placeholder="RelationshipofAppOwner"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['RelationshipofAppOwner'].errors }"
                                                id="RelationshipofAppOwner" />
                                            <div *ngIf="submitted && applyoutletForm.controls['RelationshipofAppOwner'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['RelationshipofAppOwner'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="RelationshipofAppOwner">Relationship of Applicant Owner<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Addressofplot"
                                                placeholder="Addressofplot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Addressofplot'].errors }"
                                                id="Addressofplot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Addressofplot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Addressofplot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Addressofplot">Address of plot</label>
                                            
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="SurveyNo"
                                                placeholder="SurveyNo"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['SurveyNo'].errors }"
                                                id="SurveyNo" />
                                            <div *ngIf="submitted && applyoutletForm.controls['SurveyNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['SurveyNo'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="SurveyNo">Survey No/ CTS No.<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="District"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['District'].errors}">
                                                <option value="" selected>Select District </option>
                                                <option *ngFor="let DistrictItem of district "
                                                    value={{DistrictItem.District}}>{{DistrictItem.District}}</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['District'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['District'].errors">Required Field</div>
                                            </div>
                                            <label for="District">District<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="PinCode"
                                                placeholder="PinCode"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['PinCode'].errors }"
                                                id="PinCode" />
                                            <div *ngIf="submitted && applyoutletForm.controls['PinCode'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['PinCode'].errors.required">
                                                    Required Field
                                                </div>
                                                <div *ngIf="applyoutletForm.controls['PinCode'].errors?.pattern">invalid
                                                    pincode
                                                </div>
                                            </div>
                                            <label for="PinCode">PinCode<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="AreaofthePlot"
                                                placeholder="AreaofthePlot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['AreaofthePlot'].errors }"
                                                id="AreaofthePlot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['AreaofthePlot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['AreaofthePlot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="AreaofthePlot">Area of plot for CNG (in Sq. M.)<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                         <label class="font-size11 mt-1">If the plot is on NH/SH Min. 35m Frontage and 1225 sq.m. area required</label>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Frontageoftheplot"
                                                placeholder="Frontageoftheplot"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Frontageoftheplot'].errors }"
                                                id="Frontageoftheplot" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Frontageoftheplot'].errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="applyoutletForm.controls['Frontageoftheplot'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="Frontageoftheplot">Frontage of the plot<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Depth"
                                                placeholder="Depth"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Depth'].errors }"
                                                id="Depth" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Depth'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Depth'].errors.required">Required
                                                    Field
                                                </div>
                                            </div>
                                            <label for="Depth">Depth<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                   <!--  <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Latitude"
                                                placeholder="Latitude"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Latitude'].errors }"
                                                id="Latitude" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Latitude'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Latitude'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            
                                            <label for="Latitude">Latitude</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Longitude"
                                                placeholder="Longitude"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Longitude'].errors }"
                                                id="Longitude" />
                                            <div *ngIf="submitted && applyoutletForm.controls['Longitude'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Longitude'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                           
                                                    <label for="Longitude">Longitude</label>
                                        </div>
                                    </div> -->
                                  <!--   <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="Ownership_of_Land"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Ownership_of_Land'].errors}">
                                                <option value="" selected>Select Ownership of Land </option>
                                                <option value="Owned">Owned</option>
                                                <option value="Leased">Leased</option>
                                                <option value="Will be Leased/Purchased">Will be Leased/Purchased</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['Ownership_of_Land'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Ownership_of_Land'].errors">Required Field</div>
                                            </div>
                                            <label for="Ownership_of_Land">Ownership of Land<span style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="NameofRoad"
                                                placeholder="NameofRoad"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['NameofRoad'].errors }"
                                                id="NameofRoad" />
                                            <div *ngIf="submitted && applyoutletForm.controls['NameofRoad'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['NameofRoad'].errors.required">
                                                    Required Field
                                                </div>
                                            </div>
                                            <label for="NameofRoad">Name of Road<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <select class="form-select" formControlName="Plot"
                                                [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['Plot'].errors}">
                                                <option value="" selected>Select Plot</option>
                                                <option *ngFor="let PlotItem of plot " value={{PlotItem.Plot}}>
                                                    {{PlotItem.Plot}}</option>
                                            </select>
                                            <div *ngIf="submitted && applyoutletForm.controls['Plot'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['Plot'].errors"> Required Field
                                                </div>
                                            </div>
                                            <label for="Plot">Plot<span style="color: crimson;">*</span></label>
                                        </div>

                                    </div> -->
                                    <!-- <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="Additionalremarks"
                                                placeholder="Additionalremarks" id="Additionalremarks" />
                                            <label for="Plot">Additional remarks</label>
                                        </div>

                                    </div> -->

                                </div>
                            </div>

                            <div class="light-blue-bg mt-10">
                                            <p><b>वचन :</b></p>

                                            <div class="mb-25 apply-for-cng-outlet">
                                  
                                        <ol class="number-list">
                                        <li> मी / आम्ही हे समजतो की तपशीलवार तांत्रिक-व्यावसायिक सर्वेक्षण केल्यानंतर, एमजीएल त्याच्या एकल निर्णयाधीन राहून सीएनजी रीटेल आऊटलेट उभारण्यासाठी अनुरूप प्लॉट्स निवडू शकते.</li>
                                        <li> मी / आम्ही हे समजतो की एकदा आशयपत्र (एलओआय) प्रसृत झाले की त्यानंतर मला / आम्हाला एमजीएलच्या विद्यमान धोरणानुसार विविध करारनाम्यांत सहभागी व्हावे लागेल.</li>
                                        <li>मी / आम्ही हे समजतो की त्याच पट्ट्यावर इतर अर्जदार असू शकतात आणि एमजीएल अर्जाच्या गुणवत्तेच्या आधारावर एलओआय देईल.</li>
                                         <li>मी / आम्ही एमजीएलकडून मागणी होईल तेव्हा आणि त्यानुसार आधारदायी कागदपत्रे सादर करण्याचे वचन देतो, जे न केल्यास माझा अर्ज अपात्र ठरेल.</li>
                                          <li>प्रशासकीय शुल्कांसाठी महानगर गॅस लिमिटेडच्या नावाने ------- रोजी दिनांकित रु. ५,९०,०००/- (पाच लाख नव्वद हजार फक्त) चा डीमांड ड्राफ्ट / पे ऑर्डर क्र. ------- ज्यामध्ये जीएसटी समाविष्ट आहे, --------------------------- (बँकेचे नाव) च्या नावे काढला असून सोबत जोडला आहे. मी पूर्णपणे समजतो की एमजीएल कडून स्वारस्यपत्र (एलओआय) निर्गमित झाल्यावर ही रक्कम न-परतावायोग्य आहे.</li>
                                          <li>या अर्ज प्रपत्रात विचारलेली माहिती फक्त एमजीएल सीएनजी रीटेल आऊटलेट उभारण्यासाठी अर्जाचे मूल्यांकन करण्यासाठीची तोंडदेखली माहिती गोळा करण्यासाठी आहे. हे प्रपत्र सुपूर्द केले म्हणजे सीएनजी रीटेल आऊटलेटचा एलओआय मिळेल याची हमी मिळत नाही.</li>
                                           <li>मी / आम्ही समजतो की सर्व प्रकारचे अपूर्ण प्रपत्र नाकारले जाऊशकतील.</li>
                                          <!--  <li>I/We, the undersigned hereby, certify that the information given above is true to the best of my/our knowledge and belief. Any wrong information / suppression of facts will disqualify me from being considered for issuance of LOI for CNG Retail Outlet.</li> -->
                                           <li>
                                            <div class="row service-label">
                                <div class="col-md-12">
                                    <div class="form-check">
                                        <input formControlName="accept_term" type="checkbox" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['accept_term'].errors }">
                                        <div *ngIf="submitted && applyoutletForm.controls['accept_term'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['accept_term'].errors">कृपया चेकबॉक्सवर टिक करा</div>
                                            </div>
                                        <label>
                                            <a  class="terms-label">मी / आम्ही, निम्नस्वाक्षरीकर्ते याद्वारे, प्रमाणित करतो की वर दिलेली माहिती माझ्या / आमच्या माहितीनुसार आणि विश्वासानुसार खरी आहे. कोणतीही चुकीची माहिती दिल्याने / तथ्ये लपवल्याने मला सीएनजी रीटेल आऊटलेटचा एलओआय देण्यासाठी विचारात घेतल्या जाण्यापासून अपात्र ठरवले जाईल.</a></label>
                                           
                                    </div>

                                </div>
                            </div>
                        </li>
                                        
                                    </ol>
                                </div>

                                 <div class="row">
                                     
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="orderNo"
                                                placeholder="Demand Draft / Pay Order No."
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['orderNo'].errors }"
                                                id="orderNo" />
                                            <div *ngIf="submitted && applyoutletForm.controls['orderNo'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['orderNo'].errors.required">
                                                    आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="orderNo">डिमांड ड्राफ्ट / पे ऑर्डर क्र.<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="date" class="form-control" formControlName="orderNodate"
                                                placeholder="डिमांड ड्राफ्टची तारीख"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['orderNodate'].errors }"
                                                id="orderNodate" />
                                            <div *ngIf="submitted && applyoutletForm.controls['orderNodate'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['orderNodate'].errors.required">
                                                    आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="orderNodate">डिमांड ड्राफ्ट / पे ऑर्डरची तारीख<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                     <div class="col-sm-6 form-group">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" formControlName="bank_name"
                                                placeholder="बँकेचे नाव"
                                                [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['bank_name'].errors }"
                                                id="bank_name" />
                                            <div *ngIf="submitted && applyoutletForm.controls['bank_name'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['bank_name'].errors.required">
                                                    आवश्यक फील्ड
                                                </div>
                                            </div>
                                            <label for="bank_name">बँकेचे नाव<span
                                                    style="color: crimson;">*</span></label>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="light-blue-bg mt-10">
                                            <p><b>अर्ज प्रपत्रासोबत जोडलेली कागदपत्रे :</b></p>

                                    <div class="row">
                                    <p><b><i> तुम्ही फक्त .doc,.docx,.pdf स्वरूपाची फाइल आणि 5 MB पेक्षा कमी साइझ असणारी फाइलच अपलोड करू शकता</i></b></p>
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size111">जमिनीच्या मालकाकडून वचननामा / नोंदणीकृत भाडेपट्ट्याचा करारनामा<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'Undertaking')"
                                                    class="from-control" type="file"
                                                    formControlName="under_taking_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['under_taking_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['under_taking_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['under_taking_doc'].errors">
                                                    आवश्यक फील्ड</div>
                                            </div>
                                        </div>
                                       <!--  <label class="font-size11 mt-1" for="under_taking_doc">Undertaking from Landowner/ Registered Lease Agreement<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                         <label class="font-size111">वास्तुविशारदाचा आराखडा<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'architect')"
                                                    class="from-control" type="file"
                                                    formControlName="architect_layout_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['architect_layout_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['architect_layout_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['architect_layout_doc'].errors">
                                                    आवश्यक फील्ड</div>
                                            </div>
                                        </div>
                                        <!-- <label class="font-size11 mt-1" for="architect_layout_doc">Architect Layout<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                        <label class="font-size111">अर्जदारा(रां)चा ओळखीचा पुरावा (आधार कार्ड / पॅन कार्ड / कोणताही शासकीय आयडी; कोणताही एक)<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'idproof')"
                                                    class="from-control" type="file"
                                                    formControlName="id_proof_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['id_proof_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['id_proof_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['id_proof_doc'].errors">
                                                    आवश्यक फील्ड</div>
                                            </div>
                                        </div>
                                      <!--   <label class="font-size11 mt-1" for="id_proof_doc">ID proof of Applicant(s) (Aadhaar Card / PAN Card / Any Govt. ID; any one)<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 
                                    <div class="col-sm-6 form-group">
                                         <label class="font-size111">डिमांड ड्राफ्ट<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'demanddraft')"
                                                    class="from-control" type="file"
                                                    formControlName="demand_draft_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['demand_draft_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['demand_draft_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['demand_draft_doc'].errors">
                                                    आवश्यक फील्ड</div>
                                            </div>
                                        </div>
                                       <!--  <label class="font-size11 mt-1" for="demand_draft_doc">Demand Draft<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 
                                    <div class="col-sm-6 form-group" *ngIf="applicantFirmFlag==true">
                                         <label class="font-size111">कृपया कोणतेही एक सहाय्यक दस्तऐवज संलग्न करा - निगमन प्रमाणपत्र किंवा GST प्रमाणपत्र किंवा पॅन कार्ड किंवा भागीदारी करार इ.<span
                                                style="color: crimson;">*</span></label>
                                        <div class="form-floating ">
                                                <input  accept="pdf,msword,document"
                                                    (change)="fileCheckForSlip($event,'incorporation')"
                                                    class="from-control" type="file"
                                                    formControlName="incorporation_doc"
                                                    [ngClass]="{'is-invalid':submitted && applyoutletForm.controls['incorporation_doc'].errors}">
                                            <div *ngIf="submitted && applyoutletForm.controls['incorporation_doc'].errors"
                                                class="invalid-feedback">
                                                <div *ngIf="applyoutletForm.controls['incorporation_doc'].errors">
                                                    आवश्यक फील्ड</div>
                                            </div>
                                        </div>
                                       <!--  <label class="font-size11 mt-1" for="incorporation_doc">Please attach any one supporting documents - Incorporation Certificate or GST Certificate or PAN Card or Partnership Deed etc<span
                                                style="color: crimson;">*</span></label> -->
                                    </div> 


                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-5">
                                                <div class="mt-10">
                                                    <div class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-7 col-md-7 col-sm-7 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && applyoutletForm.controls['Captcha'].errors }"
                                                        id="Captcha" />

                                                    <div *ngIf="submitted && applyoutletForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="applyoutletForm.controls['Captcha'].errors.required">
                                                            आवश्यक फील्ड
                                                        </div>
                                                    </div>
                                                    <label for="Captcha">कॅप्चा<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="onSubmit()" class="greenbtn2">सबमिट करा <i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <div class="text-right md-text-left">
                                            <button (click)="onSubmit1()" class="greenbtn2">मसुदा म्हणून जतन करा<i
                                                    class="demo-icon uil-redo"></i></button>
                                        </div>
                                    </div>

                                    <div *ngIf="alertType == 'submit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>

                                </div>
                                <div class="text-right mt-30">
                                    <p class="mb-0"><b><i>कृपया लक्षात ठेवा की सर्व चिन्हांकित फील्ड <span
                                                    class="text-danger">*</span> अनिवार्य आहेत.</i></b></p>
                                </div>
                                 </div>
                            </form>
                        </div>
                   <!--  </div>
                </div> -->
            </div>
        </div>
    </div>
</div>