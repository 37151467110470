<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>MSDS OF NATURAL GAS</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Safety, Health And Environment</a></li>
                <li class="active" aria-current="page"><a href="#">MSDS OF NATURAL GAS</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>
                <app-insta-post></app-insta-post>

               <!--  <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <div class="rs-faq applications-natural-gas">
                        <div class="faq-content">
                            <div id="accordion" class="accordion">
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwentytwo" data-toggle="collapse"
                                            data-bs-target="#collapsetwentytwo" aria-expanded="false">Identification:
                                        </a>
                                    </div>
                                    <div id="collapsetwentytwo" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <thead _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <th _ngcontent-udx-c139="">Chemical Name</th>
                                                            <th _ngcontent-udx-c139="" colspan="3">NATURAL GAS </th>
                                                    </thead>
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Synonyms</td>
                                                            <td _ngcontent-udx-c139="">NATURAL GAS, Piped Natural Gas,
                                                                Compressed Natural Gas</td>
                                                            <td _ngcontent-udx-c139="">Trade Name</td>
                                                            <td _ngcontent-udx-c139="">Domestic/ Industrial/ Commercial
                                                            </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Formula</td>
                                                            <td _ngcontent-udx-c139="">CH4</td>
                                                            <td _ngcontent-udx-c139="">Product Identifier</td>
                                                            <td _ngcontent-udx-c139="">CAS No: 8006-14-2 <br>UN. No:
                                                                1971</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwentythree"
                                            data-toggle="collapse" data-bs-target="#collapsetwentythree"
                                            aria-expanded="false">Use of Substance or Mixture:
                                        </a>
                                    </div>
                                    <div id="collapsetwentythree" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c96="" class="col-lg-6 col-md-6 col-sm-12">
                                                <div _ngcontent-udx-c96="" class="blue-tick-list">
                                                    <ul _ngcontent-udx-c96="">
                                                        <li _ngcontent-udx-c96="">Methane (CH4) - 88 – 98 % v/v </li>
                                                        <li _ngcontent-udx-c96="">Ethane (C2H6) - 2 – 8 % v/v</li>
                                                        <li _ngcontent-udx-c96="">Other Higher Hydrocarbons - < 2 %
                                                                v/v</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwenty" data-toggle="collapse"
                                            data-bs-target="#collapsetwenty"
                                            aria-expanded="false">Composition/Information of Ingredients:
                                        </a>
                                    </div>
                                    <div id="collapsetwenty" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <thead _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <th _ngcontent-udx-c139="">Hazardous Component (Specific
                                                                Chemical Identity, Common Names</th>
                                                            <th _ngcontent-udx-c139="">Product Identifier </th>
                                                            <th _ngcontent-udx-c139="">% (Optional)</th>
                                                    </thead>
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Methane - (CH4)</td>
                                                            <td _ngcontent-udx-c139="">CAS # 74-82-8</td>
                                                            <td _ngcontent-udx-c139="">88 – 98 % v/v</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Ethane (C2H6) </td>
                                                            <td _ngcontent-udx-c139="">CAS No. 74-84-0</td>
                                                            <td _ngcontent-udx-c139="">2 – 8 % v/v</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Other Higher Hydrocarbons</td>
                                                            <td _ngcontent-udx-c139="">--</td>
                                                            <td _ngcontent-udx-c139="">- < 2 % v/v</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwentyfive" data-toggle="collapse"
                                            data-bs-target="#collapsetwentyfive" aria-expanded="false">First Aid
                                            Measures:
                                        </a>
                                    </div>
                                    <div id="collapsetwentyfive" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <thead _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <th _ngcontent-udx-c139="" colspan="2">Description of First
                                                                Aid Measures</th>
                                                    </thead>
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">First Aid Measures after
                                                                Inhalation</td>
                                                            <td _ngcontent-udx-c139="">remove the person to fresh air
                                                                and offer artificial respiration if required</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">First Aid Measures after Skin
                                                                Contact </td>
                                                            <td _ngcontent-udx-c139="">Not known to be a skin irritant.
                                                                Skin absorption is unlikely. Good practice to wash the
                                                                skin with plenty of water and soap</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">First Aid Measures after Eye
                                                                Contact</td>
                                                            <td _ngcontent-udx-c139="">Wash the eye with cold water.
                                                                Seek medical attention if symptoms persist.</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">First Aid Measures after
                                                                Ingestion</td>
                                                            <td _ngcontent-udx-c139="">This product is a gas. Under
                                                                normal atmospheric conditions, ingestion is unlikely.
                                                            </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Symptoms or Injuries (Acute and
                                                                Delayed)</td>
                                                            <td _ngcontent-udx-c139="">Dizziness & Eye irritation,
                                                                Ashyxition at high concentration</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsetwentysix" data-toggle="collapse"
                                            data-bs-target="#collapsetwentysix" aria-expanded="false">Fire Fighting
                                            Measures :

                                        </a>
                                    </div>
                                    <div id="collapsetwentysix" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <thead _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                    </thead>
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Suitable Extinguishing Media</td>
                                                            <td _ngcontent-udx-c139="">CO2, DCP Type Fire Extinguisher &
                                                                Water Spray. Water may be ineffective on flames but
                                                                useful for other purposes; including cooling heated
                                                                surfaces or preventing the creation of static
                                                                electricity.</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td>Special Hazards arising from Substance or Mixture:</td>
                                                            <td>Gas is highly flammable. A hazard from re-ignition and
                                                                explosion exists if the flame is extinguished without
                                                                stopping flow of gas and/or cooling surroundings and
                                                                eliminating ignition source. Gas may travel a
                                                                considerable distance to a source of ignition and flash
                                                                back.
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Fire Hazard</td>
                                                            <td _ngcontent-udx-c139="">Yes</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Explosion Hazard</td>
                                                            <td _ngcontent-udx-c139="">Yes</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Reactivity</td>
                                                            <td _ngcontent-udx-c139="">With strong oxidizing agents
                                                            </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Fire Fighting Instructions</td>
                                                            <td _ngcontent-udx-c139="">Keep spraying water to cool. Do
                                                                not enter any enclosed or confined space without proper
                                                                protective equipment, including, but not limited to,
                                                                self-contained breathing apparatus. Use approved gas
                                                                detectors in confined spaces.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse13" data-toggle="collapse"
                                            data-bs-target="#collapse13" aria-expanded="false">Accidental Release
                                            Measures
                                        </a>
                                    </div>
                                    <div id="collapse13" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Personal Precautions, Personal
                                                                Protective Equipment and Emergency Procedures:<br>
                                                                Keep away from flames and chemical oxidants. Eliminate
                                                                all sources of ignition and in emergency ensure gas
                                                                supply is shut off. Allow proper ventilation. Use
                                                                non-sparking tools and explosion-proof electrical
                                                                equipment when working around gas. Ventilate area and
                                                                allow to evaporate. Stay upwind and away from any
                                                                accidental releases. Isolate immediate hazardous area
                                                                and keep unauthorized personnel out. Appropriate
                                                                protective equipment should be worn as conditions
                                                                warrant. Before entering confined areas check the
                                                                atmosphere for oxygen content and flammability.
                                                            </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Environment Precautions (Method
                                                                of Containment and Clean up): Report the gas leak /
                                                                escape as required by local regulations</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse14" data-toggle="collapse"
                                            data-bs-target="#collapse14" aria-expanded="false">Handling and Storage
                                        </a>
                                    </div>
                                    <div id="collapse14" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Precautions for Safe
                                                                Handling:<br>
                                                                Work involving gas should be done by qualified
                                                                professionals. Use non-sparking tools and
                                                                explosion-proof electrical equipment. Before entering
                                                                confined areas, check the atmosphere for oxygen content
                                                                and flammability. Purging of gas lines, blow-downs and
                                                                other planned releases of natural gas should only be
                                                                performed by qualified gas professionals. Such gas
                                                                release operations should only be performed in
                                                                well-ventilated areas or by safely venting the contents
                                                                of gas lines and equipment to the outside atmosphere
                                                                away from people, animals, structures and sources of
                                                                ignition. All possible ignition sources should be
                                                                extinguished before and during such operations. Do not
                                                                release the contents of a gas line into a confined
                                                                space.

                                                            </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Conditions for Safe Storage
                                                                including incompatibilities:<br>
                                                                Store in cool, dry, well-ventilated areas, away from
                                                                sources of heat, ignition and direct sunlight. Isolate
                                                                from oxidizers such as oxygen, chlorine, or fluorine.
                                                                Use a check valve or trap in the discharge line to
                                                                prevent hazardous backflow. Post “No Smoking or Open
                                                                Flame” signs in storage and use areas.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse15" data-toggle="collapse"
                                            data-bs-target="#collapse15" aria-expanded="false">Exposure
                                            Controls/Personal Protections
                                        </a>
                                    </div>
                                    <div id="collapse15" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <thead _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <th _ngcontent-udx-c139="" colspan="3">Control Parameters
                                                            </th>

                                                    </thead>
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">ACGIH TLV-TWA (ppm)</td>
                                                            <td _ngcontent-udx-c139="">OHSA PEL TWA (mg/m3)</td>
                                                            <td _ngcontent-udx-c139="">OHSA PEL TWA (ppm)</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">1000 ppm </td>
                                                            <td _ngcontent-udx-c139="">--</td>
                                                            <td _ngcontent-udx-c139="">--</td>
                                                        </tr>

                                                    </tbody>
                                                </table>

                                                <div _ngcontent-udx-c139="" class="table-responsive">
                                                    <table _ngcontent-udx-c139="" class="table table-bordered">
                                                        <thead _ngcontent-udx-c139="">
                                                            <tr _ngcontent-udx-c139="">
                                                                <th _ngcontent-udx-c139="" colspan="2">Exposure Control
                                                                </th>

                                                        </thead>
                                                        <tbody _ngcontent-udx-c139="">
                                                            <tr _ngcontent-udx-c139="">
                                                                <td _ngcontent-udx-c139="">Engineering Controls:</td>
                                                                <td _ngcontent-udx-c139="">Use of Isolation valves. Use
                                                                    of non-sparking tools.</td>
                                                            </tr>
                                                            <tr _ngcontent-udx-c139="">
                                                                <td _ngcontent-udx-c139="">Hand Protection:</td>
                                                                <td _ngcontent-udx-c139="">Hand gloves are recommended
                                                                    for general usage. Wear flame retardant clothing in
                                                                    potentially flammable areas</td>
                                                            </tr>
                                                            <tr _ngcontent-udx-c139="">
                                                                <td _ngcontent-udx-c139="">Eye Protection:</td>
                                                                <td _ngcontent-udx-c139="">Wear safety glasses when
                                                                    handling or exposure to high pressure gas</td>

                                                            </tr>
                                                            <tr _ngcontent-udx-c139="">
                                                                <td _ngcontent-udx-c139="">Skin and Body Protection:
                                                                </td>
                                                                <td _ngcontent-udx-c139="">Wear protective clothing if
                                                                    needed to avoid prolonged skin contact. Suitable
                                                                    body washing facilities should be available in the
                                                                    work area.</td>
                                                            </tr>
                                                            <tr _ngcontent-udx-c139="">
                                                                <td _ngcontent-udx-c139="">Respiratory Protection:</td>
                                                                <td _ngcontent-udx-c139="">If oxygen levels are unknown
                                                                    or deficient, use a Self Contained Breathing
                                                                    Apparatus </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse16" data-toggle="collapse"
                                            data-bs-target="#collapse16" aria-expanded="false">Physical/Chemical
                                            Characteristics
                                        </a>
                                    </div>
                                    <div id="collapse16" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Physical State</td>
                                                            <td _ngcontent-udx-c139="">Gas</td>
                                                            <td _ngcontent-udx-c139="">Colour</td>
                                                            <td _ngcontent-udx-c139="">Colourless</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Boiling Point </td>
                                                            <td _ngcontent-udx-c139="">- 161.5 oC</td>
                                                            <td _ngcontent-udx-c139="">Freezing Point</td>
                                                            <td _ngcontent-udx-c139="">Not Applicable</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Vapor Pressure (mm Hg) </td>
                                                            <td _ngcontent-udx-c139="">Not applicable</td>
                                                            <td _ngcontent-udx-c139="">Melting Point</td>
                                                            <td _ngcontent-udx-c139="">- 182.6 oC</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Vapor Density (Air=1) </td>
                                                            <td _ngcontent-udx-c139="">0.6</td>
                                                            <td _ngcontent-udx-c139="">Evaporation Rate</td>
                                                            <td _ngcontent-udx-c139="">Not Applicable </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Solubility in Water </td>
                                                            <td _ngcontent-udx-c139="">Soluble </td>
                                                            <td _ngcontent-udx-c139="">Appearance and Odo</td>
                                                            <td _ngcontent-udx-c139="">Odorless, Spotleak 1007 (C2H5SH)
                                                                is added for odourisation </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Auto Ignition Temperature</td>
                                                            <td _ngcontent-udx-c139="">540 oC </td>
                                                            <td _ngcontent-udx-c139="">Specific Gravity (H2O=1)</td>
                                                            <td _ngcontent-udx-c139="">0.55-0.65 </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Flammability</td>
                                                            <td _ngcontent-udx-c139="">Highly Flammable </td>
                                                            <td _ngcontent-udx-c139="">LEL: 5 % Volume of Gas in Air
                                                            </td>
                                                            <td _ngcontent-udx-c139="">UEL: 15 % Volume of Gas in Air
                                                            </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Oxidising Properties</td>
                                                            <td _ngcontent-udx-c139=""> </td>
                                                            <td _ngcontent-udx-c139="">PH</td>
                                                            <td _ngcontent-udx-c139="">Not Pertinent </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Explosion Data : Sensitivity to
                                                                Impact</td>
                                                            <td _ngcontent-udx-c139=""> May explode</td>
                                                            <td _ngcontent-udx-c139="">Explosion Data: Sensitivity to
                                                                Static Discharge</td>
                                                            <td _ngcontent-udx-c139="">May explode </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Hazardous Combustion Products
                                                            </td>
                                                            <td _ngcontent-udx-c139=""> CO2 and Traces of oxides</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse17" data-toggle="collapse"
                                            data-bs-target="#collapse17" aria-expanded="false">Stability and Reactivity
                                        </a>
                                    </div>
                                    <div id="collapse17" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Reactivity</td>
                                                            <td _ngcontent-udx-c139="">No reaction with common material
                                                                but may react with oxidising agent.</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Chemical Stability </td>
                                                            <td _ngcontent-udx-c139="">Stable</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Possibility of Hazardous
                                                                Reactions </td>
                                                            <td _ngcontent-udx-c139="">Heat will increase the pressure
                                                                of gas in cylinders and may cause an explosion </td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Incompatible Materials </td>
                                                            <td _ngcontent-udx-c139="">Strong Oxidisers</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Hazardous Decomposition Products
                                                            </td>
                                                            <td _ngcontent-udx-c139="">Combustion may produce carbon
                                                                dioxide and water with trace amounts of carbon monoxide,
                                                                nitrogen oxides, hydrocarbons, etc.</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse19" data-toggle="collapse"
                                            data-bs-target="#collapse19" aria-expanded="false">Toxicological Information
                                        </a>
                                    </div>
                                    <div id="collapse19" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Information on Toxicological
                                                                Effects</td>
                                                            <td _ngcontent-udx-c139="">Natural gas is expected to
                                                                readily evaporate and not cause adverse effects</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse20" data-toggle="collapse"
                                            data-bs-target="#collapse20" aria-expanded="false">Ecological Information
                                        </a>
                                    </div>
                                    <div id="collapse20" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Information on Ecological Effects
                                                            </td>
                                                            <td _ngcontent-udx-c139="">Natural gas is expected to
                                                                readily evaporate and not cause adverse effects</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse21" data-toggle="collapse"
                                            data-bs-target="#collapse21" aria-expanded="false">Disposal Considerations
                                        </a>
                                    </div>
                                    <div id="collapse21" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Waste Disposal Methods</td>
                                                            <td _ngcontent-udx-c139="">Allow the gas to burn under
                                                                control</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Waste Treatment Methods</td>
                                                            <td _ngcontent-udx-c139="">Allow the gas to burn under
                                                                control</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse22" data-toggle="collapse"
                                            data-bs-target="#collapse22" aria-expanded="false">Transport Information
                                        </a>
                                    </div>
                                    <div id="collapse22" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Hazard Classes</td>
                                                            <td _ngcontent-udx-c139="">704</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">NFPA Hazard Label</td>
                                                            <td _ngcontent-udx-c139="">704</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">Special Transport Precautions
                                                            </td>
                                                            <td _ngcontent-udx-c139="">--</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapse23" data-toggle="collapse"
                                            data-bs-target="#collapse23" aria-expanded="false">Regulatory Information
                                        </a>
                                    </div>
                                    <div id="collapse23" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-udx-c139="" class="table-responsive">
                                                <table _ngcontent-udx-c139="" class="table table-bordered">
                                                    <tbody _ngcontent-udx-c139="">
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">National Regulations</td>
                                                            <td _ngcontent-udx-c139="">Manufacture, Storage and Import
                                                                of Hazardous Chemicals Rules</td>
                                                        </tr>
                                                        <tr _ngcontent-udx-c139="">
                                                            <td _ngcontent-udx-c139="">International Regulations</td>
                                                            <td _ngcontent-udx-c139="">--</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>

                                <h4>Manufacturer's Name, Address & Emergency Telephone Number:</h4>
                                Supplier Name : Mahanagar Gas Limited<br>
                                Block G-33, Bandra-Kurla Complex, <br>
                                Bandra (East), Mumbai 400 051<br>
                                Emergency Telephone Number- 6875 9400/ Toll Free- 1800 266 9944 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>