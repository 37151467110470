<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Savings ki Shubh Shuruaat
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
				<li class=""><a>CNG</a></li>
                <li class="active" aria-current="page"><a>Savings ki Shubh Shuruaat</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-cng-service-section></app-cng-service-section>
<!-- <app-service-section></app-service-section> -->
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="light-blue-bg">
                    <div class="row">
                       <h6>“Saving Ki Shubh Shuruaat Scheme has ended on 31st Aug however last date to upload data to claim benefit is 31st Oct 2023”. <a class="upload-doc-a" [routerLink]="['/cng/cng-offers-schemes/savings-ki-shubh-shuruaat']"> Click here to upload Documents.</a> </h6>
                        <div class="mb-30">
                            <img src="assets/images/saving-shuruaat-brochure.jpg" class="w-100">
                        </div>
                        <div class="text-center">
                       <!--  <a [routerLink]="['/cng/cng-offers-schemes/savings-ki-shubh-shuruaat']" class="bluebtn mx-auto">Apply Now <i class="demo-icon uil-arrow-right"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





