<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Important Policies</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
				<li class=""><a >Residential PNG</a></li>
				<li class=""><a >Policies & Procedures</a></li>
                <li class="active" aria-current="page"><a href="#">Important Policies</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

               <!--  <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1"> 
                <div class="mt-40">
                    <div class="box882_left">

                        <div class="mb-20">
                            <h6>Billing Policy</h6>
							<div class="blue-tick-list">
								<ul>
									<li>Bills are raised once in 2 months.</li>
									<li>Actual meter reading is taken by MGL once in 4 months based on Billing Cycle of the
										consumer.</li>
									<li>In case of non-usage of gas by consumer, the consumer should inform MGL based on
										which Minimum Charges Bills will be raised on the consumer.</li>
									<li>All Bills raised by MGL have to be paid by the consumer on or before the due date.
									</li>
									<li>Once a consumer is disconnected, connection will only be provided at MGL’s
										discretion only after paying the reconnection charges as applicable from time to
										time.</li>
								</ul>
							</div>
							<div class="mt-30">
                            <h6>Disconnection Policy</h6>
							<div class="blue-tick-list">
								<ul>
									<li>On non-payment of 3 bills lock will be applied by appointed agency isolating the
										supply.</li>
									<li>On non-payment of 6 bills complete disconnection will be performed by the Operations
										&amp; Maintenance team of MGL.</li>
									<li>In case of Meter reading not available for 3 consecutive attempts &amp; after
										providing notice to consumer.</li>
									<li>Incase of customer do not allow for meter replacement after 15 year of installation
										and a notice to consumer</li>
								</ul>
							</div>
							</div>
							<div class="mt-30">
                            <h6>Collections &amp; Reconnection Policy</h6>
							<div class="blue-tick-list">
								<ul>
									<li>Customer has to pay the reconnection charges.</li>
									<li>If a customer has deferred his reconnection and has paid only Rs. 1600/- earlier
										shall pay the differential i.e. Rs. 2500/-.</li>
									<!--<li>On Payment of Rs. 4400/- a customer shall be eligible for re-connection.</li>-->
									<li>On Payment of Rs. 4230/- a customer shall be eligible for re-connection.</li>
								</ul>
							</div>
							</div>
							<div class="mt-30">
                            <h6>Cheque Dishonoured Procedure Followed by MGL</h6>
							<div class="blue-tick-list">
								<ul>
									<li>For every dishonour of cheque Rs. 200/- is charged for Domestic Consumer.</li>
									<li>In case of consecutive dishonour of cheques, notices are served on consumers under
										Section 138.</li>
								</ul>
							</div>
							 </div>
                            <!--<h2>General Billing Policy</h2><p>Coming Soon</p><h2>How Bills are Calculated</h2><p>Coming Soon</p>-->
                        </div>


							<h6 class="ecs_text">How to Get Refund?</h6>
							<p>Any customer, who has started using Domestic Piped Natural Gas (PNG) on or after 1st April
								2008, is eligible for refund of interest free Security Deposit under the following
								conditions: On customer’s request: </p>
							<h6>Security Deposit shall be refunded in full, irrespective of the status of connection,
								subject to receipt of the following:</h6>
								<div class="blue-tick-list">
							<ul >
								<li>Refund request letter from the customer.</li>
								<li>PNG equipments from the premises (flat / kitchen) of the customer, if applicable.</li>
								<li>Payment towards disconnection charges as per prevailing rate and all other dues.</li>
							</ul>
						</div>
							<h6 class="ecs_text">Other reasons: </h6>
							<h6>Security Deposit shall be refunded in full under the following conditions:</h6>
							<div class="blue-tick-list">
							<ul >
								<li>In case premise of the customer is found technically not feasible for supplying PNG.
								</li>
								<li>In case of non-availability of necessary statutory permissions.</li>
								<li>Any other reasons as and when specified by Statutory Authorities in its notifications.
								</li>
							</ul>
						</div>
							<p>For more information contact our Customer Care Executive on&nbsp;<strong>68674500 /
									61564500</strong>&nbsp;from 8:00 hrs. to 22:00 hrs. daily. Kindly quote your Business
								Partner (BP) Number as mentioned on your gas bill.</p>
						</div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Main content End -->
