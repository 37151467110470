<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/about-banner.jpg">
  <div class="banner-heading">
    <h1>PNG Overview</h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
    <li class=""><a>Residential PNG</a></li>
    <li class=""><a>Customer Zone</a></li>
        <li class="active" aria-current="page">PNG Overview</li>
      </ul>
    </div>
  </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
      <app-recent-post></app-recent-post>

                <app-tips></app-tips>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
  <div class="">
    <p >MGL has the distinction of pioneering <span> Natural Gas distribution network in
      Mumbai, </span>Its adjoining areas and Raigad district. More than 25.58 Lakh domestic customers are connected through our wide network of 7096 <!--6653--> kms which comprises of carbon steel (CS) and polyethylene (PE) pipeline. Domestic PNG is used for various purposes like cooking and water heating. </p>

    <p>It is also widely used by Hospitals, Nursing Homes, Hotels, Flight kitchens, Restaurants, places of Worship etc.
      PNG satisfies most of the requirements for fuel across all segments, being efficient, non-polluting and relatively
      economical.</p>

    <div class="mt-40 mb-40">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 y-middle">
          <h6 class="mb-0">The Different Uses <br>
            Of Domestic PNG</h6>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12">
          <div class="domestic-png-section">
            <a class="">
              <i><img src="assets/images/domestic-png-icon1.png"></i>
              <p>Cooking</p>
            </a>
            <a class="">
              <i><img src="assets/images/domestic-png-icon2.png"></i>
              <p>Water Heating</p>
            </a>
            <a class="">
              <i><img src="assets/images/domestic-png-icon3.png"></i>
              <p>Hospitals & Nursing Homes</p>
            </a>
            <a class="">
              <i><img src="assets/images/domestic-png-icon4.png"></i>
              <p>Flight Kitchens</p>
            </a>
            <a class="">
              <i><img src="assets/images/domestic-png-icon5.png"></i>
              <p>Hotels & Restaurants</p>
            </a>
            <a class="">
              <i><img src="assets/images/domestic-png-icon6.png"></i>
              <p>Places of Worship</p>
            </a>
          </div>
        </div>
      </div>
    </div>


  </div>
  <!-- Inner Page Content Section End -->
  <div class="benifits-piped-gas">
    <div class="container custom">
      <h3>Benefits of Piped Natural Gas</h3>
      <ul class="piped-natural-gas-content">
        <li>
          <i><img src="assets/images/benefits-png-icon1.png"></i>
          <h6>Convenience</h6>
          <p>Gas is continuously fed into the system so there are no hassles of refilling / changing the cylinder; it is
            piped and does not require any space to store, hence handling is easy, safe and secure.</p>
        </li>
        <li>
          <i><img src="assets/images/benefits-png-icon2.png"></i>
          <h6>Consistent, reliable supply</h6>
          <p>MGL has got a track record of almost 100% reliability in its gas supply. Even during the deluge of July
            2005 in Mumbai, when most other utilities failed, PNG supply continued uninterrupted.</p>
        </li>
        <li>
          <i><img src="assets/images/benefits-png-icon3.png"></i>
          <h6>Economical</h6>
          <p> Higher savings than any other conventional fuel.</p>
        </li>
        <li>
          <i><img src="assets/images/benefits-png-icon4.png"></i>
          <h6>Safe</h6>
          <p> Robust systems and processes as per international safety standards that match the best in the world, are
            practiced. PNG being lighter than air disperses easily and avoids spontaneous flammability.</p>
        </li>
        <li>
          <i><img src="assets/images/Eco-Friendly-.png"></i>
          <h6>Eco Friendly</h6>
          <p>Use of PNG improves public health. It also reduces the transportation of bottled gas within the metro
            thereby reducing traffic congestion and safety on the roads.</p>
        </li>
      </ul>
    </div>
  </div>

  <div class="">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="mt-40">

          <h3>PNG Composition</h3>
          <p>With only one carbon and four hydrogen atoms per molecule (Methane) Natural Gas is a composition of
            hydrocarbons (Almost 95% Methane & rest other Hydro Carbons). Its calorific value generally ranges from 8000
            kcal/m3 to 9000 kcal/m3, Natural Gas has the lowest carbon to hydrogen ratio, and hence it burns completely,
            making it a more environment friendly fuel.</p>

          <div class="png-composition-table">
            <ul>
              <li><span class="space"><strong>Physical state</strong></span><abbr><strong>Gas</strong></abbr></li>
              <li><span class="space">Colour</span><abbr>Colourless</abbr></li>
              <li><span class="space">Odour</span><abbr>Odourless (For easy detection through smell, Spot-leak is
                  added as Odourant)</abbr></li>
              <li><span class="space">Melting point</span><abbr>-182 ° C</abbr></li>
              <li><span class="space">Boiling point</span><abbr>-161.5°C</abbr></li>
              <li><span class="space">Vapor density</span><abbr>0.6 to 0.7 (with respect to air)</abbr></li>
              <li><span class="space">Flammability ratio</span><abbr>5 to 15% by volume in air</abbr></li>
              <li><span class="space">Auto ignition temp</span><abbr>540°C</abbr></li>
            </ul>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>


</div>
</div>

</div>