<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Domestic PNG Connection from a Gasified Institution Building </h1>

        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Domestic Connection Request</a></li>
                <li class=""><a>Domestic PNG Registration</a></li>
                <li class="active" aria-current="page">Domestic PNG Connection from a Gasified Institution Building</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">

                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Title"></a>
                                    <span class="date-post"> <i class="fa fa-calendar"></i> {{i.PublishDate | date:'MMM
                                        dd, yyyy'}}</span>
                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i
                                    class="demo-icon uil-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
                <!-- Tips -->

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>


                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">
                    <div class="domestic_png_connection">


                        <div class="reg_form_box">

                            <input type="hidden" name="ctl00$ContentPlaceHolder1$hiddma_id"
                                id="ContentPlaceHolder1_hiddma_id">

                            <p class="mb-15"><b><i>Applicants may please Note that :</i></b></p>
                             <ol class="number-list mb-10">
                                <li> Building in which the applicant resides should have active PNG connections.
                                </li>
                                <li> Payments may be made Online.
                                </li>
                                <li> If the applicant’s premises is found to be technically not feasible as per current safety standards, MGL will refund the amount paid by the applicant in full.</li>
                                <li>  Applicant should be the legal occupant of the premises for which new connection is requested. In case of any dispute w.r.t. ownership/occupancy arising in future, the Security Deposit against the connection shall stand forfeited.
                                </li>
                               
                            </ol>


                            <div class="light-blue-bg mt-20 domestic-conn-req mb-lg-0 mb-30">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <p><b><i>Please enter the details below to submit your Domestic Connection
                                                    Request</i></b></p>
                                        <div class="customer-login-page">
                                            <div class="">
                                                <form [formGroup]="UserForm">
                                                    <div class="row g-3 align-items-center">
                                                        <!-- <div class="col-auto">

                                                        </div> -->
                                                        <div class="col-auto">

                                                            <div class="form-floating">
                                                                <input type="number" class="form-control"
                                                                    formControlName="pincode"
                                                                    onKeyPress="if(this.value.length==6) return false;"
                                                                    placeholder="pincode" maxlength="6"
                                                                    [ngClass]="{ 'is-invalid': submitted && UserForm.controls['pincode'].errors }"
                                                                    id="pincode" />
                                                                <div *ngIf="submitted && UserForm.controls['pincode'].errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="UserForm.controls['pincode'].errors.required">
                                                                        Required Field</div>

                                                                </div>
                                                                <label for="Captcha">Enter the Pincode<span
                                                                        style="color: crimson;">*</span></label>
                                                            </div>
                                                        </div>
                                                        <div class="col-auto">
                                                            <div class="">
                                                                <button (click)="submit()" class="greenbtn2"
                                                                    [disabled]="isSubmitDisable"> <span *ngIf="loading"
                                                                        class="spinner-border spinner-border-sm mr-1"></span>
                                                                    Go <i
                                                                        class="demo-icon uil-arrow-right"></i></button>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="alertType == 'goSubmit'"
                                                            [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                                            class="card card-inverse-info" id="context-menu-simple">
                                                            <div class="card-body">
                                                                <p class="card-text">
                                                                    {{alertMsg}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                                <span *ngIf="status && Address.length == 0">
                                                    <div class="alert alert-warning mt-10"><i
                                                            class="bi flex-shrink-0 me-2 demo-icon uil-exclamation-circle"></i>
                                                        Area Not Serviceable</div>
                                                </span>

                                                <div class="row" *ngIf="status && Address.length > 0">

                                                    <div class="customer-login-page log-meter-reading">
                                                        <div class="col-auto">
                                                            <label class="mt-30"><b>Please search your Building
                                                                    Name</b></label>
                                                            
                                                        </div>

                                                        <div class="row">
                                                            
                                                            <div class="col-sm-8 form-group">
                                                               

                                                                <div class="form-floating">
                                                                    <div class="ng-autocomplete">
                                                                        <ng-autocomplete [data]="Address"
                                                                            [searchKeyword]="keyword"
                                                                            [(ngModel)]=userLocation [min-length]="4"
                                                                            placeholder="Select Building Name"
                                                                            (selected)='selectEvent($event)'
                                                                            (inputChanged)='onChangeSearch($event)'
                                                                            (inputFocused)='onFocused($event)'
                                                                            [itemTemplate]="itemTemplate"
                                                                            [notFoundTemplate]="notFoundTemplate">
                                                                        </ng-autocomplete>

                                                                        <ng-template #itemTemplate let-item>
                                                                            <a
                                                                                [innerHTML]="item.Bldg_name+','+
                                                          item.Colony_name +','+item.Road_name+','+','+item.Pincode"></a>
                                                                        </ng-template>

                                                                        <ng-template #notFoundTemplate let-notFound>
                                                                            <div [innerHTML]="notFound"></div>
                                                                        </ng-template>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                           
                                                        </div>
                                                    </div>

                                                    
                                                </div>
                                              


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div class="form_50per choose"></div>
                            <div class="form_50per choose">
                                <div class="Select_box"></div>
                            </div>
                            <div id="ContentPlaceHolder1_chk"></div>

                        </div>


                        <div *ngIf="status2" class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">

                                <div class="customer-login-page log-meter-reading ">

                                    <form [formGroup]="UserForm2">
                                        <div class="light-blue-bg mt-10">
                                            <p><b>Installation Address</b></p>
                                            <div class="row">
                                                <div class="col-lg-2 col-md-2 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control" formControlName="flatNo"
                                                            onKeyPress="if(this.value.length==4) return false;"
                                                            placeholder="flatNo" maxlength="10"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['flatNo'].errors }"
                                                            id="flatNo" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['flatNo'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['flatNo'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Captcha">Flat No. <span
                                                                style="color: crimson;">*</span></label>
                                                    </div>
                                                </div>

                                                <div class="col-lg-2 col-md-2 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control" formControlName="floor"
                                                            onKeyPress="if(this.value.length==2) return false;"
                                                            placeholder="floor" maxlength="10"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['floor'].errors }"
                                                            id="floor" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['floor'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['floor'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Captcha">Floor </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control" formControlName="plot"
                                                            onKeyPress="if(this.value.length==3) return false;"
                                                            placeholder="plot" maxlength="10"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['plot'].errors }"
                                                            id="plot" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['plot'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['plot'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Captcha">Plot No. </label>
                                                    </div>

                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input readonly type="text" class="form-control"
                                                            formControlName="wing" placeholder="wing"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['wing'].errors }"
                                                            id="wing" maxlength="10" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['wing'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['wing'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="wing">Wing</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control" formControlName="Sector"
                                                            placeholder="Sector" maxlength="10"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Sector'].errors }"
                                                            id="Sector" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['Sector'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['Sector'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Sector">Sector</label>
                                                    </div>


                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input readonly type="text" class="form-control"
                                                            formControlName="BuildingName" placeholder="BuildingName"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['BuildingName'].errors }"
                                                            id="BuildingName" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['BuildingName'].errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="UserForm2.controls['BuildingName'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="BuildingName">Building Name<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input readonly type="text" class="form-control"
                                                            formControlName="SocietyName" placeholder="SocietyName"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['SocietyName'].errors }"
                                                            id="SocietyName" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['SocietyName'].errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="UserForm2.controls['SocietyName'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="BuildingName">Society Name</label>
                                                    </div>
                                                </div>
                                                 <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input readonly type="text" class="form-control"
                                                            formControlName="Location" placeholder="Location"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Location'].errors }"
                                                            id="Location" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['Location'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['Location'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Location">Location</label>
                                                    </div>
                                                </div>

                                              

                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input readonly type="text" class="form-control"
                                                            formControlName="Pincode" placeholder="Pincode"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Pincode'].errors }"
                                                            id="Pincode" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['Pincode'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['Pincode'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Pincode">Pincode</label>
                                                    </div>
                                                </div>

                                                <div class="row">
                                <div class="col-md-12">
                                    <p class="mb-2"><b>I want to go green and opt for paperless billing.</b></p>
                                     <div class="form-check form-check-inline">
                                         <input formControlName="go_green" (change)="getGoreen($event)" class="form-check-input" type="radio" name="color" value="Yes" id="iyes" [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['go_green'].errors }"><label class="form-check-label" for="iyes"> Yes</label>
                                         </div>

                                          <div class="form-check form-check-inline">
                                          <input formControlName="go_green" (change)="getGoreen($event)" class="form-check-input" type="radio" name="color" value="No" id="ino" [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['go_green'].errors }"> <label class="form-check-label" for="ino">No</label>
                                        </div>
                                            <div *ngIf="submitted3 && UserForm2.controls['go_green'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['go_green'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <p class="mb-2" *ngIf="gogreentenFlag"><b>Please note: Rs 10 per bill will be charged for Hard Copy.</b></p>
                                </div>
                             
                           </div>
                                                
                             
                                                <!--New Form-->
                                            </div>
                                        </div>
                                        <div class="light-blue-bg mt-10">
                                            <div class="row">
                                                <p><b>Personal Details</b></p>


                                              
                                                <!--  <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control"
                                                            formControlName="NameofInstitution" placeholder="NameofInstitution"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['NameofInstitution'].errors }"
                                                            id="NameofInstitution" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['NameofInstitution'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['NameofInstitution'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="NameofInstitution">Name Of Institution<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div> -->
                                                 <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <select formControlName="Initial"
                                                            [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['Initial'].errors}"
                                                            class="form-select">
                                                              <option value="">Initial</option>
                                                              <option value="Mr.">Mr.</option>
                                                                    <option value="Mrs.">Mrs.</option>
                                                                    <option value="Miss.">Miss.</option>
                                                        </select>
                                                        <div *ngIf="submitted3 && UserForm2.controls['Initial'].errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="UserForm2.controls['Initial'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Initial">Initial<span
                                                                class="text-danger">*</span></label>

                                                    </div>
                                                  
                                                </div>
                                                 <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <select formControlName="NameofInstitution"
                                                            [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['NameofInstitution'].errors}"
                                                            class="form-select">
                                                              <option value="">Select Name Of Institution</option>
                                                             <option  value="Indian Navy">Indian Navy</option>
                                                             <option  value="Indian Army">Indian Army</option>
                                                        </select>
                                                        <div *ngIf="submitted3 && UserForm2.controls['NameofInstitution'].errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="UserForm2.controls['NameofInstitution'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="NameofInstitution">Name Of Institution<span
                                                                class="text-danger">*</span></label>

                                                    </div>
                                                  
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control"
                                                            formControlName="FName" placeholder="FName"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['FName'].errors }"
                                                            id="FName" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['FName'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['FName'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="FullName">First Name<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control"
                                                            formControlName="MName" placeholder="MName"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['MName'].errors }"
                                                            id="MName" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['MName'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['MName'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="MName">Middle Name</label>
                                                        <!-- <label for="MName">Middle Name<span
                                                                class="text-danger">*</span></label> -->
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control"
                                                            formControlName="LName" placeholder="LName"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['LName'].errors }"
                                                            id="LName" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['LName'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['LName'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="LName">Last Name<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                              
                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input onKeyPress="if(this.value.length==10) return false;"
                                                            min="1" type="number" class="form-control"
                                                            formControlName="Mobile" placeholder="Mobile"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Mobile'].errors }"
                                                            id="Mobile" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['Mobile'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['Mobile'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Mobile">Mobile No<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input onKeyPress="if(this.value.length==10) return false;" min="1"
                                                            type="number" class="form-control"
                                                            formControlName="AltMobile" placeholder="AltMobile"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['AltMobile'].errors }"
                                                            id="AltMobile" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['AltMobile'].errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="UserForm2.controls['AltMobile'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="AltMobile">Alternate No</label>
                                                    </div>
                                                </div>
                                              
                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="email" class="form-control"
                                                            formControlName="EmailId" placeholder="EmailId"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['EmailId'].errors }"
                                                            id="EmailId" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['EmailId'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['EmailId'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="EmailId">Email ID<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                              
                                                <div class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <select #LPG (change)="checkLpg(LPG.value)"
                                                            formControlName="HaveLpg"
                                                            [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['HaveLpg'].errors}"
                                                            (change)="this.UserForm2.get('HaveLpg')?.setValue(LPG.value)"
                                                            class="form-select">
                                                            <option value="" selected>Select Options</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>


                                                        </select>
                                                        <div *ngIf="submitted3 && UserForm2.controls['HaveLpg'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['HaveLpg'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="HaveLpg">Do you have a LPG connection in your
                                                            name?<span class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div *ngIf="LpgStatus" class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <select #ans
                                                            [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['IfYes'].errors}"
                                                            (change)="this.UserForm2.get('IfYes')?.setValue(ans.value)"
                                                            class="form-select">
                                                            <option value="" selected>If Yes</option>
                                                            <option value="Indane Gas">Indane Gas</option>
                                                            <option value="HP Gas">HP Gas</option>
                                                            <option value="Bharat Gas">Bharat Gas</option>
                                                            <option value="None Of the Above">None Of the Above</option>


                                                        </select>
                                                        <div *ngIf="submitted3 && UserForm2.controls['IfYes'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['IfYes'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="IfYes">If Yes<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div *ngIf="LpgStatus" class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control"
                                                            formControlName="Distributor" placeholder="Distributor"
                                                            maxlength="50"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Distributor'].errors }"
                                                            id="Distributor" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['Distributor'].errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="UserForm2.controls['Distributor'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Distributor">Name of the LPG Distributor<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div *ngIf="LpgStatus" class="col-lg-6 col-md-6 col-sm-12">


                                                    <div class="form-floating mb-3">
                                                        <input type="text" class="form-control"
                                                            formControlName="Consumer" placeholder="Consumer"
                                                            [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Consumer'].errors }"
                                                            id="Consumer" />
                                                        <div *ngIf="submitted3 && UserForm2.controls['Consumer'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm2.controls['Consumer'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="Consumer">LPG Consumer No<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="payShowFlag==false">


                                                    <div class="form-floating mb-3">
                                                        <select (change)="checkPayment()" formControlName="paymentType"
                                                            [ngClass]="{'is-invalid':submitted3 && UserForm2.controls['paymentType'].errors}"
                                                            class="form-select">
                                                              <option value="">Select payment type</option>
                                                             <option  value="Refundable Consumption Security Deposit">Refundable Consumption Security Deposit – Rs 1000</option>
                                                        </select>
                                                        <div *ngIf="submitted3 && UserForm2.controls['paymentType'].errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="UserForm2.controls['paymentType'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="paymentType">Payment Type<span
                                                                class="text-danger">*</span></label>

                                                    </div>
                                                  
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                                            <label>Captcha </label>
                                                            <div (copy)="false" class="captcha-text ">
                                                                <div class="mt-15">{{captcha}} <i
                                                                        (click)="captchaGenerate()"
                                                                        class="fa fa-refresh" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-md-6 col-sm-12">


                                                            <div class="form-floating mb-3">
                                                                <input type="text" class="form-control"
                                                                    formControlName="Captcha" placeholder="Captcha"
                                                                    [ngClass]="{ 'is-invalid': submitted3 && UserForm2.controls['Captcha'].errors }"
                                                                    id="Captcha" />
                                                                <div *ngIf="submitted3 && UserForm2.controls['Captcha'].errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="UserForm2.controls['Captcha'].errors.required">
                                                                        Required Field</div>

                                                                </div>
                                                                <label for="Captcha">Captcha<span
                                                                        class="text-danger">*</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- <div class="row y-middle">
                                                
                                            </div> -->
                                            <div class="mt-20">
                                                <div class="row">
                                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                        <button (click)="submit3()" class="greenbtn2"
                                                            [disabled]="isSubmitDisable">Submit <i
                                                                class="demo-icon uil-arrow-right"></i></button>
                                                    </div>
                                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div class="text-right md-text-left">
                                                            <button (click)="reset()" class="bluebtn2">Reset <i
                                                                    class="demo-icon uil-redo"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-right mt-20">
                                                <p class="mb-0"><i><b>Please note all fields marked <span
                                                                class="text-danger">*</span> are compulsory.</b></i></p>
                                            </div>

                                            <!-- <div *ngIf="alertType == 'secSubmit'"
                                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                                class="card card-inverse-info" id="context-menu-simple">
                                                <div class="card-body">
                                                    <p class="card-text">
                                                        {{alertMsg}}
                                                </div>
                                            </div> -->
                                        </div>

                                        <!---New Form End-->


                                    </form>

                                  
                                </div>

                            </div>
                        </div>
                        <!-- *ngIf="status3" -->
                        <div *ngIf="status3" class="mt-20">
                            <div class="light-blue-bg station-list">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                    class="mb-30 png-connection-table">
                                    <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <th>Flat No</th>
                                            <th>Building</th>
                                           <!--  <th>Location</th> -->
                                            <th>Payment Type</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                        </tr>
                                        <tr>
                                            <td data-label="Name"><span
                                                    class="InstrumentNo1">{{this.UserForm2.get('FName')?.value}} {{this.UserForm2.get('MName')?.value}} {{this.UserForm2.get('LName')?.value}}</span>
                                            </td>
                                            <td data-label="Fl.No."><span
                                                    class="PaymentDate1">{{this.UserForm2.get('flatNo')?.value}}</span>
                                            </td>
                                            <td data-label="Building Name"><span
                                                    class="PaymentSource1">{{this.UserForm2.get('BuildingName')?.value}}</span>
                                            </td>
                                            <td data-label="Meter No."><span
                                                    class="Amount1">{{this.UserForm2.get('paymentType')?.value}}- Rs
                                                    {{this.UserForm2.get('paymentAmt')?.value}}</span>
                                            </td>
                                            <td data-label="Mobile No."><span
                                                    class="Amount1">{{this.UserForm2.get('Mobile')?.value}}</span></td>
                                            <td data-label="Email"><span
                                                    class="Amount1">{{this.UserForm2.get('EmailId')?.value}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button (click)="submit44()" class="greenbtn2" [disabled]="isConfirmDisable">Confirm <i
                                        class="demo-icon uil-arrow-right"></i></button>



                               <!--  <div class="alert alert-info mt-20"><b>Your request for a PNG connection is saved.</b>
                                </div> -->

                                <div *ngIf="alertType == 'secSubmit'"
                                                [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                                class="card card-inverse-info" id="context-menu-simple">
                                                <div class="card-body">
                                                    <p class="card-text">
                                                        {{alertMsg}}
                                                </div>
                                            </div>

                            </div>
                        </div>
                        <!-- *ngIf="status4" -->
                        <div *ngIf="status4" class="light-green-bg mt-10">

                            <p class="Application mb-10"><b><i>Please note your Application No:</i></b></p>
                            <div class="Application">
                                <strong><span
                                        id="ContentPlaceHolder1_lblappln_no">{{this.UserForm2.get('ApplicationNo')?.value}}</span></strong>
                            </div>

                            <!-- <p class="mb-10"><b><i>Please choose from the options below:</i></b></p> -->
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-sm-12">
                                    <!-- <label class="mb-15">Please Rate the Services of MGL<span
                                            class="text-danger">*</span></label> -->

                                    <form>
                                        <div class="row service-label">
                                          

                                            <div class="col-md-6">
                                                <div class="rate-services-box">
                                                    <div class="form-check">
                                                        <input (change)="ActivatePayRegistrationCharges()"
                                                            formControlName="Rating" type="radio" id="html"
                                                            name="fav_language" value="Pay Registration Charges"
                                                            class="form-check-input">
                                                        <label for="html" class="mb-0">Pay Registration Charges</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>

                           
                        </div>


                        <div *ngIf="status5" class="light-blue-bg mt-10">
                          
                            <form [formGroup]="UserForm4">

                                <p class="mb-15"><b><i>Please upload a scanned copy for proof of ‘ADDRESS & IDENTITY’ (preferably in PDF Format):</i></b></p>
                                <div class="mb-25">
                                   
                                    <ol class="number-list">
                                        <li>Allotment Letter / Letter from Institution</li>
                                        <li>Others</li>
                                    </ol>
                                </div>
                                <p class="mb-15"><b><i>
                                            Note- Please upload PDF file and file size should be below 2MB.
                                        </i></b></p>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">

                                        <div class="customer-login-page">

                                            <div class="row">

                                            
                                                <!--  <div class="col-lg-6 col-md-6 col-sm-12">

                                                    <div class="form-floating mb-3">
                                                        <select formControlName="type1"
                                                            [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type1'].errors}"
                                                            class="form-select" (change)="checkAddproof()">
                                                            <option value="" selected>Select Proof of Ownership</option>
                                                            <option value="Government ID like (#) with above installation address">Government ID like (#) with above installation address</option>
                                                            <option value="Society Document (Share Certificate/Letter/Maintainence Bill)">Society Document (Share Certificate/Letter/Maintainence Bill)</option>
                                                            <option value="Sale Deed/Lease deed Agreement to sale">Sale Deed/Lease deed Agreement to sale</option>
                                                            <option value="House Tax Receipt/ Electricity Bill/Company letter">
                                                                House Tax Receipt/ Electricity Bill/Company letter</option>
                                                        </select>
                                                        <div *ngIf="submitted4 && UserForm4.controls['type1'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm4.controls['type1'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="type1">Select Proof of Ownership<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div> -->
                                                 <div class="col-lg-6 col-md-6 col-sm-12">

                                                    <div class="form-floating mb-3">
                                                        <select formControlName="type1"
                                                            [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type1'].errors}"
                                                            class="form-select" (change)="checkAddproof()">
                                                            <option value="" selected>Select Proof of Ownership</option>
                                                            <option value="Allotment Letter / Letter from Institution">Allotment Letter / Letter from Institution</option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                        <div *ngIf="submitted4 && UserForm4.controls['type1'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm4.controls['type1'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="type1">Select Proof of Ownership<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <div class="mb-10 cu-login-input2" *ngIf="panCheckFlag == false">
                                                        <!-- <i class="demo-icon  uil-file-landscape-alt"></i> -->
                                                        <input formControlName="type1File"
                                                            (change)="checkApplictionFile($event,'1st')"
                                                            [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type1File'].errors}"
                                                            class="from-control" type="file" id="name" name="name"
                                                            accept="application/pdf"
                                                            placeholder="Select ownership proof"
                                                            accept="image/jpeg, image/png, application/pdf">
                                                        <div *ngIf="submitted4 && UserForm4.controls['type1File'].errors"
                                                            class="invalid-feedback">
                                                            <div style="margin-left: 50px;"
                                                                *ngIf="UserForm4.controls['type1File'].errors">Required
                                                                Field
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <div class="form-floating mb-3">
                                                        <select formControlName="type2"
                                                            [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type2'].errors}"
                                                            class="form-select">
                                                            <option value="" selected>Select Proof of identity</option>
                                                            <option value="Aadhar Card">Aadhar Card</option>
                                                             <option value="Government ID">Government ID</option>
                                                              <option value="Driving License">Driving License</option>
                                                            <option *ngIf="panCheckFlag == false"
                                                                value="PAN Card">PAN Card</option>
                                                        </select>
                                                        <div *ngIf="submitted4 && UserForm4.controls['type2'].errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="UserForm4.controls['type2'].errors.required">
                                                                Required Field</div>

                                                        </div>
                                                        <label for="type2">Select Proof of identity<span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <div class="mb-10 cu-login-input2">
                                                        <!-- <i class="demo-icon uil-file-landscape-alt"></i> -->
                                                        <input (change)="checkApplictionFile($event,'2nd')"
                                                            accept="application/pdf"
                                                            [ngClass]="{'is-invalid':submitted4 && UserForm4.controls['type2File'].errors}"
                                                            formControlName="type2File" class="from-control" type="file"
                                                            onKeyPress="if(this.value.length==10) return false;"
                                                            accept="application/pdf" id="name" name="name"
                                                            placeholder="Enter your BP Number">
                                                        <div *ngIf="submitted4 && UserForm4.controls['type2File'].errors"
                                                            class="invalid-feedback">
                                                            <div style="margin-left: 50px;"
                                                                *ngIf="UserForm4.controls['type2File'].errors">Proof of
                                                                identity
                                                                required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-10 ">
                                                    <button (click)="submit5()" class="greenbtn2">Upload <i
                                                            class="demo-icon uil-upload-alt"></i></button>
                                                </div>

                                                <div *ngIf="alertType == 'LastSubmit'"
                                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                                    class="card card-inverse-info" id="context-menu-simple">
                                                    <div class="card-body">
                                                        <p class="card-text">
                                                            {{alertMsg}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>


                         <div *ngIf="activeTC" class="light-blue-bg mt-10">
                           <p class="mb-15"><b><i>DECLARATION BY APPLICANT:</i></b></p>
                                <div class="mb-25">
                                   
                                    <ol class="number-list">
                                        <li> I/We declare the above details are true & correct and authorize MGL to verify the same.</li>
                                        <li> I/ We acknowledge that I will visit www.mahanagargas.com for latest terms and conditions which shall be binding on me/us. </li>
                                        <li>  I/We agree that this application can be accepted or rejected by Mahanagar Gas Ltd., without assigning any reason thereof.</li>
                                        <li> I/We agree to surrender the LPG Connection within 60 days of activation of PNG Connection OR keep the LPG Connection in safe Custody.</li>
                                    </ol>
                                </div>
                              </div>
                               <div *ngIf="activeTC" class="light-blue-bg mt-10">
                           <p class="mb-15"><b><i>NOTE:</i></b></p>
                                <div class="mb-25">
                                   
                                    <ol class="number-list">
                                        <li> For any details/ queries, please call us on Customer Care.</li>
                                        <li> Application Charges will be refundable in case premises is found "TECHNICALLY NOT FEASIBLE" by MGL.</li>
                                        <li> Any other charges shall be charged as per the standard Rate card which is available on website www.mahanagargas.com </li>
                                        <li> Anybody demanding cash or extra charge must be brought to the notice of Vigilance Dept. via mail at vigilance@mahanagargas.com. </li>
                                         <li> Incomplete Application Form will not be accepted</li>
                                         <li> MGL bill is not proof of ownership of the Property. </li>
                                    </ol>
                                </div>
                              </div>


                     

                          <div *ngIf="activeTC" class="light-blue-bg mt-10 mb-lg-0 mb-30">
                            

                            <div class="row service-label">
                                <div class="col-md-12">
                                    <div class="form-check">
                                        <input (click)="ActivatePayment()" formControlName="Rating" type="checkbox"
                                            id="html" name="fav_language" value="Print Application Form"
                                            class="form-check-input">
                                        <label for="html" class="">
                                            <a class="terms-label" href="assets/images/domesticformterms/domestic-institution-form-terms.pdf" target="_blank">I HAVE READ AND UNDERSTOOD THE TERMS AND CONDITIONS FOR SUPPLY OF PNG.</a></label>
                                         
                                          
                                    </div>
                                </div>
                            </div>

                             




                            
                             <div class="row">
                             <div *ngIf="activepaymentlink" class="mt-20" class="col-md-6">
                              
                                <button  (click)="printApplFull()" class="greenbtn2">Print Application Form <i
                                        class="demo-icon uil-print"></i></button>
                                         
                                       
                            
                            </div>
                            <div *ngIf="activepaymentlink" class="mt-20" class="col-md-6">
                                <a (click)="makePayment()" class="bluebtn2">Make Payment <i
                                        class="demo-icon uil-files-landscapes-alt"></i></a>
                            </div>
                        </div>
                    

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




 

<div class="modal fade" id="exampleModal-rejected" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" (click)=" closePngModel()" class="close"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="text-center">
            <img src="assets/images/PNGmahotsavnew.png" class="mx-auto">
          <!-- <div class="col-12 ">
            
          </div> -->
        </div>
      </div>
       <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-success" (click)=" closePngModel()">Okay</button>
        <button type="button" class="btn btn-light" (click)=" closePngModel()">Cancel</button>
      </div>
     
    </div>
  </div>
</div>

<div class="modal fade  {{Popup == 'show' ? 'show' : ''}}" id="exampleModal" tabindex="-1"
    aria-labelledby="exampleModalLabel" [ngStyle]="{'display': display, 'padding-right': '17px'}" aria-modal="true"
    role="dialog">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <button (click)="closeModel()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                    class="demo-icon uil-multiply"></i></button>
            <div class="modal-body">
                <div class="">
                    <div class="customer-login-page log-meter-reading">
                        <div class="mb-20">
                           “Your Payment plan is as below.”
                        </div>
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <div>
                                    <p class="mb-2"><b><i>Payment Type : {{this.UserForm2.get('paymentType')?.value}} -Rs {{this.UserForm2.get('paymentAmt')?.value}}</i></b></p>
                                    
                                </div>

                             
                                                      
                                                   
                                                   
                                                  
                                                    
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                <button class="greenbtn2" (click)="submit4()">Confirm</button>
                            </div>

                            <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                <button (click)="closeModel()" class="greybtn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class=" {{Popup == 'show' ? 'modal-backdrop fade show' : ''}}"></div>



<div class="modal fade" id="exampleModal-already-reg" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" (click)=" closealreadyModel()" class="close"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
       
      </div>
       <div class="modal-footer d-flex justify-content-center">
        <button type="button" class="btn btn-success" (click)="closeproceedModel()">Proceed</button>
        <button type="button" class="btn btn-light" (click)=" closealreadyModel()">Cancel</button>
      </div>
     
    </div>
  </div>
</div>


<!-- Inner Page Content Section End -->