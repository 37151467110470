<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Documents Required
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >Industrial & Commercial   </a></li>
                <li class=""><a  >Commercial PNG</a></li>
                <li class=""><a>Apply For Commercial Connection</a></li>
                <li class="active" aria-current="page"><a >Documents Required
                    </a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<<!-- app-service-section></app-service-section> -->
<app-commercial-service-section></app-commercial-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips>
                <app-insta-post></app-insta-post>
               <!--  <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content awards-section">
                    <h6>Documents required for <span>Customers of Commercial PNG*</span></h6>
                    <div class="blue-tick-list">
                        <ul class="unordered-list">
                            <li>Technical Assessment Form.</li>
                             <li>Shop and establishment certificate / Star Category certificate (if Hotel).</li>
                              <li>Board Resolution or Power of Attorney or Authority Letter nominating your authorized signatory on behalf of your company to execute the agreement (no standard format) - duly signed and stamped by Authorized signatory.</li>
                              <li>GST, PAN and VAT TIN photocopy (signed and stamped).</li>
                              <li>NOC from society/Landlord/owner of land (signed and stamped). </li>
                          <!-- <li> Board Resolution or Power of Attorney or Authority Letter nominating your authorized signatory on behalf of your company to execute the agreement (no standard format) --- duly signed and stamped by Authorized signatory<li>

                        <li>Technical Assessment Form:-</li>	
                        <li>Fuel requirement form (Annexure form) (signed and stamped)</li>
                        <li>Drawing issued by MGL (signed and stamped)</li>

                        <li>Brief profile of the consumers giving full details of the business including the particulars for which gas is required (On company letter head) (signed and stamped)</li>

                        <li>Name and address of all partners/Directors/CEO/MD/Chairman/Trustees and Members (On company letter head) (signed and stamped)</li>

                        <li>Registered office and site address--- duly signed by Company Secretary/ authorized signatory (On company letter head) (signed and stamped)</li>
                        <li>Shop and establishment certificate (signed and stamped)</li>
                        <li>Star Category certificate (signed and stamped)</li>
                        <li>Pan Card photocopy (signed and stamped)</li>
                        <li>VAT TIN number photocopy (signed and stamped)</li>
                        <li>GST Number (signed and stamped)</li>
                        <li>NOC from society/Landlord/owner of land (signed and stamped) </li> -->

                            
                        
                    
                            
                            
                        </ul>
                    </div>

                </div>
            </div>
        </div>    
    </div> 
</div>       