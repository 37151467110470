<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Type of Industries</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a  [routerLink]="['/']">Home</a></li>
                <li class=""><a >Industrial & Commercial  </a></li>
                <li class=""><a >Industrial PNG</a></li>
                <li class=""><a >Overview</a></li>
                <li class="active" aria-current="page"><a>Type of Industries</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-industrial-service-section></app-industrial-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <p>Few I & C applications
                    <span> of Natural Gas:</span>
                </p>
                <div class="light-blue-bg mt-20">
                    <div class="rs-faq applications-natural-gas">
                        <div class="faq-content">
                            <div id="accordion" class="accordion">
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link" href="#collapseOne" data-toggle="collapse"
                                            aria-expanded="true">Metal</a>
                                    </div>
                                    <div id="collapseOne" class="collapse" data-bs-parent="#accordion">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <div class="blue-tick-list">
                                                        <ul>
                                                            <li>Melting</li>
                                                            <li>Heat Treatment</li>
                                                            <li>Pre-heating of ingots</li>
                                                            <li>Powder Coating</li>
                                                            <li>Moulding/ Casting</li>
                                                            <li>Fabrication</li>
                                                        </ul>

                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                    <div class="blue-tick-list">
                                                        <ul class="unordered-list">
                                                            <li>Gas Cutting</li>
                                                            <li>Smelting</li>
                                                            <li>Welding</li>
                                                            <li>Pyro processing</li>
                                                            <li>Forging</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapseTwo" data-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false">Pharmaceuticals</a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <ul class="unordered-list">
                                                    <li>Steam Generation</li>
                                                    <li>Spray Drying</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">

                                        <a class="card-link collapsed" href="#collapseThree" data-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false">Food & Beverages</a>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <ul class="unordered-list">
                                                    <li>Steam Generation</li>
                                                    <li>Baking</li>
                                                    <li>Process Heating</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsefour" data-toggle="collapse"
                                            data-bs-target="#collapsefour" aria-expanded="false">Printing & Dyeing</a>
                                    </div>
                                    <div id="collapsefour" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <ul class="unordered-list">
                                                    <li>Drying of inks Pre-printing</li>
                                                    <li>Pre Drying of inks Post-printing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsefive" data-toggle="collapse"
                                            data-bs-target="#collapsefive" aria-expanded="false">Oil Mills</a>
                                    </div>
                                    <div id="collapsefive" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <ul class="unordered-list">
                                                    <li>Steam Generation</li>
                                                    <li>Distillation</li>
                                                    <li>Refining</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapsesix" data-toggle="collapse"
                                            data-bs-target="#collapsesix" aria-expanded="false">FMC Product
                                            Manufacturers</a>
                                    </div>
                                    <div id="collapsesix" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <ul class="unordered-list">
                                                    <li>Steam Generation</li>
                                                    <li>Waste Heat Treatment</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapseseven" data-toggle="collapse"
                                            data-bs-target="#collapseseven" aria-expanded="false">Power Generation</a>
                                    </div>
                                    <div id="collapseseven" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <ul class="unordered-list">
                                                    <li>Micro Gas Turbines</li>
                                                    <li>Gas Gensets</li>
                                                    <li>Combined Cooling, Heating &amp; Power</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <a class="card-link collapsed" href="#collapseeight" data-toggle="collapse"
                                            data-bs-target="#collapseeight" aria-expanded="false">Air-conditioning</a>
                                    </div>
                                    <div id="collapseeight" class="collapse" data-bs-parent="#accordion" style="">
                                        <div class="card-body">
                                            <div class="blue-tick-list">
                                                <ul class="unordered-list">
                                                    <li>Vapour Absorption Machine (VAM)</li>
                                                    <li>Centralized Cooling</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-40">
                    <h3>Common NG appliances used in Industries: </h3>
                    <p>Boiler, Furnace, Kiln, Oven, Thermo pack, VAM, Natural Gas Fired Gensets.</p>

                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->





</div>
<!-- Main content End -->