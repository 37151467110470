<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Awards</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class="active" aria-current="page"><a >
                    Awards</a></li>
            </ul>
        </div>
    </div>
  </div>
  <!-- Services Section Start -->
  <!-- <app-service-section></app-service-section> -->
  <app-mgl-corporate-service-section></app-mgl-corporate-service-section>
  <!-- Services Section End -->
  
  <!-- Inner Page Content Section Start -->
  <div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <app-recent-post></app-recent-post>
  
                <app-tips></app-tips>
                <app-insta-post></app-insta-post>
  
               <!--  <div class="mt-30">
  
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
  
                </div> -->
            </div>
  
            <div class="col-lg-9 col-md-9 col-sm-12">
                <div class="innerpage-right-content news-section-page rs-blog blog-main-home ">
                    <div class="row">
                        <div *ngFor="let AwardsItem of Awards" class="col-lg-4 col-md-4 col-sm-12 mb-30">
                            <div class="blog-item awardspage">
                                <div class="image-wrap">
                                    <a *ngIf="AwardsItem.Image"><img src="{{baseUrl}}{{AwardsItem.Image}}" class="w-100"></a>
                                </div>
                                <div class="blog-content">
                                    <!-- <ul class="blog-meta">
                                        <li class="date">{{i.Title}} {{i.PublishDate | date:'MM/dd/yyyy'}}</li>
                                    </ul> -->
                                    <!-- <h3 class="blog-title"><a routerLink="/MGL-corporate/media/news/{{i._id}}"></a></h3> -->
                                    <ul class="blog-meta">
                                        <li class="date">{{AwardsItem.Year}}</li>
                                    </ul>
                                    <h3 class="blog-title"><a >{{AwardsItem.Title}}</a></h3> 
                                    <!-- <p class="desc" [innerHTML]="i.Description"></p> -->
                                    <div class="blog-btn">
                                        <a class="readon consultant blog" routerLink="/MGL-corporate/award-details/{{AwardsItem._id}}">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
  </div>