<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Technical Guidelines for PNG Installation</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a href="#">Home</a></li>
                <li class=""><a >Residential PNG</a></li>
                <li class=""><a >Domestic Connection Request</a></li>
                <li class="active" aria-current="page"><a href="#">Technical Guidelines for PNG Installation</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-residential-png-service-section></app-residential-png-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="mt-40">
                    <div class="box882_left">

                        <div class="con">
                            <h5>Meter Positions</h5>
                            <div class="blue-tick-list">
                                <ul>
                                    <li>Gas Meters will normally be located inside the property on wall, and use of
                                        copper
                                        pipe between MCV and consumer meter should be restricted as far as possible. The
                                        Kitchen is the preferred room to locate the meter thereby minimizing the length
                                        of
                                        the outlet pipe - work.</li>
                                    <li>The meter shall be installed in an area with adequate ventilation &amp; Gas
                                        pipeline
                                        not to&nbsp; be concealed</li>
                                    <li>As a guide, a minimum ventilation area of 2% of the internal floor area of the
                                        room must be provided in the room where the meter is located. The gaps under and
                                        around doors, windows and ventilators provide adequate ventilation to the rooms
                                        of
                                        normally occupied individual domestic dwellings.</li>
                                    <li>Space&nbsp; near Meter installation&amp; pipeline should not used
                                        for
                                        storage of any cleaning chemicals, harsh chemicals, rubbish or any other
                                        material
                                        prone to create any safety hazard to the meter/ pipeline &nbsp;and <em>I </em>or
                                        blocking ventilation.</li>
                                    <li>Meters must not be installed along a route that is used by more than one
                                        property as
                                        a means of a fire escape-i.e., a communal fire escape route.</li>
                                    <li>The meter shall be installed at a proper location and height, so as to
                                        facilitate
                                        safe and convenient meter reading and maintenance activities in future.</li>
                                    <li>Appliance Valve shall be fitted at least 100 mm above the kitchen platform level
                                        and
                                        at an &nbsp;easily accessible location.</li>
                                    <li>Steel reinforced rubber hose is connected between AV and Appliance Length of
                                        flexible hose shall be kept minimum. Max. length shoudn't exceed 1.5 meter</li>
                                </ul>
                            </div>
                            <h5>Pipe work in Island type Kitchen</h5>
                            <div class="blue-tick-list">
                                <ul>
                                    <li>In some of the residences, kitchen platform is located incenter of the room and
                                        pipe
                                        work requires extending from MCV &amp; meter (mounted on one of the wall) up to
                                        the
                                        appliance valve. Under such circumstances, pipe can be laid in flooring with
                                        adequate protection.</li>
                                    <li>Pipe work passing through flooring should take the shortest practicable route
                                        and
                                        must be sleeved.</li>
                                    <li>Pipe work laid in flooring shall be protected against corrosion and also against
                                        failure caused by movement. Pipes should be laid on the base concrete and avoid
                                        joints in the pipe section. A minimum of 65 mm screed is required on top of the
                                        concrete which will provide an approximate cover of 50 mm over a 12 mm
                                        installation
                                        pipe.<br>
                                        <br>
                                        In case of screeded floors, the method of construction consists of a base
                                        concrete
                                        raft foundation onto which a cement screed is subsequently laid in order to
                                        obtain a
                                        satisfactory surface to accept the floor finish. In this&nbsp; case, copper
                                        pipes
                                        may be laid on top of the base concrete and subsequently covered by the screed,
                                        provided the pipe is protected from corrosion. Alternatively, the copper pipes
                                        may
                                        be laid through pre-formed PVC <em>I </em>HOPE sleeves <em>I </em>ducts with
                                        protective covers
                                    </li>
                                </ul>
                            </div>
                            <!-- <h5>Gas geyser location: Gas Geysers should only be installed in areas like:</h5>
                            <div class="blue-tick-list">
                                <ul>
                                    <li>Kitchens, Living rooms, Utility Rooms, Halls and Passageways if flueing and
                                        ventilation can be achieved.</li>
                                    <li>Gas Geyser should be installed at such a height that it can be switched off
                                        easily.
                                    </li>
                                    <li>No Gas Geyser is permitted to be concealed
                                      
                                        
                                    </li>
                                     <li>Installation of Gas Geyser in Bathroom is not permitted</li>
                                </ul>
                            </div> -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Main content End -->