<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/cng-rate-inner-banner.jpg">
    <div class="banner-heading">
        <h1>MGL CNG Mahotsav</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>CNG</a></li>
                <li class=""><a>MGL CNG Mahotsav</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>
                <app-tips></app-tips> 
                <app-insta-post></app-insta-post>
                <!-- <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>

          
            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                 <h5>   Mahanagar Gas Limited launches “MGL CNG Mahotsav”  </h5> 
                  <div class="blue-tick-list">
                     <ul>
                         <!-- <li>Benefits worth INR 19,999/- for new or retrofitted CNG cars</li> -->
                        <li>Incentive of up to INR ₹ 4.5 lakhs for retrofitted CNG Commercial Vehicles</li>
                        <p>Mahanagar Gas Limited (MGL), one of the largest City Gas Distribution (CGD) companies in India, launched a new scheme ‘MGL CNG Mahotsav’ for retrofitment of existing petrol or diesel vehicles to CNG in Mumbai, Thane &amp;Raigad Districts.</p>
                        <p>The scheme, which can be availed on retrofitment of Commercial CNG vehicles, is launched to further encourage use of CNG, a clean &amp; green energy. Mahanagar Gas Limited has partnered with leading CNG kit distributors to promote quality retro-fitment.</p>
                     </ul>
                  </div>      
               <!--  <div class="mb-30">
                   
                    <img  src="assets/images/MGL CNG Mahotsav.jpg" class="w-100">
                </div> -->
                <div class="text-center md-text-center">
                <a class="bluebtn2 mr-2" [routerLink]="['/cng/cng-offers-schemes/retrofitment']">Retrofitters <i class="demo-icon uil-arrow-right"></i></a>
               <!--   <a class="bluebtn2" [routerLink]="['/cng/cng-offers-schemes/new-cng-vehicle-promotion']">New CNG Vehicle <i class="demo-icon uil-car"></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>