<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
     <h1>PNG Network

    </h1>   
     <div class="breadcrumbs">
           <ul class="">
             <li class=""><a [routerLink]="['/']">Home</a></li>
             <li class=""><a >Residential PNG</a></li>
             <li class=""><a >Domestic Connection Request</a></li>
             <li class="active" aria-current="page"><a >PNG Network
            </a></li>
           </ul>
     </div>
    </div>
</div>
<!-- Services Section Start -->
    <!--  <app-service-section></app-service-section> -->
    <app-residential-png-service-section></app-residential-png-service-section>
     <!-- Services Section End -->

 <!-- Inner Page Content Section Start -->
 <div class="innerpage-content-section">
 <div class="container custom">
       <div class="row">
 <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
 <app-recent-post></app-recent-post>

 <app-tips></app-tips>
 <app-insta-post></app-insta-post>

<!--  <div class="mt-30">

<a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by mahanagargas</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>


 </div> -->
 </div>

 <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
     <div class="innerpage-right-content awards-section mb-lg-0 mb-30">
        <img src="assets/images/GA-1-2-PNG-Network.jpg">
        <img src="assets/images/GA-3Model-PNG-Network.jpg">
       
 </div>
 </div>
 </div>
</div>
 <!-- Inner Page Content Section End -->





</div>