import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { ApplyforoutletService } from 'src/app/Service/applyforoutlet.service';
import { ApplyOutletItem } from 'src/app/models/applyforoutket';
import { DistrictItem } from 'src/app/models/applyforoutket';
import { PlotItem } from 'src/app/models/applyforoutket';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-applyforcngoulet',
  templateUrl: './applyforcngoulet.component.html',
  styleUrls: ['./applyforcngoulet.component.css']
})
export class ApplyforcngouletComponent {
  applyoutletForm!: FormGroup
  submitted = false
  applydata: ApplyOutletItem[] = []
  district: DistrictItem[] = []
  plot: PlotItem[] = []
  files!: any;
  LpgSlip: any
   captcha:any
   alertType: any = ''
  alertMsg: any = ''
  alertColor: string = ''
  alertTextColor: string = ''
  alertBorder: string = ''
  alertTextAlign: string = 'center';


Doc1_Propertycard_img:any;
Doc1_Propertycard_name:any;
Doc2_subleases_img:any;
Doc2_subleases_name:any;
Doc3_Photographsofland_img:any;
Doc3_Photographsofland_name:any;
Doc4_AgeProof_img:any;
Doc4_AgeProof_name:any;
Doc5_IDproof_img:any;
Doc5_IDproof_name:any;
  

  constructor(private pageLoaderService: PageLoaderService, private router: Router, private FormBuilder: FormBuilder, private service: ApplyforoutletService) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
  
    this.getdist()
    this.getPlot()
    this.getall ()
  this.captchaGenerate()
    this.applyoutletForm = this.FormBuilder.group({
      ApplicationNo:[''],
      Name: ['', [Validators.required]],
      ContactNumber: ['', [Validators.required,Validators.pattern(/^[0-9]{10}$/)]],
      // Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
       Email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      //NameofPlotOwner: ['', [Validators.required]],
     // RelationshipofAppOwner: ['', [Validators.required]],
      // Addressofplot: ['', [Validators.required]],
       Addressofplot: [''],
      SurveyNo: ['', [Validators.required]],
      District: ['', [Validators.required]],
     // PinCode: ['', [Validators.required,Validators.pattern(/^[0-9]{6}$/)]],
      AreaofthePlot: ['', Validators.required],
      //Frontageoftheplot: ['', Validators.required],
      //Depth: ['', Validators.required],
      // Latitude: ['', Validators.required],
      // Longitude: ['', Validators.required],
        Latitude: [''],
      Longitude: [''],
    //  NameofRoad: ['', Validators.required],
    //  Plot: ['', Validators.required],
      Additionalremarks: [''],
       Ownership_of_Land: ['', Validators.required],
       Correspondence_Address: ['', [Validators.required]],
      Doc1_Propertycard: ['', Validators.required],
      // Doc2_subleases: ['', Validators.required],
      // Doc3_Photographsofland: ['', Validators.required],
      // Doc4_AgeProof: ['', Validators.required],
      // Doc5_IDproof: ['', Validators.required],
    Captcha: ['',[Validators.required]],

    });

    var todayDate = new Date().toISOString().slice(0, 10);
//console.log(todayDate);

  }
  
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }

  getall() {
    this.service.getAll().subscribe((res: any) => {
      this.applydata = res
      // console.log(res)
      // console.log('this.applydata', this.applydata);
    })
  }


  getdist() {
    this.service.getdistrict().subscribe((res: any) => {
      // this.district = res
      this.district = [
      {"District":"Mumbai" },
      {"District":"Thane" },
      {"District":"Raigad" }
      ]
      // console.log(res)
      // console.log('this.District', this.district);
    })
  }

  getPlot() {
    this.service.getplot().subscribe((res: any) => {
      this.plot = res
      // console.log(res)
      // console.log('this.plot', this.plot);
    })
  }
  
  
   captchaGenerate() {
    let length = 5
    let result = '';
    // const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const characters = '0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha=result


  }

   fileCheckForSlip(val: any) {
    //console.log(val.target.files[0].type)
   
    if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
      // this.files = val.files
        this.Doc1_Propertycard_img = val.target.files;
         this.Doc1_Propertycard_name = val.target.files[0];
    } else {
      // this.LpgSlip = null;
      // console.log(val.files);
      // this.applyoutletForm.get(name)?.setValue(''); 
       this.applyoutletForm.get('Doc1_Propertycard')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }
  }

   fileCheckForSlip1(val: any) {
   
    if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
      // this.files = val.files
       this.Doc2_subleases_img = val.target.files;
         this.Doc2_subleases_name = val.target.files[0];
    } else {
      // this.LpgSlip = null;
      // console.log(val.files);
      // this.applyoutletForm.get(name)?.setValue(''); 
       this.applyoutletForm.get('Doc2_subleases')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }
  }


   fileCheckForSlip2(val: any) {

   
    if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
      // this.files = val.files
       this.Doc3_Photographsofland_img = val.target.files;
         this.Doc3_Photographsofland_name = val.target.files[0];
    } else {
      // this.LpgSlip = null;
      // console.log(val.files);
      // this.applyoutletForm.get(name)?.setValue(''); 
       this.applyoutletForm.get('Doc3_Photographsofland')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }
  }


   fileCheckForSlip3(val: any) {
   
    if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
      // this.files = val.files
       this.Doc4_AgeProof_img = val.target.files;
         this.Doc4_AgeProof_name = val.target.files[0];
    } else {
      // this.LpgSlip = null;
      // console.log(val.files);
      // this.applyoutletForm.get(name)?.setValue(''); 
       this.applyoutletForm.get('Doc4_AgeProof')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }
  }


   fileCheckForSlip4(val: any) {
   
    if ((val.target.files[0].type=="application/pdf"||val.target.files[0].type=="application/msword"||val.target.files[0].type=="application/vnd.openxmlformats-officedocument.wordprocessingml.document" )&& val.target.files[0].size < (5*1024*1024)) {
      // this.files = val.files
       this.Doc5_IDproof_img = val.target.files;
         this.Doc5_IDproof_name = val.target.files[0];
    } else {
      // this.LpgSlip = null;
      // console.log(val.files);
      // this.applyoutletForm.get(name)?.setValue(''); 
       this.applyoutletForm.get('Doc5_IDproof')?.setValue('');
      return alert('Please Select pdf,msword,document');
      
    }
  }

 




  // filechange(element: any) {
  // this.files = element.target.files
  // console.log(this.files)
  // }


  onSubmit() {
    this.submitted = true
  if(this.applyoutletForm.get('Captcha')?.value != this.captcha){
        this.applyoutletForm.get('Captcha')?.setValue('')
      }
    this.captchaGenerate()
    if (this.applyoutletForm.invalid) {
      return;
    }
    //console.log("hello")

         var mydate = new Date();

           var h = (mydate.getHours()<10?'0':'') + mydate.getHours();
    var m = (mydate.getMinutes()<10?'0':'') + mydate.getMinutes();
    var timeokay = h + ':' + m;
    //console.log(timeokay)
   
    var curr_date = mydate.getDate();

var curr_month = mydate.getMonth();

var curr_year = mydate.getFullYear();

//console.log(mydate,curr_date,curr_month,curr_year)

var xdfvdmgfddate = new Date(curr_year, curr_month, curr_date);
var result = xdfvdmgfddate.toLocaleDateString("en-GB", { // you can use undefined as first argument
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//console.log(result)

var myresultsplit = result.split('/');
//console.log(myresultsplit)

var myresultsplitlast2 = myresultsplit[2].slice(-2);
var time = mydate.toLocaleTimeString();
//console.log(time)
var timesplit = time.split(':');
//console.log(timesplit)

    const form = new FormData()

        if (this.Doc1_Propertycard_name != null) {
       let Doc1_PropertycardImg = this.Doc1_Propertycard_img[0].name.split('.').pop()
    let Doc1_PropertycardImg1  =this.Doc1_Propertycard_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ Doc1_PropertycardImg;
      form.append("Doc1_Propertycard", this.Doc1_Propertycard_img[0], Doc1_PropertycardImg1)
      
    }

    // if (this.Doc2_subleases_name != null) {
    //    let Doc2_subleasesImg = this.Doc2_subleases_img[0].name.split('.').pop()
    // let Doc2_subleasesImg1  =this.Doc2_subleases_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ Doc2_subleasesImg;
    //   form.append("Doc2_subleases", this.Doc2_subleases_img[0], Doc2_subleasesImg1)
      
    // }

    //  if (this.Doc3_Photographsofland_name != null) {
    //    let Doc3_PhotographsoflandImg = this.Doc3_Photographsofland_img[0].name.split('.').pop()
    // let Doc3_PhotographsoflandImg1  =this.Doc3_Photographsofland_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ Doc3_PhotographsoflandImg;
    //   form.append("Doc3_Photographsofland", this.Doc3_Photographsofland_img[0], Doc3_PhotographsoflandImg1)
      
    // }

    //  if (this.Doc4_AgeProof_name != null) {
    //    let Doc4_AgeProofImg = this.Doc4_AgeProof_img[0].name.split('.').pop()
    // let Doc4_AgeProofImg1  =this.Doc4_AgeProof_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ Doc4_AgeProofImg;
    //   form.append("Doc4_AgeProof", this.Doc4_AgeProof_img[0], Doc4_AgeProofImg1)
      
    // }

    //  if (this.Doc5_IDproof_name != null) {
    //    let Doc5_IDproofImg = this.Doc5_IDproof_img[0].name.split('.').pop()
    // let Doc5_IDproofImg1  =this.Doc5_IDproof_img[0].name.split('.').slice(0, -1).join('.') + new Date() +'.'+ Doc5_IDproofImg;
    //   form.append("Doc5_IDproof", this.Doc5_IDproof_img[0], Doc5_IDproofImg1)
      
    // }

   // form.append("ApplicationNo", myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+timesplit[0]+timesplit[1]+Math.floor(10000 + Math.random() * 90000))
   // form.append("ApplicationNo", myresultsplit[2]+myresultsplit[1]+myresultsplit[0]+h+m+Math.floor(10000 + Math.random() * 90000))
    form.append("ApplicationNo", myresultsplitlast2+myresultsplit[1]+myresultsplit[0]+Math.floor(10 + Math.random() * 90))
    form.append("Name", this.applyoutletForm.get('Name')?.value)
    form.append("ContactNumber", this.applyoutletForm.get('ContactNumber')?.value)
    form.append("Email", this.applyoutletForm.get('Email')?.value)
   // form.append("NameofPlotOwner", this.applyoutletForm.get('NameofPlotOwner')?.value)
    //form.append("RelationshipofAppOwner", this.applyoutletForm.get('RelationshipofAppOwner')?.value)
    form.append("Addressofplot", this.applyoutletForm.get('Addressofplot')?.value)
    form.append("SurveyNo", this.applyoutletForm.get('SurveyNo')?.value)
    form.append("District", this.applyoutletForm.get('District')?.value)
   // form.append("PinCode", this.applyoutletForm.get('PinCode')?.value)
    form.append("AreaofthePlot", this.applyoutletForm.get('AreaofthePlot')?.value)
    //form.append("Frontageoftheplot", this.applyoutletForm.get('Frontageoftheplot')?.value)
    //form.append("Depth", this.applyoutletForm.get('Depth')?.value)
    form.append("Latitude", this.applyoutletForm.get('Latitude')?.value)
    form.append("Longitude", this.applyoutletForm.get('Longitude')?.value)
   // form.append("NameofRoad", this.applyoutletForm.get('NameofRoad')?.value)
   // form.append("Plot", this.applyoutletForm.get('Plot')?.value)
    form.append("Additionalremarks", this.applyoutletForm.get('Additionalremarks')?.value)
    form.append("Ownership_of_Land", this.applyoutletForm.get('Ownership_of_Land')?.value)
    form.append("Correspondence_Address", this.applyoutletForm.get('Correspondence_Address')?.value)
     form.append("form_status", "EOI Submitted")
    // form.append("Doc1_Propertycard", this.files[0], this.files[0].name)
    // form.append("Doc2_subleases", this.files[0], this.files[0].name)
    // form.append("Doc3_Photographsofland", this.files[0], this.files[0].name)
    // form.append("Doc4_AgeProof", this.files[0], this.files[0].name)
    // form.append("Doc5_IDproof", this.files[0], this.files[0].name)
    this.service.insertapplyforoutlet(form).subscribe(response=>{
      let data = JSON.parse(JSON.stringify(response))
      this.service.ApplicationNo =data
      //console.log(data)
      if(data){
        this.alertType='submit'
        this.alertMsg = "Submitted"
        this.alertColor = 'rgba(4, 183, 107, 0.2)'
        this.alertTextColor = '#038b51'
        this.alertBorder = '1px solid #04a862;'
        setTimeout(() => {
          this.alertType=''
          this.applyoutletForm.reset()
          this.submitted = false
          this.router.navigate(['/thank-you-outlet/'+data])  
        }, 1500);
      }
    })
    
    
  }




  reset(){
  this.applyoutletForm.reset()
   this.submitted=false

  }
}