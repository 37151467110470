<!-- <div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/awards-banner.jpg">
  <div class="banner-heading">
    <h1>Awards</h1>
    <div class="breadcrumbs">
      <ul class="">
        <li class=""><a [routerLink]="['/']">Home</a></li>
        <li class=""><a >MGL Corporate</a></li>
        <li class="active" aria-current="page"><a >Awards</a></li>
      </ul>
    </div>
  </div>
</div>
<app-service-section></app-service-section>

<div class="innerpage-content-section">
  <div class="container custom">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12">
        <app-recent-post></app-recent-post>

        <app-tips></app-tips>

        <div class="mt-30">

          <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
            mahanagargas</a>

        </div>
      </div>

      <div class="col-lg-9 col-md-9 col-sm-12">
        <div class="innerpage-right-content awards-section">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards1.png"></div>
              <p>Financial Express CFO Awards 2018</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards2.png"></div>
              <p>Social Change Award 2018 by Govt. of India</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards3.png"></div>
              <p>Yes Bank - Business World Best CFO Award 2018</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards4.png"></div>
              <p>Golden Tigers Award 2017</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards5.png"></div>
              <p>Innov Awars 2017 in Gold Category</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards1.png"></div>
              <p>Financial Express CFO Awards 2018</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards2.png"></div>
              <p>Social Change Award 2018 by Govt. of India</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards3.png"></div>
              <p>Yes Bank - Business World Best CFO Award 2018</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards4.png"></div>
              <p>Golden Tigers Award 2017</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards5.png"></div>
              <p>Innov Awars 2017 in Gold Category</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards1.png"></div>
              <p>Financial Express CFO Awards 2018</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards2.png"></div>
              <p>Social Change Award 2018 by Govt. of India</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards3.png"></div>
              <p>Yes Bank - Business World Best CFO Award 2018</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards4.png"></div>
              <p>Golden Tigers Award 2017</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="awardsimg"><img src="assets/images/awards/awards5.png"></div>
              <p>Innov Awars 2017 in Gold Category</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div class="innerpage-banner">
  <img src="assets/images/innerpage-banner/awards-banner.jpg">
  <div class="banner-heading">
      <h1>Awards</h1>
      <div class="breadcrumbs">
          <ul class="">
              <li class=""><a [routerLink]="['/']">Home</a></li>
              <li class=""><a >MGL Corporate</a></li>
              <li class="active" aria-current="page"><a >Awards</a></li>
          </ul>
      </div>
  </div>
  

    
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
  <div class="container custom">
      <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
              <app-recent-post></app-recent-post>

              <app-tips></app-tips>

              <app-insta-post></app-insta-post>

             <!--  <div class="mt-30">

                  <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                      mahanagargas</a>

              </div> -->
          </div>

          <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
              <div class="innerpage-right-content news-section-page rs-blog blog-main-home">
                  <div class="row">


<div class="tab">
<button *ngFor="let i of allawards" class="tablinks" (click)="openCity($event, i)">{{i}}</button>


</div>

<!-- <div *ngFor="let i of allNews" id="{{i}}" class="tabcontent">

  

</div> -->

<div *ngFor="let k of filteredAwards" class="col-lg-4 col-md-4 col-sm-12 mb-30">
  <div class="blog-item">
      <div class="image-wrap">
          <a *ngIf="k.Image"><img src="{{base}}{{k.Image}}" class="w-100"></a>
      </div>
      <div class="blog-content">
      <!--  <ul class="blog-meta">
          <li class="date">{{k.Year}}</li>
      </ul>-->
          
               <!-- <p class="font-size11 mb-0">  {{k.Year}}</p> -->
               <h3 class="blog-title"><a>{{k.Title}}</a></h3> 
        
                  
          <!-- <div class="blog-btn">
              <a class="readon consultant blog" routerLink="/MGL-corporate/media/news/{{k._id}}">Read More</a>
          </div> -->

          <div class="blog-btn">
            <a class="readon consultant blog" routerLink="/MGL-corporate/award-details/{{k._id}}">Read More</a>
        </div>
      </div>
  </div>
</div>
             
           </div>
              </div>
          </div>
      </div>
  </div>
  <!-- Inner Page Content Section End -->
</div>

