  
<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/login-banner.jpg">
    <div class="banner-heading">
        <h1>Current <span>Bill</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Customer Login</a></li>
                <li class=""><a [routerLink]="['/user-dashboard']">Customer Dashboard</a></li>
                <li class="active" aria-current="page">Current Bill</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
      <div id="this" *ngIf="!isLoad" class="text-center">
                Please Wait, We are calculating your bill...
            </div>
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section">
                    <app-dashboard-info-card></app-dashboard-info-card>
                </div>
            </div>

            
            <div id="this" class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">

            <div class="md-mt-30 bill-current-recipt">
                    <div class="print_box3" *ngIf="isLoad">
                        <a  class="print"><button style="background: transparent; border: transparent;" (click)="printPdf()">Printer Friendly Version</button></a>
                     
                    </div>
                    <div style="margin:0 auto; width:90%; font-family:Arial; font-size:12px; border-bottom:5px solid #fbc78c; position:relative;">
<div style="margin-bottom:0px">
<table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td valign="top" width="65%">
        <div style="display:flex; border:2px solid #6877b4; padding:4px 10px; border-radius: 8px;">
    <a href="#"><img src="../../../assets/images/currbillimgnew/logo.png" style="height:70px"></a>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td align="center" style="font-size:12px">Tax Invoice</td>
    </tr>
    <tr>
      <td align="center" style="font-size:20px; color:#0069B3; font-weight:bold">MAHANAGAR GAS LIMITED</td>
    </tr>
    <tr>
      <td align="center" style="font-size:12px; color:#0F9400">An ISO 9001, 14001 and 45001 Certified Company</td>
    </tr>
    <tr>
      <td align="center" style="font-size:12px;color:#0069B3;">www.mahanagargas.com</td>
    </tr>
  </tbody>
</table>

    </div>
        </td>
      <td>&nbsp;</td>
      <td width="35%">
          <table width="100%" border="0" cellspacing="0" cellpadding="5" style="font-size: 12px; font-weight:600">
        <tbody>
          <tr>
            <td style="background:#c5e5ee; padding: 6px 0 6px 10px;border-bottom:2px solid #FFF; border-radius:8px 0 0 0;-webkit-print-color-adjust: exact; ">CA Number</td>
            <td style="background:#c5e5ee; padding: 6px 0 6px 10px;border-bottom:2px solid #FFF; border-radius:0 8px 0 0;-webkit-print-color-adjust: exact;">{{billData.contract_acc}}</td>
          </tr>
          <tr>
            <td style="background: #b6d7a4;padding:6px 0 6px 10px;border-bottom:2px solid #FFF;-webkit-print-color-adjust: exact; text-transform: uppercase;">Bill Date</td>
            <td style="background: #b6d7a4;padding: 6px 0 6px 10px;border-bottom:2px solid #FFF;-webkit-print-color-adjust: exact;">{{billData.bill_date}}</td>
          </tr>
          <tr>
            <td style="background: #fbc78c;padding:6px 0 6px 10px; border-radius:0 0 0 8px;-webkit-print-color-adjust: exact;text-transform: uppercase">Period</td>
            <td style="background: #fbc78c;padding:6px 0 6px 10px; border-radius:0 0 8px 0;-webkit-print-color-adjust: exact;">{{previousDate}} to {{closingDate}}</td>
          </tr>
          
        </tbody>
      </table>
        </td>
    </tr>
    <tr>
      <td valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin:0 0 0 0">
        <tbody>
          <tr>
            <td valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #f190a0; border-radius:6px; padding: 5px; ">
              <tbody>
                <tr>
                  <td align="center"><h2 style="margin: 0 0; font-size: 12px">Emergency</h2></td>
                </tr>
                <tr>
                  <td align="center">
                    <p style="margin:5px 0; font-size:9px;">(Gas Leak, Gas Stop, Fire)</p>
                    <h5 style="color:#e80717; font-size: 10px; margin: 5px 0">18002669944 (Tollfree)<br>
                      (022)-68759400,<br>
                      (022)-24012400</h5>
                    <p style="margin: 5px 0 0">(Available 24X7)</p></td>
                </tr>
              </tbody>
            </table></td>
            <td width="10">&nbsp;</td>
            <td valign="top"><div style="border:2px solid #6877b4; border-radius: 6px; padding:5px 10px; min-height:86px">
              <h2 style="font-size: 11px; line-height: 13px;color: #000; text-transform: uppercase; padding: 0 2px 0 4px; margin: 0;">{{billData.name}}</h2>
              <p style="font-size: 11px; line-height: 13px;  color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">{{details}}
                </p>
              <p style="font-size: 11px; line-height: 13px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;"> {{flat}}</p>
              <p style="font-size: 11px; line-height: 13px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">{{landmark}}</p>
              <p style="font-size: 11px; line-height: 13px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">Mobile No: {{mobile}}, Email Id:{{email}}</p>
              <p style="font-size: 11px; line-height: 13px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">BP No:{{billData.partner_no}}</p>
            </div></td>
          </tr>
        </tbody>
      </table></td>
      <td>&nbsp;</td>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td>
                <div style="margin:0 auto; width: 65px; height: 65px; background:#FBC78C; border-radius:65px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact;">
                <h2 style="margin:0 0 2px; font-weight: 800;font-size: 11px; ">{{uptoTotal}}</h2>
                <span style="font-size:10px">Amount upto</span>
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width: 75%; margin:-8px auto 0; border-radius: 40px;font-size: 12px; padding: 10px 5px 7px; text-align: center;-webkit-print-color-adjust: exact">{{dueDate}}</div>
              </td>
            <td>&nbsp;</td>
            <td>
              <div style="margin:0 auto; width: 65px; height: 65px; background:#FBC78C; border-radius:65px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
                <h2 style="margin:0 0 2px; font-weight: 800;font-size: 11px;">{{uptodueAmt}}</h2>
                <span style="font-size:10px">Amount after</span>
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width:75%; margin:-8px auto 0; border-radius: 40px;font-size: 12px; padding: 10px 5px 7px; text-align: center;-webkit-print-color-adjust: exact">{{dueDate}}</div>
              </td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table>
</div>  
    <div style="margin-bottom:0px">
        <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="49%" align="left" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="3" style="border:2px solid #6877b4; border-radius: 6px; ">
                    <tbody>
                      <tr>
                          <td colspan="3"  style="background:#c5e5ee;-webkit-print-color-adjust: exact; border-radius:4px 0 4px 0;">
                            <div style="display: flex;justify-content: space-between;">
                              <span>MVAT Invoice No</span>
                          <span><strong> {{billData.TAX_INVNO}} </strong></span>
                                </div>
                          </td>
                        </tr>
                      
                      <tr>
                        <td style="background:#fee8d4;-webkit-print-color-adjust: exact;"><strong>SALE OF NATURAL GAS</strong></td>
                        <td style="border-right:1px solid #6877b4; background:#fee8d4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" style="background:#fee8d4;-webkit-print-color-adjust: exact; padding-right: 20px"><strong><strong></strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>Gas Consumption SCM <strong></strong></strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;  ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{billData.cur_cons}}
                        </strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>Gas Consumption Charges @ Rate Per<strong> SCM {{billData.rate}}</strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{billData.cons}}</strong></strong></td>
                      </tr>
                        <tr>
                        <td bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;"><strong>MVAT @3% <strong></strong></strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{billData.vat}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>Arrears<strong></strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>
                        {{str_arrears_emi.toFixed(2)}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;"><strong>Credit Balance / Discount / Rebate</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>
                        {{v_creditbalance.toFixed(2)}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FDDDBC"  style="-webkit-print-color-adjust: exact;"><strong>TOTAL CHARGES A</strong></td>
                        <td bgcolor="#FDDDBC" style="border-right:1px solid #6877b4; -webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact; padding-right: 20px"><strong><strong>{{totalPartA.toFixed(2)}}</strong></strong></td>
                      </tr>
                       <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>GST Invoice No. {{billData.bill_no}}</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px"></td>
                      </tr>
                     
                        <tr>
                        <td bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;"><strong>Other Charges</strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;padding-right: 20px">{{otherCharges.toFixed(2)}}</td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>Minimum Charges</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px">{{billData.min_chg}}</td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>SGST @9%<strong></strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{v_SGST}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>CGST @9%</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>{{v_CGST}}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact;"><strong>TOTAL CHARGES B</strong></td>
                        <td bgcolor="#FDDDBC" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong>{{vPartB.toFixed(2)}}</strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FBC78C" style="-webkit-print-color-adjust: exact;"><strong>TOTAL PAYABLE (A+B)<strong></strong></strong></td>
                        <td bgcolor="#FBC78C" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FBC78C" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong>{{abTotal.toFixed(1)}}</strong></td>
                      </tr>
                      
                      
              </tbody>
                  </table>
        </td>
    </tr>
    <tr>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="3" style="border-radius: 4px; border:2px solid #6877b4;">
        <tbody>
          <tr>
            <td style="border-bottom: 2px solid #6877b4"><strong>Gas Consumption Security Deposit Rs.{{billData.EMI_CLEARED}}</strong></td>
          </tr>
          <tr>
            <td>{{str5000}}</td>
          </tr>
         
        </tbody>
      </table></td>
    </tr>
    <tr>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td>
                <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
                <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">Meter No.</h2>
                
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">{{billData.meter_no}}</div></td>
            <td>&nbsp;</td>
            <td>
              <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
                  <span style="font-size:8px">Previous Reading </span>
                <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">{{previoseReading}}</h2>
                <span style="font-size:11px">{{assessedActual}}</span>
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">{{previousDate}}</div>
              </td>
            <td>&nbsp;</td>
            <td> <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
                  <span style="font-size:8px">Closing Reading</span>
                <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">{{closingReading}}</h2>
                <span style="font-size:11px">{{closingActual}}</span>
                </div>
                <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">{{closingDate}}</div></td>
          </tr>
        </tbody>
      </table></td>
    </tr>
      <tr>
        <td>
          <div id="canvasdivid">
                        <canvas id="canvas">{{chart}}</canvas>
                      </div>
        </td>
     <!--  <td><img src="../../../assets/images/currbillimgnew/graph.jpg" height="120"></td> -->
    </tr>
  </tbody>
</table>

        </td>
      
      <td width="49%" align="left" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid  #6877b4; border-radius: 6px">
              <tbody>
                <tr>
                  <td height="25" align="left" style="background:#c5e5ee; color:#000; font-size: 12px; -webkit-print-color-adjust: exact; padding-left:15px; font-weight:800">IMPORTANT INFORMATION</td>
                </tr>
                <tr>
                  <td style="padding:5px 10px; position: relative">
                    <!-- <p style="font-size:11px;margin:0 0 3px">Opt for paperless Invoice by sending SMS to 922555557 "No Hard Copy"</p> -->
                    <p [innerHTML]="important1"></p>
                     <p *ngIf="firstMessageFlag==true">
                                                Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill
                                            </p>

                                            <p *ngIf="secondMessageFlag==true">
                                                We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill
                                            </p>
                     <!--  <p style="font-size:11px; margin:0 0 3px">For registering your E-mail Id, please visit https://www.mahanagargas.com/- Billing/updatecontactnumber.aspx.</p> -->
                     
                      <div style="position:absolute; left: 50%; top:50%; transform: translate(-50%,-50%); opacity:0.1; z-index: -1 ">
                      <img src="../../../assets/images/currbillimgnew/mg-icon.png" style="width: 100%">
                      </div>
                    </td>
                </tr>
              </tbody>
            </table></td>
          </tr>
          
          <tr>
            <td>
              <table width="100%" border="0" cellspacing="0" cellpadding="5" style="border:2px dashed #6877b4; border-radius: 4px; margin-top: 5px">
              <tbody>
                <tr>
                  <td width="51%" align="center" *ngIf="meterImg">
                    <img src="{{meterImg}}" width="100">
                    </td>
                  <td width="49%" align="center" *ngIf="meterImgTxt"><strong [innerHTML]="meterImgTxt"></strong></td>
                </tr>
              </tbody>
            </table>
              </td>
          </tr>
          
          <tr>
            <td>
              <div style="width:auto; border: 2px solid #6877b4; border-radius: 6px; margin-top:8px;">
                  <p style="background:#c5e5ee; color:#000; font-size: 12px; padding: 5px 5px 5px 15px; margin: 0;-webkit-print-color-adjust: exact;font-weight:800;">Declaration</p>
                  <div style="padding:8px">
                  <p style="margin: 0 0 5px"><strong>It is hereby certified that Registration Certificate of the Company issued under Maharashtra Value Added Tax Act, 2002 is in force as on date and that the transaction of sale covered under this Tax Invoice shall be accounted for in the Turnover of Sales while filing of Returns and tax payable on the sale, if any, has been paid or shall be paid.</strong></p>
                      <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td><p style="font-size: 14px; font-weight:600; margin: 0"><strong>For Mahanagar Gas Ltd</strong></p>
                      <img src="../../../assets/images/currbillimgnew/chief-manager-sign1.jpg">
                  <p style="font-size: 12px">Chief manager - Revenue & Taxation</p></td>
      <td align="right"> <img src="../../../assets/images/currbillimgnew/stamp.jpg" width="120"></td>
    </tr>
  </tbody>
</table>

                  
                      </div>
              </div>
              </td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table>

  </div>
  <!-- <div class="p-2"><img src="../../../assets/images/currbillimgnew/MGL_Tez_PNG_Bill_Front_19.5x3.5-01.jpg" style="width:100%; border-radius: 8px;"></div>   -->
  <div class="p-2"><img src="../../../assets/images/currbillimgnew/mgl-ganpati-ad-img.jpg" style="width:100%; border-radius: 8px;border: 2px solid #6877b4; border-radius: 6px;"></div>

    <!-- <div style="border-radius: 6px;margin-bottom: 5px; height: 180px; border:2px solid #6877b4;">
      <p style="line-height:23px; font-size: 11px; padding: 5px 5px 5px 15px; margin: 0;-webkit-print-color-adjust: exact;">
        We are excited to present our PNG bill in a new format. We at MGL, constantly endeavour to provide unmatched convenience while maintaining simplicity.<br> 
This changed format will provide you with a cleaner presentation of your account & past consumption along with multiple and easy payment options.<br>
One more important detail about the new format. A young student and budding artist Akash Sthool from Sir J. J. Institute of Applied Arts has designed the format.<br>
So do tell us about the change as well as our services at support @ mahanagargas.com or <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="14" style="margin:0px"> 9899 20 3843<br>
Thanking you once again for the opportunity to serve you.
      </p>
      <div style="text-align:right;font-weight:bold;margin: 10px;">Team MGL</div>
    </div> -->
  <div style="margin-bottom:0px; border-top:2px dashed #000;padding:5px 0 0">
        <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td width="60%" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #6877b4; border-radius: 6px">
                    <tbody>
                      <tr>
                        <td height="20" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 12px; font-weight:800">
                          PAYMENT SLIP/CHEQUE
                        </td>
                      </tr>
                      <tr>
                        <td >
                            <table width="100%" border="0" cellspacing="0" cellpadding="3">
  <tbody>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 2px 2px 0"><strong>Cheque No:</strong></td>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong> Cheque Dt:</strong></td>
    </tr>
    <tr>
      <td colspan="2" style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong>Bank Name/ branch:</strong></td>
      </tr>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 2px 2px 0"><strong>CA:{{billData.contract_acc}}</strong></td>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong>Due date:{{billData.due_date}}</strong></td>
    </tr>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0" colspan="2"><strong>Amount Paid:{{uptoTotal}}</strong></td>
      </tr>
    <tr>
      <td colspan="2" style="font-size:10px">Please pay by cheque in favour of Mahanagar Gas Ltd CA No. {{billData.contract_acc}}</td>
      </tr>
  </tbody>
</table>

                        </td>
                      </tr>
                    </tbody>
                  </table>
        
        </td>
      <td>&nbsp;</td>
      <td width="40%" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #6877b4; border-radius: 6px">
        <tbody>
          <tr>
            <td height="20" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 12px; font-weight:800"> Pay through QR (Quick Response) Code </td>
          </tr>
          <tr>
            <td ><table width="100%" border="0" cellspacing="0" cellpadding="5" style="height:96px">
              <tbody>
                <tr>
                  <td width="50%" align="center" valign="middle" >
                      <div style="display:flex; align-items: center;justify-content: space-around;">
                      <img src="{{qrdata_upi}}" style="height:90px;">
                      <img src="../../../assets/images/currbillimgnew/upi-logo.jpg" style="height:20px;">
                      </div>
                    </td>
                 <!--  <td align="center" valign="middle" >
                    <div style="display:flex; align-items: center;justify-content: space-around;">
                      <img src="{{qrdata_paytm}}" style="height:90px;">
                      <img src="../../../assets/images/currbillimgnew/paytm-logo.jpg" style="height:20px;">
                    </div>
                    </td> -->
                </tr>
              </tbody>
            </table></td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table>

    </div>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td align="center" style="font-size: 16px; font-weight: 800">Customer Care - 24x7</td>
    </tr>
    <tr>
      <td align="center" style="display:flex; font-size: 14px;justify-content: center;align-items: center;"><img src="../../../assets/images/currbillimgnew/call-img.jpg" width="20" style="margin:0 10px">(022) 6867 4500 &amp; 6156 4500 <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="20" style="margin:0 10px">9899 20 3843 </td>
    </tr>
    <tr>
      <td align="center">
          <h4 style="background:#c5e5ee; padding:6px 0; color:#000; font-size:11px; text-align: center; margin:5px 0;-webkit-print-color-adjust: exact; font-weight: normal">
              CIN #L40200MH1995PLC088133 | VAT # 27390000364V | C.S.T #27390000364C | GSTIN :27AABCM4640G1ZN |PLACE OF SUPPLY : MAHARASHTRA (27)</h4></td>
    </tr>
  </tbody>
</table>
<div style="position:absolute; left:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
    <div style="position:absolute; right:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
</div>
<div style="margin:20px auto 0; width:90%; font-family:Arial; font-size:12px; border-bottom:5px solid #fbc78c; padding: 10px 30px; position: relative">
    <div style="position:absolute; left:0; top: 0; z-index: -1;transform: rotateX(180deg);"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
    <div style="position:absolute; right:0; top: 0; z-index: -1;transform: rotateX(180deg);"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
    <p style="margin-bottom:3px; text-align: center">Subject To Mumbai Juisdiction</p>
    <table width="100%" border="0" cellspacing="0" cellpadding="2">
      <tbody>
        <tr>
          <td width="59%"><img src="../../../assets/images/currbillimgnew/if-you-smell1.png" style="width:100%"></td>
          <td>&nbsp;</td>
          <td valign="top">
            <div style="border:2px solid #6877b4; border-radius: 6px;margin:35px 0 0 0">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td height="25" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 12px; font-weight: 800">
                          Best Safety Practices
                        </td>
                      </tr>
                      <tr>
                        <td>
                            <ul style="padding:24px 10px">
            <li style="margin-bottom:8px">Ensure Appliance Valve / Meter Control Valve is closed when not using Gas.</li>
            <li style="margin-bottom:8px">Keep windows open and allow air circulation inside Kitchen to facilitate ventilation</li>
            <li style="margin-bottom:8px">Electric installation / Wiring should never touch or traverse Gas line. Please maintain a clear distance of at least 01 foot (300mm).</li>
            <li style="margin-bottom:8px"> Do not conceal Gas Pipes in permanent or temporary structures.</li>
             <li > Gas Geyser must not be installed inside bathrooms or closed areas.</li>
            </ul>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
          </td>
        </tr>
      </tbody>
  </table>
    <div style="margin:0 0 0">
    <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="50%" valign="top">
          <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td><div style="min-height: 140px;border: 2px solid #6877b4; border-radius: 6px;">
          <!-- <img src="../../../assets/images/currbillimgnew/addnew.png">  
          <img src="../../../assets/images/currbillimgnew/draft-electoral-roll.jpg"> -->
          <img src="../../../assets/images/currbillimgnew/mgl-tez-2page.png"> 

      </div></td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>
        <div style="border:2px solid #6877b4; border-radius:4px; min-height: 140px; position: relative">
          <h2 style="margin:8px 5px 5px 85px; position: relative; font-size:14px">Dear Consumer, 
              <i style="position: absolute; left:-60px; top:-28px; border:2px solid #24388e; width: 50px; height: 50px; display: flex; border-radius: 50px; background: #FFF;align-items: center;
    justify-content: center;"><img src="../../../assets/images/currbillimgnew/user-icon.jpg"></i>
            </h2>
            <ul  style="padding:10px 20px">
            <li  style="margin-bottom:6px">Invoice is raised once in two months on the basis of meter reading taken by MGL meter reader or meter Reading forwarded by yourself.</li>
                <li  style="margin-bottom:6px">We allow a Credit Period of 20 days to enable you to make payment. If you do not pay your bill by Due Date, Late Payment Charges of Rs. 100/- shall be levied.</li>
                <li  style="margin-bottom:6px"> Continued default in making payment will attract additional Delayed Payment Charges@18% p.a. on unpaid value (beyond 9 days of delay) w.e.f. Due Date.</li>
                <li  style="margin-bottom:6px">In the event of continued default, MGL reserves the right to disconnect the supply. Reconnection shall be done only upon payment of Outstanding, (Including Late
Payment charges, interest on delayed payment), disconnection & reconnection charges.</li>
                <li  style="margin-bottom:6px">Any dispute in invoice should be brought to our notice within 14 days of the Bill Date.</li>
                <li  style="margin-bottom:6px">If you do not consume PNG equivalent to Rs. 100/- in a Bimonthly Billing Period, the shortfall amount will be levied in addition to the Gas Consumption Charges.</li>
                <li  style="margin-bottom:6px">We forward the copy of the Bill by SMS as well as e-mail on demand.</li>
                <li style="margin-bottom:6px">Piped Natural Gas (PNG) attracts MVAT and all other sale and supply of Goods and Services attract GST.</li>
                 <li>PNG (Domestic) price break-up is available on our website.</li>
            </ul>
            <div style="position:absolute; left: 50%; top:50%; transform: translate(-50%,-50%); opacity:0.1; z-index: -1; width: 90% ">
                      <img src="../../../assets/images/currbillimgnew/mg-icon.png" style="width: 100%">
                      </div>
          </div>
        </td>
    </tr>
  </tbody>
</table>
</td>
      <td width="5">&nbsp;</td>
      <td width="49%" align="left" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td>
        <div style="border:2px solid #6877b4; border-radius: 6px; ">
                <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight: 800; line-height:13px">Your Nearest Walk in center (10:00 AM to 5:00 PM)  <span style="font-size: 11px; font-weight:normal;">Monday to Saturday except Public &amp; Bank Holidays</span></p>
                    <div style="padding:5px 10px">
                    <p style="margin:0 0 2px 0;line-height:13px">{{walkincenterData}}</p>
                        
                        </div>
                </div>
        </td>
    </tr>
    <tr>
      <td><div style="border:2px solid #6877b4; border-radius: 6px; ">
        <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight:800;">For Unresolved Issues, Please Contact</p>
        <div style="padding:5px 10px">
          <p style="margin:0 0 2px 0; line-height:15px">You may contact the officials between 3:00 pm to 5:00 pm from Monday to Friday (Except Public & Bank Holidays) with the Unique Docket number given at the time of registering your complaint.</p>
          <p  style="margin:0 0 2px 0;line-height:15px"><strong>Nodal Office:</strong><br> Mahanagar Gas Ltd, Pay & Accounts Building, Ground Floor, Near Family Court, Bandra Kurla Complex, Bandra (East), Mumbai-400051.</p>
          <p  style="margin:0 0 2px 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Telephone Number :</strong> 022-6156 4555</p>
          <p  style="margin:0 0 2px 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Complaint in-charge:</strong> Mr. Karan Mhole - Ext. No. 4549</p>
          <p  style="margin:0 0 2px 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Nodal Officer :</strong> Mr. Vikas Sarmalkar - Ext. No. 4548</p>
            <p  style="margin:0 0 2px 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Appellate Authority :</strong> Mr. Paresh Chanpur - Ext. No. 4535</p>
            <p  style="margin:0 0 0;line-height:15px"><strong style="display: inline-block; width: 120px;">Email ID :</strong>  support@mahanagargas.com</p>
        </div>
      </div></td>
    </tr>
    <tr>
      <td><div style="border:2px solid #6877b4; border-radius: 6px; ">
        <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight: 800;">
         CONNECT <i>for</i> CONVENIENCE</p>
        <div style="padding:10px">
          <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="48%" align="center">
          <p style="margin:0 0 5px 0;font-size:11px">MGL CONNECT APP</p>
          <img src="../../../assets/images/currbillimgnew/qrcode_mglapp.png" width="90">
          <p style="margin:5px 0 0 0">Scan the QR Code to download the MGL CONNECT APP</p>
        </td>
      <td>&nbsp;</td>
      <td width="50%" align="center">
          <p style="margin:0 0 5px 0; font-size:11px">GENERATE YOUR OWN BILL</p>
          <img src="../../../assets/images/currbillimgnew/generate-your-own-bill.jpg" width="90">
          <p style="margin:5px 0 0 0">Scan the QR Code to<br>
            GENERATE YOUR OWN BILL</p>
        </td>
    </tr>
  </tbody>
</table>

        </div>
      </div></td>
    </tr>
    
  </tbody>
</table>
</td>
    </tr>
  </tbody>
</table>

    </div>
    
    <div style="margin:0 0">
         <div style="background:#b6d7a4; color:#000; font-size: 13px; font-weight: 800; -webkit-print-color-adjust: exact; padding:3px 0; text-align:center;">Easy to Pay your Gas Bills here</div>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #0f70b7; border-radius: 6px; margin-top: 4px">
                    <tbody>
                      
                      <tr>
                        <td>
                        <table cellspacing="0" cellpadding="4" border="0" align="center" style="font-size: 11px;">
                                        <tbody>
                                            <tr>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0; font-size: 10px;-webkit-print-color-adjust: exact; font-weight: 800; color:#0c4d7d;">Digital</td>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0;font-size: 10px;-webkit-print-color-adjust: exact; font-weight: 800;color:#0c4d7d;">Across the Counter</td>
                                                <td width="52%" colspan="3" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0; font-size: 10px;-webkit-print-color-adjust: exact;font-weight: 800;color:#0c4d7d;">RTGS/NEFT
                                                </td>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 0 1px 0;font-size: 10px;-webkit-print-color-adjust: exact;font-weight: 800;color:#0c4d7d;">E-NACH</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="5" valign="top" style="border:1px solid #0f70b7; border-width:0 1px 0 0">
                                                    <strong>Online Payments</strong>
                                                    <p>Pay By Net Banking, Cards, UPI, BillDesk <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="11" style="margin-top:0px"></p>
                                                    
                                                </td>
                                                <td rowspan="5" align="left" valign="top" style="border:1px solid #0f70b7; border-width:0 1px 1px 0">
                                                    <strong>Post office location:</strong>Mumbai, Mira Road,<br>Thane <br><br>
                                                    <strong>Drop Box</strong>
                                                    ICICI Bank, Axis Bank <br><br>
                          Outlets of Suvidhaa, Paypoint, Euronet
                                                </td>
                                                <td width="95" style="white-space: nowrap; border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Bank Name</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0" width="122"><strong>YES Bank Ltd.</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0" width="120"><strong>State Bank of India</strong></td>
                                                <td rowspan="5" valign="top" style="border:1px solid #0f70b7; border-width:0 0 1px 0">Please avail 1% Discount on Gas Consumption Charges subject to
                                                    maximum of Rs. 50/-</td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">Account no.</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0">MGL999 {{billData.contract_acc}}</td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0">MGL1 {{billData.contract_acc}}</td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">IFSC</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>YESB 0CM SNOC</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>SBIN 000 4266</strong></td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">Type of Account</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Current Account</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Current Account</strong></td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong style="white-space: nowrap">Branch:</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong>YES BANK, MUMBAI</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong>STATE BANK OF INDIA, MUMBAI</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                       
                          </td>
                      </tr>
                    </tbody>
                  </table>
        <p style="margin:5px 0 0; text-align: center">Corporate & Registered Office: MGL House, G-33, Bandra Kurla Complex, Bandra(East), Mumbai-400 051</p>
    </div>
    <div style="position:absolute; left:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
    <div style="position:absolute; right:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
                </div>
               <!--  <div class="md-mt-30">
                    <div class="print_box3" ><a  class="print"><button style="background: transparent; border: transparent;" (click)="printPdf()">Printer Friendly Version</button></a>
                     
                    </div>
                    <div class="bill-main" *ngIf='billData'>
                        <div class="tax-invoice" style="font-weight:bold">&nbsp;Record No: RRM RE 009</div>
                        <div class="mng-top-panel">
                            <div class="bill-box1">
                                <div class="bill-box1-a">
                                    <div class="bill-logo"><img src="assets/images/logo.png"></div>
                                    <div class="bill-box1-a-text">
                                        <h3>TAX INVOICE</h3>
                                        <h2>MAHANAGAR GAS LIMITED</h2>
                                        <p>www.mahanagargas.com</p>
                                        <h4>An ISO 9001, 14001, and 45001 Certified Company</h4>
                                    </div>
                                </div>
                                <div class="bill-box1-b">
                                    <h2>Emergency</h2>
                                    <p>(Gas Leak, Gas Stop, Fire)</p>
                                    <h5>18002669944 (Tollfree)<br>(022)-68759400,<br>(022)-24012400</h5>
                                    <h4>(Available 24X7)</h4>
                                </div>
                                <div class="bill-box1-c">
                                    <h2 id="ContentPlaceHolder1_lblConsumerName">{{billData.name}}</h2>
                                    <p id="ContentPlaceHolder1_lblDetail">{{details}}</p>
                                    <p id="ContentPlaceHolder1_lblFlat">{{flat}}</p>
                                    <p id="ContentPlaceHolder1_lblLandMark">{{landmark}}</p>
                                    <p id="ContentPlaceHolder1_lblCity">{{city}}</p>
                                    <p id="ContentPlaceHolder1_lblContactDetails">Mob: {{mobile}}</p>
                                    <p *ngIf="email" id="ContentPlaceHolder1_lblContactDetails">Email: {{email}}</p>
                                    <p id="ContentPlaceHolder1_lblBPno">BP No. {{billData.partner_no}}</p>
                                </div>
                            </div>
                            <div class="bill-box2">
                                <div class="bill-box2-a">
                                    <ul>
                                        <li><span>CA No</span><small
                                                id="ContentPlaceHolder1_lblCaNo_1">{{billData.contract_acc}}</small>
                                        </li>
                                        <li><span>Bill Date</span><small
                                                id="ContentPlaceHolder1_lblBill_Date_4">{{billData.bill_date}}</small>
                                        </li>
                                        <li><span>Period</span><small
                                                id="ContentPlaceHolder1_lblBill_Period">{{previousDate}} TO {{closingDate}}</small>
                                        </li>
                                    </ul>
                                </div>
                                <div class="bill-box2-b">
                                    <ul>
                                        <li>
                                            <article>
                                                <h2>Due Amount</h2>
                                                <strong
                                                    id="ContentPlaceHolder1_lblAmount_Payable_10">{{billData.amt_pybl}}</strong>
                                                <h2>Due Date</h2>
                                                <span
                                                    id="ContentPlaceHolder1_lblAmount_DueDate_11">{{dueDate}}</span>
                                            </article>
                                        </li>
                                        <li>
                                            <article>
                                                <h2>Amount After Due Date</h2>
                                                <strong id="ContentPlaceHolder1_lblAmountAfterdueDate">{{dueAmt}}</strong>
                                            </article>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="box-left49per">
                            <div class="BillDetailsNew">
                                <ul>
                                    <li><span>&nbsp;</span></li>
                                    <li class="heading">
                                        <div class="bdn_invoice_no">MVAT Invoice No</div>
                                        <div id="ContentPlaceHolder1_lblTaxInvoice" class="bdn_invoice_no_val">
                                            {{billData.TAX_INVNO}}</div>
                                    </li>
                                    <li class="heading"><strong>SALE OF NATURAL GAS</strong><big></big></li>
                                    <li><strong>Gas Consumption SCM <b></b></strong><big
                                            id="ContentPlaceHolder1_lblGas_Consumption_scm">{{billData.cur_cons}}</big>
                                    </li>
                                    <li><strong id="ContentPlaceHolder1_div_27nov_5" style="font-size:11px">Gas
                                            Consumption Charges @ Rate Per SCM {{billData.rate}}<b>₹ </b></strong><big
                                            id="ContentPlaceHolder1_lblGas_Consumption_44">{{billData.cons}}</big></li>
                                    <li><strong>MVAT @3% <b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblVat_45">{{billData.vat}}</big></li>
                                    <li><strong>Arrears<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblTotalArrears_50">{{str_arrears_emi.toFixed(2)}}</big></li>
                                    <li><strong>Credit Balance / Discount / Rebate<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblTotalCreditBlance_53">{{v_creditbalance.toFixed(2)}}</big>
                                    </li>
                                    <li class="heading"><strong>TOTAL CHARGES A<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblTotalCharges_PartA">{{totalPartA.toFixed(2)}}</big>
                                    </li>
                                    <li class="heading">
                                        <div class="bdn_invoice_no">GST Invoice No.</div>
                                        <div id="ContentPlaceHolder1_lblInvoiceNo" class="bdn_invoice_no_val">
                                            {{billData.bill_no}}</div>
                                    </li>
                                    <li><strong>Other Charges<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblAddlCopper_74">{{otherCharges.toFixed(2)}}</big></li>
                                    <li><strong>Minimum Charges<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblConsumptionShortfall_46">{{billData.min_chg}}</big></li>
                                    <li><strong>SGST @9%<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblsgst_46">{{v_SGST}}</big></li>
                                    <li><strong>CGST @9%<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblcgst_46">{{v_SGST}}</big></li>
                                    <li class="heading"><strong>TOTAL CHARGES B<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblTotalCharges_PartB">{{vPartB.toFixed(2)}}</big></li>
                                    <li class="heading2"><strong>TOTAL PAYABLE (A+B)<b>₹</b></strong><big
                                            id="ContentPlaceHolder1_lblArrears_67">{{abTotal.toFixed(2)}}</big></li>
                                </ul>
                            </div>
                            <div class="clear"></div>
                            <div class="BillDetails">
                                <ul>

                                    <li><b>Gas Consumption Security Deposit <span id="ContentPlaceHolder1_lblSecurity_68">₹ {{billData.EMI_CLEARED}}</span></b>
                                                <br><b>{{str5000}}</b>
                                        
                                        
                                    </li>
                                    
                                </ul>
                            </div>
                            <div class="clear"></div>
                           
                            <div class="readingDetails">
                                <div class="readingDetailsCol">
                                    <article>
                                        <h2>Meter No.</h2><br>
                                        <span id="ContentPlaceHolder1_lblMeterNo_60">{{billData.meter_no}}</span>
                                    </article>
                                </div>
                                <div class="readingDetailsCol">
                                    <article>
                                        <h2>Previous Reading</h2>
                                        <span
                                            id="ContentPlaceHolder1_lblPrevioseReading_61">{{previoseReading}}</span>
                                        <span id="ContentPlaceHolder1_lblAssessed_Actual_61">{{assessedActual}}</span>
                                        <h4>Date</h4>
                                        <span id="ContentPlaceHolder1_lblPreviousdate_62">{{previousDate}}</span>
                                    </article>
                                </div>
                                <div class="readingDetailsCol">
                                    <article>
                                        <h2>Closing Reading</h2>
                                        <span id="ContentPlaceHolder1_lblClosingReading_63">{{closingReading}}</span>
                                        <span id="ContentPlaceHolder1_lblAssessed_Actual_63">{{closingActual}}</span>
                                        <h4>Date</h4>
                                        <span id="ContentPlaceHolder1_lblClosingDate_64">{{closingDate}}</span>
                                    </article>
                                </div>
                            </div>
                            <div class="BillDetails2">

                                <ul>
                                    <li><strong
                                            style="font-weight:bold;text-align:center;width:100%;display:block;text-transform: capitalize;font-size: 14px;">Billing
                                            History</strong></li>
                                    <li>
                                        <strong>Graph</strong>
                                        <strong>Period</strong>
                                        <strong>SCM</strong>
                                        <strong>Type Of Reading</strong>
                                        <strong>Amount ₹</strong>
                                    </li>
                                    <li id="ContentPlaceHolder1_row2" style="height:40px">
                                        <strong id="ContentPlaceHolder1_lblGraph_1"><img *ngIf="historyIMG1" 
                                                src="assets/images/graph-img.jpg" alt="" [style.width.px]=billData.unithis1
                                                height="25px"></strong>
                                        <strong id="ContentPlaceHolder1_lblPeriod_1">{{historyDate1}}</strong>
                                        <strong id="ContentPlaceHolder1_lblScm_1">{{billData.unithis1}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblReadingType_1">{{billData.reading_type_history1}}</strong>
                                        <strong id="ContentPlaceHolder1_lblAmount_1">{{billData.amthis1}}</strong>
                                    </li>
                                    <li id="ContentPlaceHolder1_row3" style="height:40px">
                                        <strong id="ContentPlaceHolder1_lblGraph_2"><img *ngIf="historyIMG2" 
                                                src="assets/images/graph-img.jpg" alt="" [style.width.px]=billData.unithis2
                                                height="25px"></strong>
                                        <strong id="ContentPlaceHolder1_lblPeriod_2">{{historyDate2}}</strong>
                                        <strong id="ContentPlaceHolder1_lblScm_2">{{billData.unithis2}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblReadingType_2">{{billData.reading_type_history2}}</strong>
                                        <strong id="ContentPlaceHolder1_lblAmount_2">{{billData.amthis2}}</strong>
                                    </li>
                                    <li id="ContentPlaceHolder1_row4" style="height:40px">
                                        <strong id="ContentPlaceHolder1_lblGraph_3"><img *ngIf="historyIMG3" 
                                                src="assets/images/graph-img.jpg" alt="" [style.width.px]=billData.unithis3
                                                height="25px"></strong>
                                        <strong id="ContentPlaceHolder1_lblPeriod_3">{{historyDate3}}</strong>
                                        <strong id="ContentPlaceHolder1_lblScm_3">{{billData.unithis3}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblReadingType_3">{{billData.reading_type_history3}}</strong>
                                        <strong id="ContentPlaceHolder1_lblAmount_3">{{billData.amthis3}}</strong>
                                    </li>
                                    <li id="ContentPlaceHolder1_row5" style="height:40px">
                                        <strong id="ContentPlaceHolder1_lblGraph_4"><img *ngIf="historyIMG4" 
                                                src="assets/images/graph-img.jpg" alt="" [style.width.px]=billData.unit_history4_scm
                                                height="25px"></strong>
                                        <strong id="ContentPlaceHolder1_lblPeriod_4">{{historyDate4}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblScm_4">{{billData.unit_history4_scm}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblReadingType_4">{{billData.reading_type_history4}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblAmount_4">{{billData.amount_history4_scm}}</strong>
                                    </li>
                                    <li id="ContentPlaceHolder1_row6" style="height:40px">
                                        <strong id="ContentPlaceHolder1_lblGraph_5"><img *ngIf="historyIMG5" 
                                                src="assets/images/graph-img.jpg" alt="" [style.width.px]=billData.unit_history5_scm
                                                height="25px"></strong>
                                        <strong id="ContentPlaceHolder1_lblPeriod_5">{{historyDate5}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblScm_5">{{billData.unit_history5_scm}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblReadingType_5">{{billData.reading_type_history5}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblAmount_5">{{billData.amount_history5_scm}}</strong>
                                    </li>
                                    <li id="ContentPlaceHolder1_row7" style="height:40px">
                                        <strong id="ContentPlaceHolder1_lblGraph_6"><img *ngIf="historyIMG6"
                                                src="assets/images/graph-img.jpg" alt="" [style.width.px]=billData.unit_history6_scm
                                                height="25px"></strong>
                                        <strong id="ContentPlaceHolder1_lblPeriod_6">{{historyDate6}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblScm_6">{{billData.unit_history6_scm}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblReadingType_6">{{billData.reading_type_history6}}</strong>
                                        <strong
                                            id="ContentPlaceHolder1_lblAmount_6">{{billData.amount_history6_scm}}</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="box-right49per">
                            <div class="messages-box">
                                <div class="round-box">
                                    <span>Important Information</span>
                                    <small>
                                        <div id="ContentPlaceHolder1_divImpInfo" style="height:489px">
                                            
                                           
                                            <p [innerHTML]="important1"></p>

                                            <p *ngIf="firstMessageFlag==true">
                                                Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill
                                            </p>

                                            <p *ngIf="secondMessageFlag==true">
                                                We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill
                                            </p>
                                            
                                        </div>

                                    </small>
                                </div>
                            </div>
                            <div class="clear"></div>
                            <div class="messages-box2">
                                <div class="round-box">
                                    <small id="ContentPlaceHolder1_divmrnote" style="text-align:left;">
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td width="50%" align="center" valign="top" style="font-size:9px;" *ngIf="meterImg">
                                                        Meter Image : <br><img [src]="meterImg"
                                                            width="130px" height="90px"></td>
                                                    <td width="50%" align="center" valign="top" style="font-size:9px;" *ngIf="meterImgTxt" [innerHTML]="meterImgTxt">
                                                        </td>       
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </small>
                                </div>
                            </div>
                            <div class="messages-box3">
                                <div class="round-box declar">
                                    <small><strong>Declaration</strong>It is hereby certified that Registration
                                        Certificate of the Company issued under Maharashtra Value Added Tax Act, 2002 is
                                        in force as on date and that the transaction of sale covered under this Tax
                                        Invoice shall be accounted for in the Turnover of Sales while filing of Returns
                                        and tax payable on the sale, if any, has been paid or shall be paid.</small>
                                    <div class="declarationLeft">
                                        <strong>For Mahanagar Gas Ltd</strong>
                                        <img src="../../../assets/images/chief-manager-signature.jpg" alt="">
                                        <b>Chief Manager - Revenue &amp; Taxation</b>
                                    </div>
                                    <div class="declarationRight">
                                        <img src="../../../assets/images/newformat_stamp_img2.jpg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="ConsBox">
                            <div class="round-box">
                                
                                <div class="ConsBoxCont">
                                    <h2 style="margin-bottom:5px">Dear Consumer,</h2>
                                    <p style="margin-bottom:0"><strong> Download <small class="text-img-connect"><img src="assets/images/mgl-connect-logo-sml.jpg"></small>, app to Provide Meter reading between <em id="ContentPlaceHolder1_lblMrDate1">{{billData.n_mr_date1}}</em> to <em id="ContentPlaceHolder1_lblMrDate2">{{billData.n_mr_date2}}</em>.Also you can Self Generate your PNG Bill & Pay Between <em>{{billData.n_mr_date3}}</em> to <em>{{billData.n_mr_date4}}</em></strong></p>

                                     <p style="margin-bottom:0"><strong style="text-align:center;">AND</strong></p>   
                                    <strong>OPT for paperless Invoice by sending SMS to 9223555557 "No Hard Copy".</strong>
                                    <strong style="color:green; text-decoration: underline;">To check the credentials of Meter Reader / After Sales Supervisor</strong>
                                    <p style="margin-bottom:0">Open the SMS received from MGL on your registered Mobile number-> Click on the link in the sms-> Photo of the Meter Reader / After Sales Supervisor will be displayed.</p>
                                    
                                </div>

                              
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="pay-box">
                            <div class="box-left49per1">
                                <div class="paymentslip">
                                    <ul>
                                        <li>PAYMENT SLIP/CHEQUE</li>
                                        <li><samp class="border-right"><big>Cheque
                                                    No:</big><small></small></samp><samp><big>Cheque
                                                    Dt:</big><small></small></samp></li>
                                        <li><strong>Bank Name/ branch:</strong><em></em></li>
                                        <li><samp class="border-right"><big>CA:</big><small
                                                    id="ContentPlaceHolder1_lblCaNo_39">{{billData.contract_acc}}</small></samp><samp><big>Due
                                                    date:</big><small
                                                    id="ContentPlaceHolder1_lblPSlipDueDate">{{billData.due_date}}</small></samp>
                                        </li>
                                        <li><strong>Amount Paid:</strong><em
                                                id="ContentPlaceHolder1_lblAmount_Paid_42">{{billData.amt_pybl}}</em>
                                        </li>
                                        <li>
                                            <p>Please pay by cheque in favour of <br> "Mahanagar Gas Ltd CA No. <samp
                                                    id="ContentPlaceHolder1_lblCaNo_payslip">{{billData.contract_acc}}</samp>"</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="box-right49per2">
                                <div class="youcanpay">
                                    <ul>
                                        <li>Pay through QR (Quick Response) Code</li>
                                        <li>
                                            <big>
                                                <img src="assets/images/upi-logo.png"
                                                    style="position:relative; margin-bottom:-10px;"/>
                                                <img [src]="qrdata_upi"/>
                                            </big>
                                            <samp>
                                                <img src="assets/images/paytm-logo.png"
                                                    style="position:relative; margin-bottom:-10px;" >
                                               <div class="paytmqr-img"> <img [src]="qrdata_paytm"></div>

                                            </samp>
                                        </li>
                                    </ul>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <h2 class="text10">Corporate &amp; Registered Office: MGL House, G-33, Bandra-Kurla Complex,
                            Bandra (East), Mumbai - 400 051 CIN No. L40200MH1995PLC088133</h2>
                        <h4 class="text11">VAT NO. 27390000364V | C.S.T. No. 27390000364C | GSTIN Number :
                            27AABCM4640G1ZN | PLACE OF SUPPLY : MAHARASHTRA (27)</h4>
                        <h5 class="text12">Subject to Mumbai Jurisdiction</h5>
                        <div><img src="assets/images/current-bill-ad-new.jpg" width="100%" style="border-radius:6px; border:2px solid #52b049;"></div>
                    </div>

                    <div class="bill-main" *ngIf='billData'>
                        <div class="backpage-left">
                            <div class="round-box2">
                                <div class="meter_box_right">
                                    <img src="assets/images/current-bill-new-hindi-text.jpg" width="100%">
                                </div>
                                <div class="clear"></div>
                                <div class="ShouldKnow">
                                    <span>Safety - Best Practices</span>
                                    <div class="clear"></div>
                                    <ul>
                                        <li style="text-align:justify">Ensure Appliance Valve / Meter Control Valve is
                                            closed when not using Gas.</li>
                                        <li style="text-align:justify">Switch off Gas when attending telephone calls.
                                        </li>
                                        <li style="text-align:justify">Keep windows open and allow air circulation
                                            inside Kitchen to facilitate ventilation.</li>
                                        <li style="text-align:justify">Electric Installation / Wiring should never touch
                                            or traverse Gas line. Please maintain a clear distance of at least 01 foot
                                            (300 mm).</li>
                                        <li style="text-align:justify">Do not conceal Gas Pipes in permanent or
                                            temporary structures.</li>
                                        <li style="text-align:justify">Gas Geysers must NOT be installed inside
                                            bathrooms or closed areas. Please contact ONLY MGL approved contractors for
                                            installation of Gas Geysers. Details are available at <a
                                                href="https://www.mahanagargas.com/residential-png/gas-geysers/rate-card"
                                                target="_blank">https://www.mahanagargas.com/residential-png/gas-geysers/rate-card</a>
                                        </li>
                                        <li style="text-align:justify">For repair / change of Gas Stove / Rubber Tube /
                                            Temporary Disconnection (Renovation) etc. please avail services from MGL
                                            authorized contractors. Details of such contractors are available at <a
                                                href="https://www.mahanagargas.com/residential-png/policies-and-procedures/aftersales-services/service-provider"
                                                target="_blank">https://www.mahanagargas.com/residential-png/policies-and-procedures/aftersales-services/service-provider</a>
                                            and the schedule of rates for material and service charges are available at
                                            <a href="https://www.mahanagargas.com/residential-png/policies-and-procedures/aftersales-services/png-service-charges"
                                                target="_blank">https://www.mahanagargas.com/residential-png/policies-and-procedures/aftersales-services/png-service-charges</a>.
                                            Please check the credentials of After Sales Supervisor as per procedure
                                            mentioned above.</li>
                                        <li style="text-align:justify">For viewing "Natural Gas Safety Awareness Film"
                                            please visit the link <a
                                                href="https://www.mahanagargas.com/residential-png/customer-zone/consumer-safty-checklist"
                                                target="_blank">https://www.mahanagargas.com/residential-png/customer-zone/consumer-safty-checklist</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="ShouldKnow2">
                                    <strong>Dear Consumer,</strong>
                                    <p style="text-align:justify">Invoice is raised once in two months on the basis of
                                        meter reading taken by MGL meter reader or meter Reading forwarded by yourself.
                                    </p>
                                    <p style="text-align:justify">We allow a Credit Period of 20 days to enable you to
                                        make payment. If you do not pay your bill by Due Date, Late Payment Charges of
                                        Rs. 100/- shall be levied. Continued default in making payment will attract
                                        additional Delayed Payment Charges@ 18% p.a. on unpaid value (beyond 9 days of
                                        delay) w.e.f. Due Date.</p>
                                    <p style="text-align:justify">In the event of continued default, MGL reserves the
                                        right to disconnect the supply. Reconnection shall be done only upon payment of
                                        Outstanding, (Including Late Payment charges, interest on delayed payment),
                                        disconnection &amp; reconnection charges.</p>
                                    <p style="text-align:justify">Any dispute in invoice should be brought to our notice
                                        within 14 days of the Bill Date.</p>
                                    <p style="text-align:justify">If you do not consume PNG equivalent to Rs.100/- in a
                                        Bimonthly Billing Period, the shortfall amount will be levied in addition to the
                                        Gas Consumption Charges.</p>
                                    <p style="text-align:justify">We forward the copy of the Bill by SMS as well as
                                        e-mail on demand. </p>
                                    <p style="text-align:justify">Piped Natural Gas (PNG) attracts MVAT and all other
                                        sale and supply of Goods and Services attracts GST.</p>
                                </div>
                            </div>
                        </div>
                        <div class="backpage-right">
                            <div class="terms">
                                <span>Customer Care</span>
                                <div class="clear"></div>
                                <div class="row d-flex justify-content-center customer-care-phone">
                                    <div class="col-md-8">
                                        <h5>(022)-68674500 <br>(022)-61564500</h5>
                                    </div>
                                    <div class="col-md-4 ">
                                        <img style="height:50px; margin-top: 32px;" src="../../../assets/images/telephone.png"/>
                                    </div>
                                </div>
                               <div class="row d-flex justify-content-center whatsApp-number">
                                    <div class="col-md-12">
                                        <h5>WhatsApp- <img src="../../../assets/images/whats-app-icon-color.png" width="30"> 9899203843</h5>
                                        <h6>(Available 24X7)</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="ShouldKnow3">
                                <span style="text-align:left">Your Nearest Walk in center (10:00 AM to 5:00 PM, Monday
                                    to Saturday except Public &amp; Bank Holidays)</span>
                                <div class="contArea">
                                    <p id="ContentPlaceHolder1_divWalkincenter"></p>
                                    <p>{{walkincenterData}}</p>

                                </div>
                            </div>
                            <div class="ShouldKnow4">
                                <span>For Unresolved Issues Please Contact</span>
                                <p><strong>Nodal Office:</strong><br> Mahanagar Gas Limited, Pay and Accounts Building,
                                    Ground Floor, Near Family Court, Bandra Kurla Complex, Bandra (East), Mumbai-400
                                    051. <br><br><strong>E-mail ID:</strong>
                                    <em>support@mahanagargas.com</em><br><strong>Telephone Number: 022-61564555</strong>
                                </p>
                                <ul>
                                    <li><strong>Complaint in-charge:</strong> Mr. Karan Mhole - Ext. No. 4549</li>
                                    <li><strong>Nodal Officer:</strong> Mr. Vikas Sarmalkar - Ext. No. 4548</li>
                                    <li><strong>Appellate Authority:</strong> Mr. Paresh Chanpur - Ext. No. 4535</li>
                                </ul>
                                 <p>You may contact the above-mentioned officials between 3:00 pm to 5:00 pm from Monday to Friday (Except Public & Bank Holidays) with the Unique Docket number given at the time of registering your complaint.</p>
                                 
                                

                            </div>
                            <div class="ShouldKnow5" style="min-height: 265px;text-align:center;padding-top:10px;">
                                <img src="../../../assets/images/current-bill-MGL_Connect_App_Section.jpg" alt="">
                            </div>
                            <div class="ShouldKnow6">
                                <div class="qrcodeRow">
                                    <div class="qrcodeCol">
                                        <h2>For T&amp;C Please scan below QR</h2>
                                        <img [src]="qrdata_tc"/>
                                    </div>
                                    <div class="qrcodeCol">
                                        <h2>Generate your own bill </h2>
                                    <img src="../../../assets/images/customer-login-qr-code.png" alt="" width="180">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="round-box4">
                            <span>
                                <img src="../../../assets/images/box.png"/>&nbsp;<b>Where to Pay Your Gas Bill</b>
                            </span>
                            <div class="clear"></div>
                            <table cellspacing="0" cellpadding="0" border="1" align="center">
                                <tbody>
                                    <tr>
                                        <td width="20.3290246769%" class="heading">Visit Our Website</td>
                                        <td width="19.2714453584%" class="heading">Across the Counter</td>
                                        <td width="38.0728554642%" class="heading" colspan="3">YOU CAN PAY BY RTGS/NEFT
                                        </td>
                                        <td width="20.7990599295%" class="heading">E-NACH</td>
                                    </tr>
                                    <tr>
                                        <td rowspan="6">
                                            <strong>Online Payments</strong>
                                            <p>Pay By Net Banking, Cards, UPI</p>
                                            <strong>Post office locations:</strong>
                                            <p>Mumbai, Mira Road, Thane</p>
                                            <strong>Drop Box:</strong>
                                            <p>ICICI Bank, Axis Bank</p>
                                            <strong>UPI</strong>
                                            <p>HSBC, Paytm, Bill Desk</p>
                                            <strong>NACH/E-NACH</strong>
                                            <p>For Registration &amp; Cancellation</p>
                                        </td>
                                        <td rowspan="2" align="center">Operators : Outlets of Suvidhaa, Paypoint,
                                            Euronet.</td>
                                        <td width="95px" style="white-space: nowrap"><strong>Bank Name</strong></td>
                                        <td width="122px"><strong>YES Bank Ltd.</strong></td>
                                        <td width="120px"><strong>State Bank of India</strong></td>
                                        <td rowspan="3" align="center">Please avail 1% Discount on Gas Consumption Charges subject to
                                            maximum of ₹ 50/-</td>
                                    </tr>
                                    <tr>
                                        <td><strong style="white-space: nowrap">Account no.</strong></td>
                                        <td><strong id="ContentPlaceHolder1_lblacno">MGL999 {{billData.contract_acc}}</strong></td>
                                        <td><strong id="ContentPlaceHolder1_lblacno1">MGL1 {{billData.contract_acc}}</strong></td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2"></td>
                                        <td><strong style="white-space: nowrap">IFSC</strong></td>
                                        <td><strong>YESB0CMSNOC</strong></td>
                                        <td><strong>SBIN0004266</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong style="white-space: nowrap">Type of Account</strong></td>
                                        <td><strong>Current Account</strong></td>
                                        <td><strong>Current Account</strong></td>
                                        <td class="heading">RTGS/NEFT</td>
                                    </tr>
                                    <tr>
                                        <td class="heading">UBIQC</td>
                                        <td rowspan="2"><strong style="white-space: nowrap">Branch Add.:</strong></td>
                                        <td rowspan="2"><strong>Unit No.2-A 1, Ground Floor, Hallmark Business Plaza, Gurunanak Hospital Road, Kalanagar, Bandra East 400051</strong></td>
                                        <td rowspan="2"><strong>Cash Management Product Operations Centre, State Bank of India "AISHWARYAM" Survey No. 26, Lingampally, Hyderbad -500019</strong></td>
                                        <td rowspan="2" align="center">Please transfer directly from your bank A/C to MGL Bank</td>
                                    </tr>
                                    <tr>
                                        <td>View And Pay MGL Bills Download App From Play Store</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div><img src="assets/images/current-bill-ad-new2.jpg" width="100%" style="border-radius:6px; border:2px solid #52b049;"></div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>