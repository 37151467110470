<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>List of Documents Required</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a  [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a  >Dealer Zone</a></li>
                <li class="active" aria-current="page"><a>List of Documents Required</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                
                <app-tips></app-tips>
                <app-insta-post></app-insta-post>

               <!--  <div class="mt-30">
                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
               

             <div class="mt-40">
                    <div class="box882_left">

                        <div class="mb-20">
                            <h6>Land Related Documents</h6>
							<div class="blue-tick-list">
								<ul>
									<li>Property card or Latest 7/12 extract (should be less than 3 months old) confirming name of owner of the plot.</li>
									<li>Ownership documents (Sale deeds and Mutation entries showing chain of title for 30 years/Property card).</li>
									<li>Details of all leases, sub-leases, tenancy agreements, assignments, or any other mode of transfer and agreements to enter in relation to the said Property.</li>
									<li>Latest copy of Property tax and NA Tax Receipt and NA order. (If applicable)
									</li>
									<li>If land does not belong to the Applicant, then Landowner and applicant shall submit notarized declaration on Rs 200 stamp paper as per format provided by MGL. <br><a href="assets/images/pdf/Undertaking Format_Land Owner.doc"  target="_blank">Undertaking Format (Land Owner)</a> <br> <a href="assets/images/pdf/Undertaking Format_Applicant.docx"  target="_blank">Undertaking Format (Applicant)</a>
									</li>
									<li>Consent letter in form of affidavit if applicant is individual and the land belongs to family members.
									</li>
									<li>Plot boundary and dimensions (layout) prepared and certified by Architect, clearly showing:<br>
										a.	Road<br>
										b.	ROW<br>
										c.	Building line<br>
										d.	Any other setbacks<br>
										e.	NET AREA (excluding setbacks)<br>
										f.	Supporting documents (Latest DP Remark, Zone Certificate, NHAI-DPR, PWD Certificate, Etc.)

									</li>
									<li>Clear Photographs of land from Front (from road), Rear of plot (facing the road) and other angles (4-5 photographs)
									</li>
								</ul>
							</div>
							<div class="mt-30">
                            <h6>Applicant Related Documents</h6>
							<div class="blue-tick-list">
								<ul>
									<li>Age Proof (Any educational certificate/Birth certificate/PAN Card)</li>
									<li>ID proof (Any Govt. approved ID Proof)</li>
									<li>Applicant's Net Worth certificate attested by CA (Showing Liquid funds and fixed assets separately)</li>
									<li>Applicants Educational certificates</li>
									<li>Registered Partnership deed in case of applicant is firm and LOI to be issued in the name of partnership firm</li>
								</ul>
							</div>
							</div>
							<div class="mt-30">
                            <h6>Others</h6>
							<div class="blue-tick-list">
								<ul>
									<li>Application form duly filled and signed by Applicant. (<a href="assets/images/pdf/Application Form_New CNG Retail Outlet.pdf"  target="_blank">Download Application form</a>)</li>
									<li>Non-refundable, non-interest-bearing one-time administrative fees of Rs 5,00,000/- + GST as applicable in favour of M/s MAHANAGAR GAS LIMITED. Vide Demand Draft or Pay Order issued by Nationalized bank. (Applicants not issued LOI will be returned this DD)</li>
									
								</ul>
								<p><b>*Above documents shall be submitted along with the application form in Hard copy. Non-submission of above documents will entail rejection of application.</b></p>
							</div>
							</div>
							
                        </div>


							
							
							
						</div>


                    </div>

            </div>
        </div>
    </div>
    <!-- Inner Page Content Section End -->
</div>
<!-- Main content End -->