<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
    <div class="banner-heading">
        <h1>Apply for Name Transfer
        </h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a>Residential PNG</a></li>
                <li class=""><a>Customer Zone</a></li>
                <li class=""><a>Name Transfer</a></li>
                <li class="active" aria-current="page">Apply for Name Transfer
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<app-service-section></app-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <div class="latestnews-section">
                    <div class="latest-posts">
                        <div class="recent-posts">
                            <div class="widget-title">
                                <h3 class="title">Posts</h3>
                            </div>
                            <div class="latest-post-widget no-border" *ngFor="let i of allPost | slice:0:4">
                                <div class="post-img">
                                    <a *ngIf="i.Img"><img src="{{base}}{{i.Img}}" alt=""></a>
                                    <a *ngIf="!i.Img"><img src="assets/images/news/news2.jpg"></a>
                                </div>
                                <div class="post-desc">
                                    <a [innerHTML]="i.Description"></a>
                                  
                                </div>
                            </div>
                            <a href="#" class="greenbtn2 d-block mt-20">View All <i class="demo-icon uil-arrow-right"></i></a> 
                        </div>
                    </div>
                </div>

                <app-tips></app-tips>
                <app-insta-post></app-insta-post>

               <!--  <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>

                </div> -->
            </div>




            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
              <div  class="customer-login-page log-meter-reading">

                    <form [formGroup]="CustomerInfoForm">
                        <div class="light-blue-bg mt-10">
                            <div class="row">
                                <p><b><i>Details of Meter installed at premise</i></b></p>
                                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="form-floating">
                                        <input type="number" class="form-control" formControlName="MeterNo"
                                            placeholder="MeterNo"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['MeterNo'].errors }"
                                            id="MeterNo" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['MeterNo'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['MeterNo'].errors.required">Required
                                                Field</div>
                                          </div>
                                        <label for="MeterNo">Meter No</label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <div class="form-floating">
                                        <input type="number" class="form-control" formControlName="MeterReading"
                                            placeholder="MeterReading"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['MeterReading'].errors }"
                                            id="MeterReading" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['MeterReading'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['MeterReading'].errors.required">
                                                Required Field</div>
                                            </div>
                                        <label for="MeterReading">Meter Reading</label>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                     <div class="form-floating">
                                        <select #status (change)="HaveConnection(status.value)" formControlName="LpgGasConn"
                                            [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['LpgGasConn'].errors}"
                                            class="form-select"
                                            (change)="this.CustomerInfoForm.get('LpgGasConn')?.setValue(status.value)">
                                            <option value="" selected>Select Option</option>
                                            <option *ngFor="let i of HaveConn">{{i}}</option>
                                        </select>
                                        <div *ngIf="submitted && CustomerInfoForm.controls['LpgGasConn'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['LpgGasConn'].errors.required">Required Field</div>
                                           </div>
                                        <label for="LpgGasConn">Do You Have LPG Gas Connection in your Name<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                                <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                	<div class="form-floating">
                                        <input type="text" class="form-control" formControlName="GasSuppli"
                                            placeholder="GasSuppli"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['GasSuppli'].errors }"
                                            id="GasSuppli" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['GasSuppli'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['GasSuppli'].errors.required">Required
                                                Field</div>
                                            </div>
                                        <label for="GasSuppli">Gas Supplier<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                                <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                	<div class="form-floating">
                                        <input type="text" class="form-control" formControlName="GasDistri"
                                            placeholder="GasDistri"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['GasDistri'].errors }"
                                            id="GasDistri" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['GasDistri'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['GasDistri'].errors.required">Required
                                                Field</div>
                                            </div>
                                        <label for="GasDistri">Gas Distributor<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                                   <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                   	<div class="form-floating">
                                        <input type="number" class="form-control" formControlName="LpgConnNo"
                                            placeholder="LpgConnNo"
                                            [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['LpgConnNo'].errors }"
                                            id="LpgConnNo" />
                                        <div *ngIf="submitted && CustomerInfoForm.controls['LpgConnNo'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['LpgConnNo'].errors.required">Required
                                                Field</div>
                                            </div>
                                        <label for="LpgConnNo">LPG Connection Number<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                                 <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label class="font-size11">Lpg Connection Surrender Slip</label>
                                    <div class="mb-20 upload-input">
                                        <input  accept=".pdf"
                                            (change)="fileCheckForSlip($event,'LpgSurrSlip')"
                                            formControlName="LpgSurrSlip"
                                            
                                            [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['LpgSurrSlip'].errors}"
                                            class="from-control" type="file" id="name" name="name"
                                            placeholder="Enter Lpg Connection Surrender Slip">
                                        <div *ngIf="submitted && CustomerInfoForm.controls['LpgSurrSlip'].errors"
                                            class="invalid-feedback">
                                            <div style="white-space: nowrap;"
                                                *ngIf="CustomerInfoForm.controls['LpgSurrSlip'].errors">
                                                Required Field
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              <!--   <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label class="font-size11">Meter Photo Upload</label>
                                    <div class="mb-20 upload-input">
                                        <input accept=".jpg, .jpeg, .png"
                                            (change)="fileCheckForMeter($event)"
                                            formControlName="MeterPhoto"
                                            [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['MeterPhoto'].errors}"
                                            class="from-control" type="file" id="name" name="name"
                                            placeholder="Enter Meter Photo Upload">
                                        <div *ngIf="submitted && CustomerInfoForm.controls['MeterPhoto'].errors"
                                            class="invalid-feedback">
                                            <div style="white-space: nowrap;"
                                                *ngIf="CustomerInfoForm.controls['MeterPhoto'].errors">Required Field
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div *ngIf="LpgConn" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                </div>
                                  <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                  	<div class="form-floating">
                                        <select #Reason (change)="ReasonForTransfer(Reason.value)" formControlName="ResForTrans"
                                            [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['ResForTrans'].errors}"
                                            class="form-select"
                                            (change)="this.CustomerInfoForm.get('ResForTrans')?.setValue(Reason.value)"
                                            required>
                                            <option value="" selected>Select Option</option>
                                            <option *ngFor="let i of AllReason , index as j" [value]="i.ReasonText">
                                                {{i.ReasonText}}</option>
                                            </select>
                                        <div *ngIf="submitted && CustomerInfoForm.controls['ResForTrans'].errors"
                                            class="invalid-feedback">
                                            <div *ngIf="CustomerInfoForm.controls['ResForTrans'].errors.required">Required Field</div>
                                        </div>
                                        <label for="LpgConnNo">Reason for transfer of PNG Connection<span
                                                style="color: crimson;">*</span></label>
                                    </div>
                                </div>
                               
                                        <div *ngIf="ShowDoc1" class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                            <div class="form-floating">
                                                <select #Docname (change)="DocnameForTransfer(Docname.value)"  formControlName="DocName1"
                                                    [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['DocName1'].errors}"
                                                    class="form-select"
                                                    
                                                    required>
                                                    <option value="" selected>Select Option
                                                    </option>
                                                    <option *ngFor="let i of finalData , index as j" [value]="i">
                                                        {{i}}</option>


                                                </select>
                                                <div *ngIf="submitted && CustomerInfoForm.controls['DocName1'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="CustomerInfoForm.controls['DocName1'].errors.required">
                                                        Required Field</div>
                                                   
                                                </div>
                                                <label for="LpgConnNo">Select Any<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                         <div *ngIf="ShowDoc2" class="col-lg-6 col-md-6 col-sm-12 mb-3">


                                            <div class="form-floating">
                                                <select #Docname2 (change)="DocnameForTransfer2(Docname2.value)"  formControlName="DocName2"
                                                    [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['DocName2'].errors}"
                                                    class="form-select"
                                                    
                                                    required>
                                                    <option value="" selected>Select Option
                                                    </option>
                                                    <option *ngFor="let i of finalData1 , index as j" [value]="i">
                                                        {{i}}</option>


                                                </select>
                                                <div *ngIf="submitted && CustomerInfoForm.controls['DocName2'].errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="CustomerInfoForm.controls['DocName2'].errors.required">
                                                        Required Field</div>
                                                   
                                                </div>
                                                <label for="LpgConnNo">Select Any<span
                                                        style="color: crimson;">*</span></label>
                                            </div>
                                        </div>
                                         <div *ngIf="LpgConn==false && ShowDoc2==false " class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        </div>
                                         <div *ngIf="LpgConn==true && ShowDoc2==true " class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        </div>

                                        <div *ngIf="ShowDoc1" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                            <label>{{Doc1Text}}</label>
                                            <div class="upload-input death-cert">
                                             
                                                <input accept=".pdf"
                                                    (change)="fileCheckForSlip($event,'DocName1File')"
                                                    formControlName="DocName1File"
                                                    
                                                    [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['DocName1File'].errors}"
                                                    class="from-control" type="file" id="name" name="name"
                                                    placeholder="Enter Lpg Connection Surrender Slip">
                                                <div *ngIf="submitted && CustomerInfoForm.controls['DocName1File'].errors"
                                                    class="invalid-feedback">
                                                    <div style="white-space: nowrap;"
                                                        *ngIf="CustomerInfoForm.controls['DocName1File'].errors">
                                                        Required Field
                                                    </div>
                                                </div>
                                            </div>


                                            <p class="font-size11">Only .pdf file is allowed and size within 2 MB</p>
                                        </div>
                                        <div *ngIf="ShowDoc2" class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                            <label>{{Doc2Text}}</label>
                                            <div class="upload-input death-cert">
                                             
                                                <input accept=".pdf"
                                                    (change)="fileCheckForSlip($event,'DocName2File')"
                                                    formControlName="DocName2File"
                                                    
                                                    [ngClass]="{'is-invalid':submitted && CustomerInfoForm.controls['DocName2File'].errors}"
                                                    class="from-control" type="file" id="name" name="name"
                                                    placeholder="Enter Lpg Connection Surrender Slip">
                                                <div *ngIf="submitted && CustomerInfoForm.controls['DocName2File'].errors"
                                                    class="invalid-feedback">
                                                    <div style="white-space: nowrap;"
                                                        *ngIf="CustomerInfoForm.controls['DocName2File'].errors">
                                                        Required Field
                                                    </div>
                                                </div>
                                            </div>


                                            <p class="font-size11">Only .pdf file is allowed and size within 2 MB</p>
                                        </div>
                            </div>
                            
                              <!-- <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-5 col-sm-12">
                                              <div class="mt-20">
                                                <div (copy)="false" class="captcha-text">{{this.captcha}} <i
                                                            (click)="captchaGenerate()" class="fa fa-refresh"
                                                            aria-hidden="true"></i> </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-7 col-md-7 col-sm-12">
                                               <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && CustomerInfoForm.controls['Captcha'].errors }"
                                                        id="Captcha" />
                                                    <div *ngIf="submitted && CustomerInfoForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="CustomerInfoForm.controls['Captcha'].errors.required">
                                                            Required Field</div>
                                                     
                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> -->
                                 <div class="row">
                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6">
                                        <button (click)="submitCustomerForm()"
                                            class="greenbtn2">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Next<i
                                                class="demo-icon uil-arrow-right"></i></button>
                                    </div>

                                    <div class="col-6 col-lg-6 col-md-6 col-sm-6 text-right">
                                      <!--   <button
                                            (click)="this.router.navigate(['/residential-png/customer-zone/name-transfer'])"
                                            class="greybtn">Cancel<i class="demo-icon uil-times-circle"></i></button> -->
                                    </div>
                                </div>


                                <div *ngIf="alertType == '3rdSubmit'"
                                    [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign,'margin-top':'10px'}"
                                    class="card card-inverse-info" id="context-menu-simple">
                                    <div class="card-body">
                                        <p class="card-text">
                                            {{alertMsg}}
                                    </div>
                                </div>
                        </div>
                      
                      
                    </form>
                </div>






            </div>
        </div>
    </div>
</div>


