import { Component } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validator, Validators } from '@angular/forms';
import { Oempromotion20Service } from 'src/app/Service/oempromotion20.service';
import { Router } from '@angular/router';
import { PageLoaderService } from 'src/app/Service/page-loader.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-oemsnewvehicles20status',
  templateUrl: './oemsnewvehicles20status.component.html',
  styleUrls: ['./oemsnewvehicles20status.component.css']
})
export class Oemsnewvehicles20statusComponent {
 searchpromotionForm!: FormGroup;
   submitted = false;
 
  captcha: any;
  alertType: any = '';
  alertMsg: any = '';
  alertColor: string = '';
  alertTextColor: string = '';
  alertBorder: string = '';
  alertTextAlign: string = 'center';

  submitStatus:boolean=false;
  display:string='none';
  Popup:any='';
  stopTimer:boolean=false;
  OtpButtonActive: boolean = false;
  Minute: any = 1;
  Sec: any = 10;
  Otp: any;
  nextPageres:any;

  leveloneArray:any;
  searchForm!: FormGroup;
  submittedsearch = false;
  constructor(private pageLoaderService: PageLoaderService, private FormBuilder: FormBuilder, private service: Oempromotion20Service,
    public router: Router,public UserService:UserRegistrationService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.pageLoaderService.show()
    setTimeout(() => {
      this.pageLoaderService.hide()
    }, 3000);
    
    this.captchaGenerate();

   

    this.searchpromotionForm = this.FormBuilder.group({
      reference_number: ['', [Validators.required]],
      ContactNumber: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^[0-9]*$"), Validators.required])],
      otp: [''],
      Captcha: ['', [Validators.required]],

    });

   

     this.searchForm = this.FormBuilder.group({
      refranceNumber: [''],
      status: [''],
     

    });

    this.get_oem_20_levle_one_list();
  }




  captchaGenerate() {
    let length = 7
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    this.captcha = result



  }

  submit(){
    this.submitted = true;
    if (this.searchpromotionForm.get('Captcha')?.value != this.captcha) {
      this.searchpromotionForm.get('Captcha')?.setValue('')
    }
    if (this.searchpromotionForm.invalid) {
      return;
    }
    let obj = {'ContactNumber':this.searchpromotionForm.get('ContactNumber')?.value,
    'refranceNumber':this.searchpromotionForm.get('reference_number')?.value,
    'otp':''}

    this.service.search_Vehicle_OEM_Application(obj).subscribe(response => {
     // console.log(response);
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
         // this.otpTimer()
          //this.OtpButtonActive = true
          this.submitStatus=false
           this.nextPageres = res;
      //  console.log(this.nextPageres);
          this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-details'],
        {queryParams:{data:JSON.stringify(this.nextPageres)}})
           //this.openPopup();

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'searchPro'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      //console.log(err)
    })

  }


 

  
  openPopup(){
    this.Popup='show';
    this.display='block';
    }

  closeModel(){
    this.Popup='';
    this.display='none';
    this.stopTimer=true;
    
  }

  clearAlert() {
    this.alertType = ''
  }

  


  // addPromotion(){
  //   this.router.navigate(['/cng/cng-offers-schemes/savings-ki-shubh-shuruaat'])
  // }
  
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
  }


  get_oem_20_levle_one_list(){
  
    // let obj = {'role':this.retroffiterLoginRole}
    let obj ={}

       this.service.get_oem_20_levle_one_list(obj).subscribe(response => {
    
      let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {
        this.leveloneArray = res.details;

         this.leveloneArray=res.details.filter((x:any)=>{
        return x.level_two_status != "new"
      })
        } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'getlist'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      
    })

  }



    searchsubmit(){
    this.submittedsearch = true;
   
    if (this.searchForm.invalid) {
      return;
    }
       let obj = {"refranceNumber":this.searchForm.get('refranceNumber')?.value,
       "status":this.searchForm.get('status')?.value}

       this.leveloneArray = [];
      
       this.service.get_oem_20_levle_one_list(obj).subscribe(response => {
       let res = JSON.parse(JSON.stringify(response))
      if (res.response == true) {

          this.leveloneArray = res.details;

         this.leveloneArray=res.details.filter((x:any)=>{
        return x.level_two_status != "new"
      })

      } else {
        this.alertColor = 'rgba(255, 94, 109, 0.2)'
        this.alertTextColor = '#c24753'
        this.alertBorder = '1px solid #eb5664;'
        this.alertType = 'searchV'
        this.alertMsg = res.message;
        setTimeout(() => {
          this.clearAlert()

        }, 4000);
      }
     }, err => {
      //console.log(err)
    })

  }


 gotoEdit(item:any){
    this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-edit'],
        {queryParams:{data:JSON.stringify(item)}})

  }

  gotoLeveltwo(item:any){
       this.router.navigate(['/cng/cng-offers-schemes/cng-mahotsav-2.0-oems-new-vehicles-approval'],
        {queryParams:{data:JSON.stringify(item)}})

  }

}

