<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/awards-banner.jpg">
     <div class="banner-heading">
        <h1>OEMs Level One Status</h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >CNG</a></li>
                <li class=""><a >MGL CNG Mahotsav 2.0</a></li>
                <li class="active" aria-current="page"><a>OEMs Level One Status</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-cng-service-section></app-cng-service-section>
<!-- Services Section End -->
<!-- Inner Page Content Section Start -->

<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">

            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <app-insta-post></app-insta-post>

                <!-- <div class="mt-30">

                    <a class="twitter-timeline" href="https://twitter.com/mahanagargas?ref_src=twsrc%5Etfw">Tweets by
                        mahanagargas</a>
                    
                </div> -->
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="light-blue-bg">
                    <div class="row">
                        <div class="col-sm-12 mx-auto">
                            <div class="customer-login-page">
                               
                                <div class="row">
                                    <form [formGroup]="searchForm">
                                        <div class="row">
                                     

                                               <div class="col-sm-4 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="vehicleNo"
                                                        placeholder="Refrance Number"
                                                        [ngClass]="{ 'is-invalid': submittedsearch && searchForm.controls['refranceNumber'].errors }"
                                                        id="refranceNumber" />
                                                    <div *ngIf="submittedsearch && searchForm.controls['refranceNumber'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="searchForm.controls['refranceNumber'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                   <!--  <label>Vehicle Number<span style="color: crimson;">*</span></label> -->
                                                    <label>Refrance Number</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 form-group">
                                                <div class="form-floating">
                                                      <select  type="text" class="form-select" formControlName="status"
                                                [ngClass]="{'is-invalid':submittedsearch && searchForm.controls['status'].errors}" >
                                                <option value="" selected>Select Status</option>
                                                <option value="new">New</option>
                                                <option value="Accepted">Accepted</option>
                                                <option value="Rejected">Rejected</option>
                                             

                                            </select>
                                                    <div *ngIf="submittedsearch && searchForm.controls['status'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="searchForm.controls['status'].errors.required">
                                                            Required Field
                                                        </div>
                                                    </div>
                                                   <!--  <label>Status<span style="color: crimson;">*</span></label> -->
                                                    <label>Status</label>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <button (click)="searchsubmit()" class="greenbtn2">Search <i
                                                        class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                            
                                          
                                        </div>

                                    </form>

                                  

                                    <div *ngIf="alertType == 'searchV'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}" class="alert alert-danger mt-2" role="alert">
                                   {{alertMsg}}
                                     </div>



                                </div>
                            </div>
                        </div>
                    </div>

                     <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Vehicle Chassis No</th>
                                    <th>Customer Name</th>
                                    <th>Contact Number</th>
                                    <th>OEM</th>
                                    <th>Status</th>
                                     <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of leveloneArray">
                                    <td>{{item.vehicle_chassis_no}}</td>
                                    <td>{{item.Name}}</td>
                                    <td>{{item.ContactNumber}}</td>
                                    <td>{{item.oem}}</td>
                                    <td>{{item.status}}</td>
                                     <td *ngIf="item.status=='Rejected'"> <button  class="greenbtn2" (click)="gotoEdit(item)">Edit</button></td>
                                      <td *ngIf="item.status=='Accepted'"> <button  class="greenbtn2" (click)="gotoLeveltwo(item)">Proceed Level 2</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                            <div class="customer-login-page">
                                <div class="row">
                                    <form [formGroup]="searchpromotionForm">
                                        <div class="row">
                                            <div class="col-sm-12 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control"
                                                        formControlName="reference_number"
                                                        placeholder="Application Reference No."
                                                        [ngClass]="{ 'is-invalid': submitted && searchpromotionForm.controls['reference_number'].errors }"
                                                        id="reference_number" />
                                                    <div *ngIf="submitted && searchpromotionForm.controls['reference_number'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="searchpromotionForm.controls['reference_number'].errors.required">
                                                            Required Field</div>
                                                    </div>
                                                    <label for="reference_number">Application Reference No.<span
                                                            style="color: crimson;">*</span></label>
                                                </div>

                                            </div>
                                            <div class="col-sm-12 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="ContactNumber"
                                                        placeholder="ContactNumber"
                                                        [ngClass]="{ 'is-invalid': submitted && searchpromotionForm.controls['ContactNumber'].errors }"
                                                        id="ContactNumber" />
                                                    <div *ngIf="submitted && searchpromotionForm.controls['ContactNumber'].errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="searchpromotionForm.controls['ContactNumber'].errors.required">
                                                            Required Field
                                                        </div>
                                                        <div *ngIf="searchpromotionForm.controls['ContactNumber'].errors.pattern">
                                                            Invalid Contact Number </div>
                                                        <div
                                                            *ngIf="searchpromotionForm.controls.ContactNumber.hasError('maxlength') && searchpromotionForm.controls.ContactNumber.touched">
                                                            Invalid Contact Number </div>
                                                        <div
                                                            *ngIf="searchpromotionForm.controls.ContactNumber.hasError('minlength') && searchpromotionForm.controls.ContactNumber.touched">
                                                            Invalid Contact Number </div>
                                                    </div>
                                                    <label>Contact Number<span style="color: crimson;">*</span></label>
                                                </div>
                                            </div>
                                            
                                          
                                        </div>

                                        <div class="row y-middle">
                                            <div class="col-lg-6">
                                                <div (copy)="false" class="">
                                                    <div class="captcha-text">
                                                        {{this.captcha}}
                                                        <button style="background: transparent;border: none;"
                                                            (click)="captchaGenerate()"><i class="fa fa-repeat"
                                                                aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 form-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" formControlName="Captcha"
                                                        placeholder="Captcha"
                                                        [ngClass]="{ 'is-invalid': submitted && searchpromotionForm.controls['Captcha'].errors }"
                                                        id="Captcha" />
                                                    <div *ngIf="submitted && searchpromotionForm.controls['Captcha'].errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="searchpromotionForm.controls['Captcha'].errors.required">
                                                            Required Field</div>

                                                    </div>
                                                    <label for="Captcha">Captcha<span
                                                            style="color: crimson;">*</span></label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="mt-15">
                                        <div class="row">
                                            <div class="col-3 col-lg-3 col-md-3 col-sm-12">
                                                <button (click)="submit()" class="greenbtn2">Search <i
                                                        class="demo-icon uil-arrow-right"></i></button>
                                            </div>
                                          
                                        </div>
                                        </div>
                                    </form>

                                   

                                    <div *ngIf="alertType == 'searchPro'"
                                        [ngStyle]="{'backgroundColor':alertColor,'color':alertTextColor,'border':alertBorder,'textAlign':alertTextAlign}" class="alert alert-danger mt-2" role="alert">
                                   {{alertMsg}}
                                     </div>



                                </div>
                            </div>
                        </div>
                    </div> -->


                </div>
            </div>



        </div>
    </div>
</div>

